import GameDropdown from "../GameDropdown/GameDropdown";
import cn from "classnames";
import styles from "./GameSelectorValue.module.scss";
import { GameSelectorValuePropsI } from "./GameSelectorValue.props";
import erc20 from "../../../../services/bch/erc20";

export default function GameSelectorValue({
  from = false,
  balance,
  options,
  value,
  selectorValue,
  transfer = false,
  isError = false,

  getSelectorValue,
  getValue,
}: GameSelectorValuePropsI) {
  const renderBalance = () => {
    if (transfer) {
      return `${erc20.convertWei(balance, "SOUL", 4)} SOUL`;
    } else {
      return `${erc20.convertWei(balance, selectorValue.name, 4)} ${selectorValue.name}`;
    }
  };

  return (
    <div className={styles.selector_wrapper}>
      <div className={styles.title_wrap}>
        <span> {from ? "From" : "To"} </span>
        <span>Balance: {renderBalance()}</span>
      </div>
      <div
        className={cn(styles.content, {
          [styles.transfer]: transfer,
          [styles.isError]: isError,
        })}
      >
        <GameDropdown
          options={options}
          className={styles.selector_dropdown}
          getValue={getSelectorValue}
          value={selectorValue}
        />
        <div className={styles.input}>
          <input
            value={value || ""}
            type={"number"}
            onChange={(e) => (from ? getValue(+e.target.value) : null)}
            placeholder={`0`}
            // defaultValue={value}
          />
          {from ? (
            <span
              onClick={() =>
                getValue(+erc20.convertWei(balance, transfer ? "SOUL" : selectorValue.name, 4))
              }
            >
              MAX
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
}
