import { useState } from "react";
import { GAME_CONFIRM_MODAL } from "../../../../../utils/modalGameNames";
import WithGameModal, { ModalGameProps } from "../../../../HOC/GameHOC/withGameModal";
import { ModalHeader } from "../GameModal";
import cn from "classnames";
import styles from "./ConfirmModal.module.scss";
import GameDataString from "../../../GameFolder/GameDataString/GameDataString";
import { GameType } from "../../../../GameFolder/GameSelectGods/useGameSelectGodsControl";
import GameBtn from "../../../GameFolder/GameBtn/GameBtn";
import { BtnType } from "../../../GameFolder/GameBtn/GameBtn.props";
import GameLoader from "../../../GameFolder/GameLoader/GameLoader";

interface ConfirmModalI extends ModalGameProps {
  mode: "PVE" | "PVP";
  gameType?: GameType;
  bet: string | number;
  goTo(): void;
}

function ConfirmModal({ mode, gameType = GameType.SOUL, bet, goTo, ...props }: ConfirmModalI) {
  const [loading, setLoading] = useState<boolean>(false);

  const renderPrice = () => (gameType === GameType.SOUL ? `- ${bet}` : `${bet}`);
  return (
    <>
      {loading && <GameLoader global />}
      <ModalHeader title={`${mode} | Confirm Battle`} />

      <div className={styles.wrapper}>
        <GameDataString title='Battle Token' name={gameType} price={renderPrice()} confirm />

        <div className={styles.bottom}>
          <span>
            {gameType === GameType.SOUL ? "5% service fee" : "10 SOUL"} will be deducted from the
            Battle Soul token amount
          </span>
          <GameBtn
            className={styles.btn}
            type={BtnType.LIGHT}
            onClick={() => {
              setLoading(true);
              goTo();
            }}
          >
            Battle room
          </GameBtn>
        </div>
      </div>
    </>
  );
}
export default WithGameModal(GAME_CONFIRM_MODAL, ConfirmModal);
