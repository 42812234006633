import { AxiosResponse } from "axios";
import { ServerResponse } from "../setting";
import service from "../service";

export interface UserRankingI {
  Nft: number;
  Soul: number;
  Total: number;
  Battles: number;
  Draw: number;
  Lose: number;
  Win: number;
  LastResults: string;
}

export interface UserRankingResponseI extends ServerResponse {
  Ranking: UserRankingI;
}

export function userRanking(): Promise<AxiosResponse<UserRankingResponseI> | Error> {
  return service.api.post("/gt/game/personranking");
}
