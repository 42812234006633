/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from "react";
import styles from "./GameResultModal_new.module.scss";
import cn from "classnames";
import { GAME_RESULT_MODAL } from "../../../../../utils/modalGameNames";
import WithGameModal from "../../../../HOC/GameHOC/withGameModal";
import { GameResultModalPropsI } from "./GameResultModal_new.props";
import { ModalHeader } from "../GameModal";
import uniqString from "../../../../../utils/uniqString";
import GameBtn from "../../../GameFolder/GameBtn/GameBtn";
import { BtnType } from "../../../GameFolder/GameBtn/GameBtn.props";
import makeImagePath from "../../../../../utils/makeImagePath";
import {
  GameResE,
  ResGameModeE,
  SelectedGodForGameI,
} from "../../../../../services/api/game/roomInfo";
import { useNavigate } from "react-router-dom";
import { RoutsGameE } from "../../../../../utils/gameRoutsName";
import useMediaQuery from "../../../../../utils/hooks/useMediaQuery";
import GodImage from "../../../GodImage/GodImage";
import { useGameProf } from "../../../../../utils/hooks/gameHooks/useGameProf";
import useMe from "../../../../../utils/hooks/useMe";

interface GameRes extends SelectedGodForGameI {
  name: string;
}

function GameResultModal_new({ result }: GameResultModalPropsI) {
  if (!result) return null;
  const navigate = useNavigate();
  const isTablet = useMediaQuery("max", 992);
  const gameProf = useGameProf();
  const me = useMe();

  const [looser, setLooser] = useState<GameRes>();
  const [winner, setWinner] = useState<GameRes>();

  useEffect(() => {
    if (drawGame) {
      setWinner({ ...result.HomeGod, name: result.HomeName });
      setLooser({ ...result.AwayGod, name: result.AwayName || "" });
      return;
    }

    if (isMe && myAwayWin()) {
      setWinner({ ...result.AwayGod, name: result.AwayName || "" });
      setLooser({ ...result.HomeGod, name: result.HomeName });
    } else if (isMe && myHomeWin()) {
      setWinner({ ...result.HomeGod, name: result.HomeName });
      setLooser({ ...result.AwayGod, name: result.AwayName || "" });
    } else if (!isMe && result.Result === 1) {
      setWinner({ ...result.HomeGod, name: result.HomeName });
      setLooser({ ...result.AwayGod, name: result.AwayName || "" });
    } else if (!isMe && result.Result === 2) {
      setWinner({ ...result.AwayGod, name: result.AwayName || "" });
      setLooser({ ...result.HomeGod, name: result.HomeName });
    } else if (isMe && !myHomeWin()) {
      setWinner({ ...result.AwayGod, name: result.AwayName || "" });
      setLooser({ ...result.HomeGod, name: result.HomeName });
    } else if (isMe && !myAwayWin()) {
      setWinner({ ...result.HomeGod, name: result.HomeName });
      setLooser({ ...result.AwayGod, name: result.AwayName || "" });
    } else if (drawGame) {
      setWinner({ ...result.HomeGod, name: result.HomeName });
      setLooser({ ...result.AwayGod, name: result.AwayName || "" });
    }
  }, []);

  const isMe =
    result.AwayName === (gameProf.GameName || me?.Address) ||
    result.HomeName === (gameProf.GameName || me?.Address);

  const drawGame = result.Result === 0;

  const checkingWin = () => {
    if (!isMe) return;
    switch (result.Result) {
      // case GameResE.DROW:
      //   return false;
      case GameResE.WIN:
        return { homeWin: result.HomeName === (gameProf.GameName || me?.Address) };
      case GameResE.LOSE:
        return { awayWin: result.AwayName === (gameProf.GameName || me?.Address) };
      default:
        return undefined;
    }
  };

  const myAwayWin = () => checkingWin()?.awayWin;
  const myHomeWin = () => checkingWin()?.homeWin;

  const myLose = !(myAwayWin() || myHomeWin());

  const isPVE = result.Mode === ResGameModeE.PVE;
  const isNFT = !result.SoulStake;

  const setTitle = () => `${isPVE ? "PVE" : "PVP"} | Battle Result`;
  const setSubtitle = () =>
    isPVE
      ? result.IsFree
        ? "Free battle mode"
        : "SOUL Mode"
      : result.SoulStake
      ? "SOUL Mode"
      : "NFT Mode";

  const renderGodImg = () => {
    if (drawGame) {
      return winner?.Preview;
    } else if (isNFT) {
      return looser?.Preview;
    } else {
      return winner?.Preview;
    }
  };
  const toHome = () => navigate(RoutsGameE.Home);
  const toHistory = () => navigate(RoutsGameE.History);
  const betString = (win = false) => {
    if (result.AwayName === "Environment" && !isMe) {
      return `+ ${result.SoulStake} SOUL`;
    }
    if (!isMe) {
      return result.SoulStake ? `+ ${result.SoulStake} SOUL` : "+ NFT";
    }
    return result.SoulStake
      ? ` ${win ? "+" : "-"} ${result.SoulStake} SOUL`
      : ` ${win ? "+" : "-"} NFT`;
  };

  return (
    <div className={styles.wrapper}>
      <ModalHeader title={setTitle()} subtitle={setSubtitle()} />

      <div className={styles.content}>
        <GodImage img={makeImagePath(renderGodImg())} className={styles.img} />
        <div className={styles.result}>
          <h5>
            {isMe && (myAwayWin() || myHomeWin())
              ? "You win"
              : isMe && myLose && !drawGame
              ? "You lose"
              : drawGame
              ? "DRAW"
              : `${winner?.name} Win`}
          </h5>
          <h4
            className={cn({
              [styles.win]: myAwayWin() || myHomeWin() || (!isMe && !drawGame),
              [styles.lose]: isMe && myLose && !drawGame,
            })}
          >
            {drawGame ? "DRAW GAME" : betString(!myLose)}
          </h4>
          <div className={styles.descr}>
            <p>{drawGame ? winner?.Name : isNFT ? looser?.Name : winner?.Name}</p>
            <p>
              {uniqString(
                drawGame ? winner?.Uniqueness : isNFT ? looser?.Uniqueness : winner?.Uniqueness
              )}{" "}
              | Level {drawGame ? winner?.Level : isNFT ? looser?.Level : winner?.Level}
            </p>
          </div>
          {drawGame && !isTablet && (
            <div className={styles.descr} style={{ float: "right" }}>
              <p style={{ textAlign: "end" }}>
                {drawGame ? looser?.Name : isNFT ? winner?.Name : looser?.Name}
              </p>
              <p style={{ textAlign: "end" }}>
                {uniqString(
                  drawGame ? looser?.Uniqueness : isNFT ? winner?.Uniqueness : looser?.Uniqueness
                )}{" "}
                | Level {drawGame ? looser?.Level : isNFT ? winner?.Level : looser?.Level}
              </p>
            </div>
          )}
        </div>
        {drawGame && !isTablet && (
          <GodImage img={makeImagePath(looser?.Preview)} className={styles.img} />
        )}
      </div>
      <div className={styles.btn_wrapper}>
        <GameBtn type={BtnType.LIGHT} onClick={toHistory}>
          History
        </GameBtn>
        <GameBtn type={BtnType.LIGHT} onClick={toHome}>
          HOME
        </GameBtn>
      </div>
    </div>
  );
}

export default WithGameModal(GAME_RESULT_MODAL, GameResultModal_new);
