import { AnyAction, applyMiddleware, combineReducers, createStore, Store } from "redux";
import { reducer as modals } from "redux-modal";
import thunk, { ThunkDispatch } from "redux-thunk";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import persistReducer from "redux-persist/lib/persistReducer";
import persistStore from "redux-persist/lib/persistStore";
import auth, { AuthState } from "./auth/reducer";
import me from "./me/reducer";
import prices from "./prices/reducer";
import * as authActions from "./auth/actions";
import { createTransform } from "redux-persist";

const rootReducer = combineReducers({
  modals,
  auth,
  me,
  //game reducers
  prices,
});

export type RootState = ReturnType<typeof rootReducer>;
export type RootDispatch = ThunkDispatch<RootState, void, AnyAction>;

const startupConnectTransform = createTransform<AuthState, AuthState>(
  (stateIn) => stateIn,
  (stateOut) => {
    const state: AuthState = {
      ...stateOut,
      startupConnect: !!stateOut.token && !!stateOut.wallet,
      connecting: undefined,
      err: undefined,
    };
    if (
      state.token &&
      state.wallet &&
      (state.token.wallet.account !== state.wallet.account ||
        state.token.wallet.type !== state.wallet.type)
    )
      return { ...state, token: undefined, wallet: undefined };
    return state;
  },
  { whitelist: ["auth"] }
);
const persistConfig = {
  key: "NRT4w5@#$T@DNN#$$GDFBSEB",
  storage,
  whitelist: ["auth"],
  stateReconciler: autoMergeLevel2,
  transforms: [startupConnectTransform],
};

let store: Store<RootState> | undefined = undefined;

const store_ = () => store;
export default store_;

export async function initStore(): Promise<Store<RootState>> {
  const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer);
  store = createStore(persistedReducer, applyMiddleware(thunk)) as Store<RootState>;
  await new Promise<void>((r) => persistStore(store as Store, null, r));
  await store.dispatch(authActions.init());
  return store;
}
