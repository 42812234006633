import { useEffect, useState } from "react";
import { Toasts } from "../../../../utils/toasts";
import { ModalProps } from "../../../HOC/withModal";
import {
  FStepsModal2Control,
  FStepsModal2Props,
  SaleDir,
  StepCode,
  TransferDir,
} from "./FStepsModal2";
import api from "../../../../services/api";
import isEnoughBalance from "../../../../utils/isEnoughBalance";
import Erc20Service from "../../../../services/bch/erc20";
import { Coins } from "../../../../constants";
import useMe, { Me } from "../../../../utils/hooks/useMe";
import wallet from "../../../../services/wallet";
import { isServerError } from "../../../../services/api/setting";
import ws, { isGodTransferDone, isTokenUpgrade, WSEventsE } from "../../../../services/ws";
import { unlistFromSale } from "../UnlistFromSale/UnlistFromSaleControl";
import { GodInfo } from "../../../../services/api/gods";
import { getCurrPrice } from "../../../../utils/getCurrPrice";
import { AreYouSureProps } from "../../AreYouSure/AreYouSure";
import { setApprovalForErc } from "../../../../services/bch/approval";
import { checkTokenID, Order, prepareSellOrder } from "../../../../services/bch/order";
import { storeOrder } from "../../../../services/api/sale";
import mkError from "../../../../utils/mkError";
import { DGCL_DOMAIN } from "../../../../config";
import { openDgclLink } from "../../../../utils/openDgclLink";

async function tranfserToMyWallet(
  tokenId: string,
  coll: string,
  count: number,
  me: Me,
  next: () => void
): Promise<number | Error> {
  try {
    const resp = await api.gods.getTransferFee(tokenId, count);
    if (isServerError(resp)) {
      throw resp;
    }
    const getFeeRes = resp.data;
    if (getFeeRes.Fee < 0) {
      throw Error(`${getFeeRes.Available} tokens available for transfer`);
    }
    const b = Erc20Service.convertToWei(getFeeRes.Fee, Coins.ETH);
    if (!isEnoughBalance(b, Coins.ETH, me)) {
      throw Error("Insufficient funds. Top up your wallet and try again.");
    }
    const transferEthRes = await wallet.transferEth(getFeeRes.Fee, getFeeRes.Address);
    if (!transferEthRes || !transferEthRes.status) {
      return Error("Fee transferring error");
    }
    const p = new Promise<number>((r) => {
      ws.once((ev) => {
        if (!isGodTransferDone(ev)) return false;
        const e = ev.Event;
        if (e.TokenId !== tokenId || e.Collection !== coll) return false;
        r(e.GodsCount);
        return true;
      });
    });
    next();
    const tresp = await api.gods.transferToMyWallet(
      tokenId,
      count,
      transferEthRes.transactionHash,
      getFeeRes.Fee,
      true
    );
    if (isServerError(tresp)) throw tresp;
    return await p;
  } catch (e) {
    return mkError(e);
  }
}

const godForUpdate = (group: GodInfo[], tokenID: string) => {
  const index = group.findIndex((item) => item.TokenID === tokenID);
  return {
    nextGod: group[index + 1]
      ? {
          ...group[index + 1],
          UpgradePrice: getCurrPrice(
            group[index + 1].UpgradePrice,
            group[index + 1].UpgradeCurrency
          ),
        }
      : null,
    prevGod: group[index] as GodInfo,
  };
};

function useFStepsModal2Control(props: FStepsModal2Props & ModalProps): FStepsModal2Control {
  const [step, setStep] = useState(0);
  const [progress, setProgress] = useState(false);
  const [saleDir, setSaleDir] = useState<SaleDir>();
  const [transferredCount, setTransferredCount] = useState<number>();
  const [showAreYouSure, setShowAreYouSure] = useState<AreYouSureProps>();
  const [steps, setSteps] = useState<StepCode[]>([]);
  const me = useMe();

  const areYouSure = async (title: string): Promise<boolean> =>
    new Promise<boolean>((r) => {
      setShowAreYouSure({
        title,
        done: (ok) => {
          setShowAreYouSure(undefined);
          r(ok);
        },
      });
    });

  const reset = () => {
    setStep(0);
    setProgress(false);
    setSaleDir(undefined);
    setTransferredCount(undefined);
  };

  useEffect(() => {
    if (!props.steps) return;

    for (const d of [StepCode.SellOnDigicol, StepCode.SellOnOpenSea, StepCode.UnlistFromSale]) {
      if (props.steps.includes(d)) {
        setSaleDir(d as SaleDir);
        break;
      }
    }

    const steps = [];
    for (const s of props.steps) {
      steps.push(s);
      switch (s) {
        case StepCode.TransferToUser:
          steps.push(StepCode.DoTransferToUser);
          break;
      }
    }

    setSteps(steps);
  }, [props.steps]);

  const finish = (e?: Error, res?: any) => {
    if (e) Toasts.Error(e.message);
    props.modalClose();
    const result = e || res;
    props.done && props.done(result);
    reset();
  };

  const updateOrder = async (): Promise<Error | void> => {
    if (!props.godOnSale || !me?.Address) return Error("unknown error");
    const god = props.godOnSale;
    try {
      const oresp = await api.sale.getOrder(god.TokenID, god.Collection, me?.Address);
      if (isServerError(oresp)) {
        return oresp;
      }
      const oldOrder = JSON.parse(oresp.data.Order.Order) as Order;
      oresp.data.Order.Order;
      let resp = await api.sale.exportToken(god.TokenID, god.Collection);
      if (isServerError(resp)) {
        return resp;
      }
      await setApprovalForErc(god.Collection, true);
      const { order, signedOrder } = await prepareSellOrder(
        checkTokenID(god.TokenID),
        god.Collection,
        oldOrder.buying,
        (god.Count || 0) + 1,
        false,
        oldOrder.key.buyAsset.token
      );
      resp = await storeOrder(JSON.stringify(order), JSON.stringify(signedOrder));
      if (isServerError(resp)) return resp;
      openDgclLink(god.Collection, god.TokenID, me.Address);
    } catch (e) {
      return mkError(e);
    }
  };

  useEffect(() => {
    if (steps[step] !== StepCode.UpdateDigicolOrder) return;
    (async () => {
      setProgress(true);
      const err = await updateOrder();
      setProgress(false);
      if (!err) {
        props.progress && props.progress(StepCode.Done);
        if (step === steps.length - 1) {
          finish(undefined, true);
        } else setStep((s) => s + 1);
      } else {
        props.progress && props.progress(StepCode.Cancel);
        finish(err);
        return;
      }
    })();
  }, [step]);

  const self = {
    step,
    steps,
    progress,
    saleDir,
    areYouSure: showAreYouSure,

    async transfer(dir: TransferDir, final: boolean) {
      if (!me || !props.god) return;
      setProgress(true);
      try {
        if (dir === StepCode.TransferToUser) {
          props.progress && props.progress(StepCode.TransferToUser);
          const res = await tranfserToMyWallet(
            props.god.TokenID,
            props.god.Collection,
            props.god.Count && props.god.Count > 0 ? props.god.Count : 1,
            me,
            () => setStep((s) => s + 1)
          );
          if (typeof res !== "number") throw res;
          setTransferredCount(res);
        } else {
          // StepCode.TransferToSystem
          const count = 1;
          props.progress && props.progress(StepCode.TransferToSystem);
          const res = await wallet.transferToken(
            props.god.TokenID,
            props.god.SystemWallet,
            props.god.Collection,
            count
          );
          const a = await api.gods.transferToSystemWallet(
            props.god.TokenID,
            count,
            res.transactionHash
          );
          if (isServerError(a)) throw a;
          setTransferredCount(count);
        }
      } catch (e) {
        props.progress && props.progress(StepCode.Cancel);
        const err = mkError(e);
        this.finish(err);
        return;
      } finally {
        setProgress(false);
      }
      props.progress && props.progress(StepCode.Done);
      if (final) {
        this.finish(undefined, true);
      } else setStep((s) => s + 1);
    },

    putOnSale(final: boolean) {
      if (final) {
        this.finish(undefined, transferredCount);
      } else throw Error("unknown error");
    },

    finish,

    async unlistFromSale(final: boolean) {
      if (!me?.Address || !props.god) return;
      setProgress(true);
      try {
        await unlistFromSale(props.god.TokenID, props.god.Collection, me.Address);
      } catch (e) {
        const err = mkError(e);
        this.finish(err);
        return;
      } finally {
        setProgress(false);
      }
      if (final) {
        this.finish(undefined, true);
      } else setStep((s) => s + 1);
    },

    stake(final: boolean) {
      if (final) {
        this.finish(undefined, true);
      } else throw Error("unknown error");
    },

    async unstake(final: boolean) {
      if (!me?.Address || !props.god) return;
      setProgress(true);
      try {
        const ok = await areYouSure("Are you sure you want to unstake this token?");
        if (!ok) {
          this.finish(undefined, false);
          return;
        }
        const resp = await api.gods.stopStaking(props.god.Staked);
        if (isServerError(resp)) {
          throw resp;
        }
        props.progress && props.progress(StepCode.Unstake);
      } catch (e) {
        const err = mkError(e);
        this.finish(err);
        return;
      } finally {
        setProgress(false);
      }
      if (final) {
        this.finish(undefined, true);
      } else setStep((s) => s + 1);
    },

    async upgrade(final: boolean) {
      if (!me?.Address || !props.god) return;
      setProgress(true);
      try {
        const resp = await api.gods.checkUpgrade(
          props.god.TokenID,
          props.god.Collection,
          props.god.Note
        );
        if (isServerError(resp)) {
          throw resp;
        }
        if (!resp.data.Available) {
          Toasts.Info("This god doesn't have a new levels yet");
          this.finish(undefined, false);
          return;
        }
        let transferEthRes: any;
        try {
          if (!isEnoughBalance(resp.data.Price, Coins[resp.data.Coin], me)) {
            throw Error("Insufficient funds. Top up your wallet and try again.");
          }
          if (resp.data.Coin === "ETH") {
            transferEthRes = await wallet.transferEth(resp.data.Price, resp.data.Address, false);
          } else {
            transferEthRes = await wallet.transfer(
              resp.data.Address,
              resp.data.Price,
              resp.data.Coin,
              false
            );
          }
          if (!transferEthRes || !transferEthRes.status) {
            throw Error("Fee transferring error");
          }
        } catch (e) {
          api.gods.abortUpgrade(resp.data.UpgradeID);
          throw e;
        }
        const p = new Promise<boolean>((r) => {
          ws.once((ev) => {
            if (!isTokenUpgrade(ev)) return false;
            const e = ev.Event;
            if (e.OldTokenId !== props.god?.TokenID) return false;
            r(ev.Type === WSEventsE.SMT_TOKEN_UPGRADE_FINISHED);
            return true;
          });
        });
        const resp1 = await api.gods.upgrade(resp.data.UpgradeID, transferEthRes.transactionHash);
        if (isServerError(resp1)) {
          throw resp1;
        }
        if (!(await p)) {
          throw Error("Upgrade was aborted on error");
        }
      } catch (e) {
        const err = mkError(e);
        this.finish(err);
        return;
      } finally {
        setProgress(false);
      }
      if (final) {
        this.finish(undefined, true);
      } else setStep((s) => s + 1);
    },
  };

  return self;
}

export default useFStepsModal2Control;
