import detectEthereumProvider from "@metamask/detect-provider";
import WalletType from "../../utils/walletType";
import Web3 from "web3";
import Fortmatic from "fortmatic";
import { provider } from "web3-core";
import { providers } from "ethers";
import { ExternalProvider, Web3Provider } from "@ethersproject/providers";
import { WidgetMode } from "fortmatic/dist/cjs/src/core/sdk";
import WalletLink from "walletlink";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { VALID_CHAIN } from "../../config";

const CHAIN_ID = parseInt(VALID_CHAIN || "0x3", 16);
const FORTMATIC_API_KEY = CHAIN_ID === 1 ? "pk_live_F77A7DCA752F5B47" : "pk_test_A39FDBAE56694167";
const APP_NAME = "godtemple.io";
const APP_LOGO_URL = "https://godtemple.io/favicon.ico";
const INFURA_API_KEY = "05994203012249fb863914d1f18d1c36";
const ETH_JSONRPC_URL = `https://${
  CHAIN_ID === 1 ? "mainnet" : "ropsten"
}.infura.io/v3/${INFURA_API_KEY}`;

export interface Source {
  enable(): Promise<void>;
}

export interface Provider extends Web3Provider, Source {
  disconnect?: () => void;
  source?: Source;
  fm?: WidgetMode;
}

export interface ProviderInfo {
  provider: Provider;
  web3: Web3;
  walletLink?: WalletLink;
}

export interface ProviderFactory {
  readonly Type: string;
  create(): Promise<ProviderInfo>;
}

export class DCMetamaskConnectProviderFactory implements ProviderFactory {
  get Type() {
    return WalletType.METAMASK;
  }
  async create(): Promise<ProviderInfo> {
    const prov = (await detectEthereumProvider()) as Provider;
    const web3 = new Web3(prov as unknown as provider);

    return {
      provider: prov as Provider,
      web3,
    };
  }
}

export class DCFortmaticProviderFactory implements ProviderFactory {
  get Type() {
    return WalletType.FORTMATIC;
  }

  async create(): Promise<ProviderInfo> {
    const fm =
      CHAIN_ID === 1
        ? new Fortmatic(FORTMATIC_API_KEY)
        : new Fortmatic(FORTMATIC_API_KEY, "ropsten");

    const fmProvider = fm.getProvider();
    const web3 = new Web3(fmProvider as provider);
    const provider: any = new providers.Web3Provider(fmProvider as unknown as ExternalProvider);
    provider["source"] = fmProvider;
    provider["fm"] = fm;

    return {
      provider: provider as Provider,
      web3,
    };
  }
}

export class DCWalletLinkProviderFactory implements ProviderFactory {
  get Type() {
    return WalletType.COIN_BASE;
  }

  async create(): Promise<ProviderInfo> {
    const walletLink = new WalletLink({
      appName: APP_NAME,
      appLogoUrl: APP_LOGO_URL,
      darkMode: false,
    });

    const provider = walletLink.makeWeb3Provider(ETH_JSONRPC_URL, CHAIN_ID);
    const web3 = new Web3(provider);

    return {
      provider: provider as unknown as Provider,
      web3,
      walletLink,
    };
  }

  async disconnect() {
    return ((await this.create()).walletLink as WalletLink).disconnect();
  }
}

export class DCWalletConnectProviderFactory implements ProviderFactory {
  get Type() {
    return WalletType.WALLET_CONNECT;
  }

  async create(): Promise<ProviderInfo> {
    const provider = new WalletConnectProvider({
      infuraId: INFURA_API_KEY,
      chainId: CHAIN_ID,
      clientMeta: {
        icons: ["https://godtemple.io/col_logo/dgcl.png"],
        name: "godtemple.io",
      } as any,
      bridge: "https://rarible.bridge.walletconnect.org/",
    });

    const web3 = new Web3(provider as unknown as provider);

    return {
      provider: provider as unknown as Provider,
      web3,
    };
  }
}
