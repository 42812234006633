import { useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { useConnector } from "../../../../../utils/hooks/useAccount";
import { GAME_CONNECT_MODAL } from "../../../../../utils/modalGameNames";
import WalletType from "../../../../../utils/walletType";
import WithGameModal, { ModalGameProps } from "../../../../HOC/GameHOC/withGameModal";
import GameBtn from "../../../GameFolder/GameBtn/GameBtn";
import { BtnType } from "../../../GameFolder/GameBtn/GameBtn.props";
import GameLoader from "../../../GameFolder/GameLoader/GameLoader";
import styles from "./GameConnectModal.module.scss";

function GameConnectModal({ modalClose }: ModalGameProps) {
  const wallets = [
    WalletType.METAMASK,
    WalletType.COIN_BASE,
    WalletType.FORTMATIC,
    WalletType.WALLET_CONNECT,
  ];

  const connector = useConnector();
  const connecting = useSelector((state: RootState) => state.auth.connecting);
  const connect = useCallback(
    async (name: WalletType) => {
      try {
        await connector.connect(name);
      } finally {
        modalClose();
      }
    },
    [connector]
  );

  const renderWallets = () => {
    return wallets.map((name, i) => (
      <GameBtn
        className={styles.connect_btn}
        onClick={() => connect(name)}
        type={BtnType.CONNECT}
        key={i}
      >
        {connecting === name ? <GameLoader size={30} style={{ width: "auto" }} /> : name}
      </GameBtn>
    ));
  };
  return (
    <div className={styles.connect}>
      <h4> Connect Your Wallet </h4>
      <p>Your wallet will be used to securely store your NFT collectibles and cryptocurrencies</p>

      <div className={styles.wallets}>{renderWallets()}</div>
    </div>
  );
}

export default WithGameModal(GAME_CONNECT_MODAL, GameConnectModal);
