import { GAME_TRANSFER_SOUL } from "../../../../../utils/modalGameNames";
import WithGameModal from "../../../../HOC/GameHOC/withGameModal";
import cn from "classnames";
import styles from "./TransferSoulModal.module.scss";
import { ModalHeader } from "../GameModal";
import GameBtn from "../../../GameFolder/GameBtn/GameBtn";
import GameSelectorValue from "../../../GameFolder/GameSelectorValue/GameSelectorValue";
import { useTransferSoulModalControl } from "./useTransferSoulModalControl";

import { ReactComponent as Arrow } from "../../../../../assets/Icons/game/arrow.svg";
import erc20 from "../../../../../services/bch/erc20";
import GameLoader from "../../../GameFolder/GameLoader/GameLoader";

function TransferSoulModal() {
  const control = useTransferSoulModalControl();
  return (
    <>
      {control.isLoading && <GameLoader global wh />}

      <ModalHeader title='Transfer SOUL' />
      <div className={styles.wrapper}>
        <GameSelectorValue
          from
          transfer
          balance={
            control.isSysWallet ? control.gameProf.soulBalance : control.gameProf.mySoulBalance
          }
          options={control.options}
          getSelectorValue={control.setSelectorFrom}
          value={control.valueFrom}
          selectorValue={control.selectorFrom}
          getValue={control.setValueFrom}
          isError={!control.isEnoughSoul}
        />
        <Arrow className={styles.icon} />
        <div className={styles.to_wallet_wrapper}>
          <div className={styles.top}>
            <span>To</span>
            <span>
              Balance:{" "}
              {erc20.convertWei(
                !control.isSysWallet
                  ? control.gameProf.soulBalance
                  : control.gameProf.mySoulBalance,
                "SOUL",
                4
              )}{" "}
              SOUL
            </span>
          </div>
          <p className={styles.dropdown}>{control.selectorTo.name}</p>
        </div>
      </div>
      <div className={styles.bottom_wrapper}>
        <span onClick={control.openBuyModal}> Buy SOUL Token Now </span>
        <GameBtn
          className={cn(styles.btn, {
            ["disabled"]: !control.isEnoughSoul,
          })}
          onClick={control.transferOrWithdrow}
          disabled={!control.isEnoughSoul}
        >
          Confirm
          {!control.isEnoughSoul && <span> Not Enough SOUL </span>}
        </GameBtn>
      </div>
    </>
  );
}

export default WithGameModal(GAME_TRANSFER_SOUL, TransferSoulModal);
