export enum ActionTypes {
  GET_USER = "ME_GET_USER",
  GET_USER_SUCCESS = "ME_GET_USER_SUCCESS",
  GET_USER_ERROR = "ME_GET_USER_ERROR",
  UPDATE_USER = "ME_UPDATE_USER",
  UPDATE_USER_SUCCESS = "ME_UPDATE_USER_SUCCESS",
  UPDATE_USER_ERROR = "ME_UPDATE_USER_ERROR",
  UPDATE_ETH_BALANCE = "ME_UPDATE_ETH_BALANCE",
  UPDATE_WETH_BALANCE = "ME_UPDATE_WETH_BALANCE",
  UPDATE_MYTH_BALANCE = "ME_UPDATE_MYTH_BALANCE",
  UPDATE_USDT_BALANCE = "ME_UPDATE_USDT_BALANCE",
  PREPARE_UPDATE_GAME_BALANCE = "PREPARE_UPDATE_GAME_BALANCE",
  UPDATE_GAME_BALANCE = "UPDATE_GAME_BALANCE",
  UPDATE_BALANCE = "ME_UPDATE_BALANCE",
}

export interface ActionBase {
  type: ActionTypes;
  payload?: any;
}

export interface ActionSetBalance extends ActionBase {
  payload: {
    ethBalance: string;
    usdtBalance: string;
    wethBalance: string;
    mythBalance: string;
    soulBalance: string;
    mySoulBalance: string;
    blockedBalance: string;
  };
}
