import cn from "classnames";
import styles from "./Search.module.scss";
import { SearchPropsI } from "./Search.props";
import { ReactComponent as SearchIcon } from "../../../../assets/Icons/game/gameSearch.svg";

export default function Search({ placholder, className, defaultValue, ...props }: SearchPropsI) {
  return (
    <div className={cn(styles.wrapper, className)} {...props}>
      <SearchIcon className={styles.icon} />
      <input placeholder={placholder || "Search"} defaultValue={defaultValue} />
    </div>
  );
}
