export interface FormParams {
  [k: string]: string | Blob;
}

export interface PreserveParams {
  [k: string]: boolean;
}

const buildFormData = (
  params: FormParams = {},
  removeEmpty = true,
  preserve: PreserveParams = {}
): FormData => {
  const formData = new FormData();
  for (const key of Object.keys(params)) {
    if (!params[key] && removeEmpty && !preserve[key]) {
      continue;
    }
    let val = params[key];
    if (!(val instanceof Blob)) {
      val = "" + val;
    }
    formData.append(key, val);
  }
  return formData;
};

export default buildFormData;
