import { CHANGE_NETWORK_MODAL } from "../../../../utils/modalNames";
import WithModal, { ModalProps } from "../../../HOC/withModal";
import styles from "./ChangeNetworkModal.module.scss";
import cn from "classnames";
import Loader from "../../Loader/Loader";
import { useEffect, useState } from "react";
import { Toasts } from "../../../../utils/toasts";
import { useConnector } from "../../../../utils/hooks/useAccount";
import WalletType from "../../../../utils/walletType";
import WithGameModal from "../../../HOC/GameHOC/withGameModal";

interface ChangeI extends ModalProps {
  isGame?: boolean;
  cb(): Promise<Error | WalletType>;
}

function ChangeNetworkModal({ cb, ...props }: ChangeI) {
  const [load, setLoad] = useState<boolean>(false);
  const connector = useConnector();
  const onSwitch = async () => {
    try {
      setLoad(true);
      const res = await cb();
      if (typeof res === "object") return Toasts.error(res);
      connector.connect(res);
    } catch (e) {
      Toasts.error(e);
    } finally {
      props.modalClose();
    }
  };

  return (
    <div className={styles.wrapper}>
      <h4>Please swtich to Ethereum network</h4>

      <div className={styles.content}>
        In order to access to God Temple, please switch to Ethereum network within your Metamask
        wallet.
      </div>
      {load ? (
        <Loader />
      ) : (
        <div className={styles.btns}>
          <button className={cn("btn", "btn--white", {})} onClick={props.modalClose}>
            Cancel
          </button>
          <button className={cn("btn", {})} onClick={onSwitch}>
            Switch network
          </button>
        </div>
      )}
    </div>
  );
}

export default WithModal(CHANGE_NETWORK_MODAL, ChangeNetworkModal);
