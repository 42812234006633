import styles from "./Loader.module.scss";
import { CircleLoader } from "react-spinners";
import cn from "classnames";
import { DetailedHTMLProps, HTMLAttributes } from "react";

interface LoaderPropsI
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  global?: boolean;
  size?: number;
  isAbsolute?: boolean;
  color?: string;
}

export default function Loader({
  global,
  size = 100,
  isAbsolute = false,
  color = "#993626",
  ...props
}: LoaderPropsI): JSX.Element {
  return (
    <div
      className={cn(styles.wrapper, {
        [styles.global]: global,
        [styles.isAbsolute]: isAbsolute,
      })}
      {...props}
    >
      <CircleLoader color={color} size={size} />
    </div>
  );
}
