import { Link } from "react-router-dom";
import { RoutsGameE } from "../../../utils/gameRoutsName";
import cn from "classnames";
import styles from "./GameLayout.module.scss";
import GameBtn from "../../global/GameFolder/GameBtn/GameBtn";
import { RoutesE } from "../../../utils/routesNames";
import useHeaderGameControl from "./useHeaderGameControl";
import GameLink from "../../global/GameFolder/GameLink/GameLink";

import { ReactComponent as GameHome } from "../../../assets/Icons/game/logo.svg";
import Notif from "../../../assets/Icons/game/notif.svg";
import NotifRed from "../../../assets/Icons/game/notifRed.svg";
import GameNotificationAll from "../../global/modals/GameFolder/GameNotificationAll/GameNotificationAll";
import useMediaQuery from "../../../utils/hooks/useMediaQuery";
import GameBurger from "./GameBurger/GameBurger";
import { useState } from "react";
import { soulFromWei } from "../../../utils/soulFromWei";
import GameLoader from "../../global/GameFolder/GameLoader/GameLoader";
import Icon from "../../global/GameFolder/Icon/Icon";
import GameNotificationsBlock from "../../global/modals/GameFolder/GameNotificationsBlock/GameNotificationsBlock";

export default function GameHeader() {
  const control = useHeaderGameControl();
  const tablet = useMediaQuery("max", 900);
  const [openBurger, setOpenBurger] = useState(false);

  const links = [
    { name: "Home", url: RoutsGameE.Home },
    { name: "Buy SOUL", url: RoutsGameE.Buy_soul, onClick: control.showBuyModal },
    {
      name: "Buy Packs",
      url: "temple/0x26050ed95756247af721497095eca4f60aaf0cd5",
    },
    { name: "Tutorial", url: RoutsGameE.Tutorial },
    { name: "Battle", url: RoutsGameE.Battle },
    { name: "Rankings", url: RoutsGameE.Rankings },
    { name: "Rooms", url: RoutsGameE.Rooms },
    { name: "History", url: RoutsGameE.History },
  ];
  return (
    <header className={styles.header}>
      {control.openAllNotif && (
        <GameNotificationAll notifications={control.notif} onClose={control.onCloseAllNotif} />
      )}
      <div className={styles.gradient} />
      <div
        className={cn("container", styles.content, {
          [styles.openBurger]: openBurger,
        })}
      >
        <div className={styles.menu}>
          <Link to={RoutesE.Main} className={styles.logo}>
            <GameHome />
          </Link>
          <nav
            className={cn(styles.nav, {
              [styles.active]: openBurger,
            })}
          >
            {links.map((link, i) => (
              <GameLink to={link.url} key={i} onClick={link.onClick}>
                {link.name}
              </GameLink>
            ))}
          </nav>
        </div>
        <div className={styles.login_wrapper}>
          {control.me?.Address ? (
            <>
              <div className={styles.notif}>
                {control.newNotif ? (
                  <Icon
                    className={styles.notif_icon}
                    onClick={control.showNotifications}
                    src={NotifRed}
                  />
                ) : (
                  <Icon
                    src={Notif}
                    className={styles.notif_icon}
                    onClick={control.showNotifications}
                  />
                )}
                {control.showNotif && (
                  <GameNotificationsBlock
                    notifications={control.notif}
                    onClose={control.onCloseNotif}
                    onOpenAllNotif={control.onOpenAllNotif}
                  />
                )}
              </div>
              <GameBtn
                className={cn(styles.btn, styles.balance)}
                onClick={control.showTransferModal}
              >
                {control.gameProf.loader ? (
                  <GameLoader size={20} color='#fff' />
                ) : (
                  `${soulFromWei(control.gameProf.soulBalance, 1)} SOUL`
                )}
              </GameBtn>
              <GameBtn className={cn(styles.btn, styles.logout)} onClick={control.onDisconnect}>
                logout
              </GameBtn>
            </>
          ) : (
            <GameBtn className={cn(styles.btn, styles.login)} onClick={control.showConnectModal}>
              wallet
            </GameBtn>
          )}
          {tablet && <GameBurger open={setOpenBurger} active={openBurger} />}
        </div>
      </div>
    </header>
  );
}
