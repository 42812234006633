import { ModalHeader } from "../GameModal";
import cn from "classnames";
import styles from "./GameOops.module.scss";
import { GameOopsPropsI } from "./GameOops.props";

import { ReactComponent as Warning } from "../../../../../assets/Icons/game/warning.svg";
import GameBtn from "../../../GameFolder/GameBtn/GameBtn";
import { BtnType } from "../../../GameFolder/GameBtn/GameBtn.props";
import WithGameModal from "../../../../HOC/GameHOC/withGameModal";
import { GAME_OOPS_MODAL } from "../../../../../utils/modalGameNames";
import { useGameOopsControl } from "./useGameOopsControl";

function GameOops({ title, subtitle, isBattle }: GameOopsPropsI) {
  const control = useGameOopsControl(isBattle);

  return (
    <>
      <ModalHeader title='OOPS' />
      <div className={styles.wrapper}>
        {title && <h5> {title} </h5>}
        <div className={styles.subtitle_wrapper}>
          <Warning className={styles.icon} />
          {subtitle ? (
            <p> {subtitle} </p>
          ) : (
            <div>
              <p>The battle has been concluded.</p>
              <p>Please refresh and try again</p>
            </div>
          )}
        </div>
        <div className={styles.bottom}>
          <GameBtn type={BtnType.LIGHT} onClick={control.onClose}>
            {isBattle ? "BATTLE ROOM" : "GO BACK"}
          </GameBtn>
        </div>
      </div>
    </>
  );
}

export default WithGameModal(GAME_OOPS_MODAL, GameOops, { oops: true });
