import { GAME_HOW_PLAY_MODAL } from "../../../../../utils/modalGameNames";
import WithGameModal from "../../../../HOC/GameHOC/withGameModal";
import styles from "./HowToPlay.module.scss";

import group from "./Group.png";
// import { ReactComponent as Intelegent } from "../../../../../assets/Icons/game/skillsIcon.svg";
// import { ReactComponent as Strength } from "../../../../../assets/Icons/game/powerIcon.svg";
// import { ReactComponent as Agility } from "../../../../../assets/Icons/game/speedIcon.svg";

function HowToPlay() {
  return (
    <div className={styles.how_to_play}>
      <h4> How to play </h4>
      <div className={styles.body}>
        <img src={group} />
        {/* <div className={styles.power_type}>
          <div className={styles.icon}>
            <Strength />
          </div>
          <div className={styles.descr}>
            <h4>Strength</h4>
            <p>Strength beats Intelligence</p>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default WithGameModal(GAME_HOW_PLAY_MODAL, HowToPlay);
