import Uniqueness from "./uniqueness";
import common from "../assets/img/temple/pack-1.jpg";
import rare from "../assets/img/temple/pack-2.jpg";
import mythical from "../assets/img/temple/pack-3.jpg";
import errPack from "../assets/img/temple/pack_0.png";

const uniqString = (un: Uniqueness = 0) =>
  un === Uniqueness.Common
    ? "Basic"
    : un === Uniqueness.Rare
    ? "Rare"
    : un === Uniqueness.Mythical
    ? "Mythical"
    : "Basic";

export default uniqString;

const uniqImg = (un: Uniqueness | undefined) => {
  switch (un) {
    case Uniqueness.Common:
      return common;
    case Uniqueness.Rare:
      return rare;
    case Uniqueness.Mythical:
      return mythical;
    default:
      return errPack;
  }
};
export { uniqImg };
