import { ActionBase, ActionSetBalance, ActionTypes } from "./actionTypes";
import { UserInfo } from "../../services/api/me";

export interface UserState {
  user?: UserInfo;
  loading?: boolean;
  error: Error | null;
  ethBalance: string;
  wethBalance: string;
  mythBalance: string;
  usdtBalance: string;
  gameProf: GameUserStateI;
}

export interface GameUserStateI {
  soulBalance: string;
  mySoulBalance: string;
  blockedBalance: string;
  GameName: string;
  loader: boolean;
}

const initialState: UserState = {
  loading: false,
  error: null,
  ethBalance: "0",
  wethBalance: "0",
  mythBalance: "0",
  usdtBalance: "0",
  gameProf: {
    soulBalance: "0",
    mySoulBalance: "0",
    blockedBalance: "0",
    GameName: "",
    loader: true,
  },
};

const meReducer = (state: UserState = initialState, action: ActionBase): UserState => {
  const { type, payload = null } = action;
  if (type.startsWith("ME_")) {
    //console.log("ME ACTION:", action);
  }
  switch (type) {
    case ActionTypes.GET_USER:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: payload,
        gameProf: {
          ...state.gameProf,
          GameName: payload.GameName,
        },
      };
    case ActionTypes.GET_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case ActionTypes.UPDATE_USER:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: { ...state.user, ...payload },
        gameProf: {
          ...state.gameProf,
          GameName: payload.GameName,
        },
      };
    case ActionTypes.UPDATE_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case ActionTypes.UPDATE_BALANCE:
      // eslint-disable-next-line no-case-declarations
      const {
        ethBalance,
        usdtBalance,
        wethBalance,
        mythBalance,
        soulBalance,
        blockedBalance,
        mySoulBalance,
      } = (action as ActionSetBalance).payload;
      return {
        ...state,
        ethBalance,
        usdtBalance,
        wethBalance,
        mythBalance,
        gameProf: {
          ...state.gameProf,
          soulBalance: (+soulBalance - +blockedBalance).toString(),
          blockedBalance,
          mySoulBalance,
          loader: false,
        },
      };
    case ActionTypes.UPDATE_ETH_BALANCE:
      return {
        ...state,
        ethBalance: payload,
      };
    case ActionTypes.UPDATE_WETH_BALANCE:
      return {
        ...state,
        wethBalance: payload,
      };
    case ActionTypes.UPDATE_MYTH_BALANCE:
      return {
        ...state,
        mythBalance: payload,
      };
    case ActionTypes.UPDATE_USDT_BALANCE:
      return {
        ...state,
        usdtBalance: payload,
      };
    case ActionTypes.PREPARE_UPDATE_GAME_BALANCE:
      return {
        ...state,
        gameProf: {
          ...state.gameProf,
          loader: true,
        },
      };
    case ActionTypes.UPDATE_GAME_BALANCE:
      return {
        ...state,
        gameProf: {
          ...state.gameProf,
          soulBalance: (+payload.soulBalance - +payload.blockedBalance).toString(),
          blockedBalance: payload.blockedBalance,
          mySoulBalance: payload.mySoulBalance,
          loader: false,
        },
      };
    default:
      return state;
  }
};

export default meReducer;
