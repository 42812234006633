import { lazy } from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import { RoutesE } from "./utils/routesNames";
import { SuspendComponent } from "./utils/SuspendComponent";
import "react-image-lightbox/style.css";
import { RoutsGameE } from "./utils/gameRoutsName";
import GameHomeProvider from "./components/GameFolder/GameHome/GameHomeProvider";
import GTContextProvider from "./GTContext/GTContext";

const Home = lazy(() => import("./components/pages/Home"));
const About = lazy(() => import("./components/pages/About"));
const Temples = lazy(() => import("./components/pages/Temples"));
const Temple = lazy(() => import("./components/pages/Temple"));
const ClaimTokens = lazy(() => import("./components/pages/ClaimTokens"));
const Pack = lazy(() => import("./components/pages/Pack"));
const God = lazy(() => import("./components/pages/GodPage"));
const User = lazy(() => import("./components/pages/User"));
const Staking = lazy(() => import("./components/pages/Staking"));
//game
const GameBattleMode = lazy(
  () => import("./components/GameFolder/GameBattleMode/GameBattleMode")
);
const GameTutorial = lazy(
  () => import("./components/GameFolder/Tutorial/Tutorial")
);
const GameRooms = lazy(
  () => import("./components/GameFolder/GameBattleRooms/GameBattleRooms")
);
const GameRankings = lazy(
  () => import("./components/GameFolder/GameRankings/GameRankings")
);
const GameHistory = lazy(
  () => import("./components/GameFolder/GameHistory/GameHistory")
);
const GamePrepare = lazy(
  () => import("./components/GameFolder/GameSelectGods/GameSelectGods")
);
const GameForecast = lazy(
  () => import("./components/GameFolder/GameForecast/GameForecast")
);
const GameBattleground = lazy(
  () => import("./components/GameFolder/GameBattleground/GameBattleground")
);

function App(): JSX.Element {
  if (window.location.pathname === "/") window.location.pathname = "/r/";

  return (
    <div className="App">
      <Router basename="r/">
        <ScrollToTop />
        {/* КОнтекст перенесет вот сюда */}
        <GTContextProvider>
          <Routes>
            <Route path={RoutesE.Main} element={SuspendComponent(Home)} />
            <Route path={RoutesE.About} element={SuspendComponent(About)} />
            <Route path={RoutesE.Temple} element={SuspendComponent(Temple)} />
            <Route path={RoutesE.Temples} element={SuspendComponent(Temples)} />
            <Route
              path={RoutesE.Claims}
              element={SuspendComponent(ClaimTokens)}
            />
            <Route path={RoutesE.Pack} element={SuspendComponent(Pack)} />
            <Route path={RoutesE.God} element={SuspendComponent(God)} />
            <Route path={RoutesE.GodNote} element={SuspendComponent(God)} />
            <Route path={RoutesE.User} element={SuspendComponent(User)} />
            <Route path={RoutesE.Staking} element={SuspendComponent(Staking)} />

            <Route
              path={RoutsGameE.Home}
              element={SuspendComponent(GameHomeProvider, { game: true })}
            />
            <Route
              path={RoutsGameE.Tutorial}
              element={SuspendComponent(GameTutorial, { game: true })}
            />
            <Route
              path={RoutsGameE.Battle}
              element={SuspendComponent(GameBattleMode, { game: true })}
            />
            <Route
              path={RoutsGameE.Rooms}
              element={SuspendComponent(GameRooms, { game: true })}
            />
            <Route
              path={RoutsGameE.Rankings}
              element={SuspendComponent(GameRankings, { game: true })}
            />
            <Route
              path={RoutsGameE.History}
              element={SuspendComponent(GameHistory, { game: true })}
            />
            <Route
              path={RoutsGameE.Select_gods}
              element={SuspendComponent(GamePrepare, { game: true })}
            />
            <Route
              path={RoutsGameE.Forecast}
              element={SuspendComponent(GameForecast, { game: true })}
            />
            <Route
              path={RoutsGameE.BattleRoom}
              element={SuspendComponent(GameBattleground, { game: true })}
            />
          </Routes>
        </GTContextProvider>
      </Router>
    </div>
  );
}

export default App;
