import { AxiosResponse } from "axios";
import { ServerResponse } from "../setting";
import service from "../service";
import Uniqueness from "../../../utils/uniqueness";

export enum ResGameModeE {
  PVE,
  PVP,
}

export enum GameResE {
  DROW,
  WIN,
  LOSE,
}

export interface SelectedGodForGameI {
  Level: number;
  Name: string;
  Preview: string;
  Uniqueness: Uniqueness;
}

export interface RoomInfoI {
  AwayGod: SelectedGodForGameI;
  HomeGod: SelectedGodForGameI;
  AwayTurns: string;
  HomeTurns: string;
  Result: GameResE;
  Rounds: string;
  SoulStake: number;
  Mode: ResGameModeE;
  HomeName: string;
  AwayName?: string;
  IsFree: boolean;
}

export interface PveBattleResponseI extends ServerResponse {
  Info: RoomInfoI;
}

export function roomInfo(
  RoomId: number | string
): Promise<AxiosResponse<PveBattleResponseI> | Error> {
  return service.api.post("/gt/game/roominfo", {
    RoomId,
  });
}
