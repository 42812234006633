import contracts from "./addresses";
import { BigNumber as EthersBigNumber, ethers } from "ethers";
import wallet from "../../services/wallet";
import contractsAbi from "./abi";
import { AbiItem } from "web3-utils";
import BigNumber from "bignumber.js";
import { MYTH_WALLET_PROXY } from "../../config";

export const ETH_DECIMALS = EthersBigNumber.from("1000000000000000000"); // ethers.constants.WeiPerEther
export const MYTH_DECIMALS = EthersBigNumber.from("1000000000000000000"); // ethers.constants.WeiPerEther
export const SOUL_DECIMALS = EthersBigNumber.from("1000000000000000000"); // ethers.constants.WeiPerEther
export const DGCL_DECIMALS = EthersBigNumber.from("1000000000000000000"); // ethers.constants.WeiPerEther
export const USDT_DECIMALS = EthersBigNumber.from("1000000");

interface CurrencyDescr {
  Symbol: string;
  Address: string;
  IsDepositable: boolean;
  Decimals: EthersBigNumber;
  IsDeprecated?: boolean;
}

export const mockCurrencies: CurrencyDescr[] = [
  {
    Symbol: "WETH",
    Address: contracts.CONTRACT_WETH,
    IsDepositable: true,
    Decimals: ETH_DECIMALS,
  },
  {
    Symbol: "USDT",
    Address: contracts.CONTRACT_USDT,
    IsDepositable: false,
    Decimals: USDT_DECIMALS,
  },
  {
    Symbol: "MYTH",
    Address: contracts.CONTRACT_MYTH,
    IsDepositable: false,
    Decimals: MYTH_DECIMALS,
  },
  {
    Symbol: "SOUL",
    Address: contracts.CONTRACT_SOUL,
    IsDepositable: false,
    Decimals: SOUL_DECIMALS,
  },
  {
    Symbol: "DGCL",
    Address: contracts.CONTRACT_DGCL,
    IsDepositable: false,
    Decimals: DGCL_DECIMALS,
  },
];

export const ETH_ADDRESS = "0x0000000000000000000000000000000000000000";

class Erc20Service {
  private currencies: { [key: string]: CurrencyDescr };
  proxy?: string | (() => Promise<string>);

  constructor(list: CurrencyDescr[], proxy?: string | (() => Promise<string>)) {
    this.currencies = list.reduce((obj, item) => ({ ...obj, [item.Symbol]: item }), {});
    this.proxy = proxy;
  }

  async getProxy(): Promise<string | undefined> {
    if (typeof this.proxy === "function") {
      return await this.proxy();
    }
    return this.proxy;
  }

  canDeposit = (curr: string): boolean => this.currencies[curr]?.IsDepositable;
  getAddress = (curr: string): string =>
    curr !== "ETH" ? this.currencies[curr]?.Address : ETH_ADDRESS;
  isDeprecated = (curr: string): boolean => this.currencies[curr]?.IsDeprecated ?? false;

  getSymbol = (addr: string): string => {
    const symbol =
      addr &&
      addr !== ETH_ADDRESS &&
      addr !== "0x0" &&
      mockCurrencies.find((item) => item.Address.toLowerCase() === addr.toLowerCase())?.Symbol;
    return symbol ? symbol : "ETH";
  };

  getDecimals = (curr: string): EthersBigNumber => {
    return curr !== "ETH" ? this.currencies[curr]?.Decimals : ETH_DECIMALS;
  };

  getDecimalsByAddr = (addr: string): EthersBigNumber => {
    const decimal =
      addr &&
      addr !== ETH_ADDRESS &&
      addr !== "0x0" &&
      mockCurrencies.find((item) => item.Address.toLowerCase() === addr.toLowerCase())?.Decimals;
    return decimal ? decimal : ETH_DECIMALS;
  };

  async getCurrencyBalance(curr: string, from?: string) {
    const walletAddress = from || wallet.account;
    if (!walletAddress) return 0;
    const contract = wallet.createContract(contractsAbi.ERC20 as AbiItem[], this.getAddress(curr));
    if (!contract) return 0;
    try {
      const result = await contract.methods.balanceOf(walletAddress).call();
      return result;
    } catch (e) {
      return 0;
    }
  }

  convertWei(balance: string, curr: string, decimalPlaces?: number): string {
    const decimals = this.getDecimals(curr).toString();
    if (typeof decimalPlaces === "number")
      return new BigNumber(balance).dividedBy(decimals).toFixed(decimalPlaces);
    return new BigNumber(balance).dividedBy(decimals).toFixed();
  }

  convertToWei(amount: BigNumber.Value, currency: string): string {
    try {
      const decimals = this.getDecimals(currency).toString();
      const weiInString = new BigNumber(amount).multipliedBy(decimals).toFixed();
      return weiInString.split(".")[0];
    } catch {
      return "0";
    }
  }
}

const service = new Erc20Service(mockCurrencies);
export default service;
