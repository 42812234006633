import { AxiosResponse } from "axios";
import Uniqueness from "../../utils/uniqueness";
import { MyGodInfo } from "./gods";
import service from "./service";
import { ServerResponse } from "./setting";

export interface PackInfo {
  Id: number;
  Hide: boolean;
  TempleAddress: string;
  Photo: string; //путь к файлу
  Background: string; //путь к файлу
  PriceValue: number;
  PriceCoin: string;
  DescShort: string;
  DescFull: string;
  Uniqness: Uniqueness; //0-common, 1-rare, 2-myth
  BasicGods: number;
  RareGods: number;
  MythicalGods: number;
  TotalCount: number; //сколько всего создано, No Of Packs
  AvailableCount: number; //сколько осталось на продажу
}

export interface PackResponse extends ServerResponse {
  Record: PackInfo;
}

export function pack(
  pack: string | number
): Promise<AxiosResponse<PackResponse> | Error> {
  return service.api.get("/gt/pack/info", { params: { PackId: pack } });
}

export function buy(
  packid: number,
  count: number
): Promise<AxiosResponse<ServerResponse> | Error> {
  return service.api.post("/gt/pack/buy", {
    PackId: packid,
    Count: count,
  });
}

export interface UserPackInfo {
  ID: number;
  Uniqueness: Uniqueness;
  Image: string;
  ShortDesc: string;
  FullDesc: string;
}

export interface UserPacksResponse extends ServerResponse {
  Total: number;
  Records: UserPackInfo[];
}
export interface OpenPackResponse extends ServerResponse {
  Gods: MyGodInfo[];
}

export async function my(
  offset = 0,
  limit = 100
): Promise<AxiosResponse<UserPacksResponse> | Error> {
  const params: any = { offset, limit };
  return await service.api.get("/gt/packs/my", { params });
}

export async function openPack(
  Packid: number | string
): Promise<AxiosResponse<OpenPackResponse> | Error> {
  return await service.api.post("/gt/packs/open", { Packid });
}
