import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { show } from "redux-modal";
import api from "../../../services/api";
import { MyGameGod, WalletFE } from "../../../services/api/gods";
import { isServerError } from "../../../services/api/setting";
import { RoutsGameE } from "../../../utils/gameRoutsName";
import { GAME_OOPS_MODAL } from "../../../utils/modalGameNames";
import { Toasts } from "../../../utils/toasts";
import { DDOptionI } from "../../global/GameFolder/GameDropdown/GameDropdown.props";
import { AppContext } from "../../HOC/GameHOC/AppContextProvider";

export enum GameType {
  SOUL = "SOUL",
  NFT = "NFT",
}

interface GameSelectGodsControlI {
  readonly value_d1: DDOptionI;
  readonly value_d2: DDOptionI;
  readonly mode: string;
  readonly total: number;
  readonly loading: boolean;
  readonly gods: MyGameGod[];
  readonly hasMore: boolean;

  setSearch(str: string): void;
  setValue_d1(option: DDOptionI): void;
  setValue_d2(option: DDOptionI): void;
  back(): void;
  setSelectedGod(god: MyGameGod): void;
  getGods(): void;
}

export const useGameSelectGodsControl = ({
  dd1,
  dd2,
}: {
  [key: string]: DDOptionI[];
}): GameSelectGodsControlI => {
  const firstRender = useRef(false);

  const dispatch = useDispatch();
  const { state } = useLocation() as { state: any };
  const { playSound } = useContext(AppContext);
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");
  const [value_d1, setValue_d1] = useState<DDOptionI>(dd1[0]);
  const [value_d2, setValue_d2] = useState<DDOptionI>(dd2[0]);
  const [selectedGod, setSelectedGod] = useState<MyGameGod>();
  const [gods, setGods] = useState<MyGameGod[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    // playSound(SoundTypeE.GENERAL);
    getGods();
  }, []);

  const clear = (r: any) => {
    Toasts.Error(r.message);
    console.error(r);
    setLoading(false);
    setGods([]);
    setTotal(0);
  };

  //берем богов
  const getGods = async () => {
    const res = await api.gods.gameGods({
      type: value_d1.select,
      level: value_d2.select,
      wallet: WalletFE.SYS,
      offset: gods.length,
    });
    if (!isServerError(res)) {
      setTotal(res.data.Total);
      setGods([...gods, ...res.data.Records]);
      setLoading(false);
      firstRender.current = true;

      if (res.data.Records.length === 0)
        dispatch(
          show(GAME_OOPS_MODAL, {
            title: "You don't have an NFT",
            subtitle: "in the System Wallet to play the game",
          })
        );
      return;
    }
    clear(res);
  };
  const getFilteredGods = async () => {
    setLoading(true);
    const res = await api.gods.gameGods({
      type: value_d1.select,
      level: value_d2.select,
      wallet: WalletFE.SYS,
    });
    if (!isServerError(res)) {
      setTotal(res.data.Total);
      setGods(res.data.Records);
      setLoading(false);
      return;
    }
    clear(res);
  };
  useEffect(() => {
    if (firstRender.current) {
      const t = setTimeout(() => getFilteredGods(), 1000);
      return () => clearTimeout(t);
    }
  }, [value_d1, value_d2, search]);

  useEffect(() => {
    if (selectedGod) {
      // если сюда попали для присоединения к комнате
      if (state.isConnectToRoom) {
        return navigate(RoutsGameE.Forecast, {
          state: {
            selectedGod,
            bet: state.bet,
            mode: state.mode,
            gameType: state.gameType,
            isConnectToRoom: state.isConnectToRoom,
            room: state.room,
          },
        });
      } else {
        navigate(RoutsGameE.Forecast, {
          state: {
            selectedGod,
            bet: state.bet,
            mode: state.mode,
            gameType: state.gameType,
            isFree: state.isFree,
          },
        });
      }
    }
  }, [selectedGod]);

  const hasMore = gods.length !== 0 ? gods.length < total : false;

  return {
    value_d1,
    value_d2,
    mode: state.mode,
    total,
    loading,
    gods,
    hasMore,

    setSearch,
    setValue_d1,
    setValue_d2,
    back: () => window.history.back(),
    setSelectedGod,
    getGods,
  };
};
