import { ServerResponse, isServerError } from "../services/api/setting";
import { AxiosResponse } from "axios";

export default async function asyncExecute<T extends ServerResponse>(
  request: () => Promise<AxiosResponse<T> | Error>,
  stateUpdater?: (value: boolean) => void
): Promise<T> {
  if (stateUpdater) stateUpdater(true);
  const response = await request();
  if (stateUpdater) stateUpdater(false);
  if (isServerError(response)) {
    return Promise.reject(response.message);
  }
  if (response.data.Status !== "success") {
    return Promise.reject(response.data.Message ?? "Unknown error");
  }
  return Promise.resolve(response.data);
}
