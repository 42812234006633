import { AxiosResponse } from "axios";
import { FileObj } from "../../components/global/modals/EditModal/useEditControl";
import { ServerResponse } from "http";
import buildFormData, { FormParams } from "../../utils/buildFormData";
import { info } from "./me";
import service from "./service";
import { PossiblyError } from "./setting";

export interface Message {
  name: string;
  email: string;
  bio?: string;
  files?: FileObj[];
}

export interface Form {
  user_name: string;
  user_email: string;
  user_desc?: string;
  //   file?: File;
}

export async function editProfileData(msg: Message): Promise<PossiblyError> {
  const form: Form = {
    user_name: msg.name,
    user_email: msg.email,
  };

  if (msg.bio) {
    form.user_desc = msg.bio;
  }

  if (msg.files && msg.files.length > 0) {
    const findLogo = msg.files.find((item) => item.id === "profile")?.file;
    const findBanner = msg.files.find((item) => item.id === "banner")?.file;

    const profileFile = findLogo
      ? buildFormData({
          file: findLogo as unknown,
        } as FormParams)
      : null;
    const bannerFile = findBanner
      ? buildFormData({
          file: findBanner as unknown,
        } as FormParams)
      : null;

    profileFile && (await service.api.put("set_user_logo", profileFile));
    bannerFile && (await service.api.put("set_user_background", bannerFile));
  }

  return await service.api.post("edit_field", form);
}
