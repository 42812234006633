import { useState } from "react";
import cn from "classnames";
import styles from "./GameImage.module.scss";
import { GameImagePropsI } from "./GameImage.props";
import Skeleton from "../../Skeleton/Skeleton";
import Lightbox from "react-image-lightbox";
import Loader from "../../Loader/Loader";

export default function GameImage({
  src,
  alt,
  isLoading,
  zoomable,
  className,
  ...props
}: GameImagePropsI): JSX.Element {
  const [loadImg, setLoadImg] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const haveZoom = zoomable && isOpen;

  return (
    <>
      <img
        className={cn(styles.img, className)}
        onClick={() => setIsOpen(true)}
        src={src}
        alt={alt}
        onLoad={() => setLoadImg(true)}
        hidden={!loadImg}
        {...props}
      />
      {!loadImg && <Skeleton godToken style={{ width: "100%", borderRadius: "initial" }} />}
      {haveZoom && <Lightbox mainSrc={src} onCloseRequest={() => setIsOpen(false)} />}
      {isLoading && <Loader isAbsolute={true} />}
    </>
  );
}
