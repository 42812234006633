import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { UserInfo, UserStatus } from "../../services/api/me";
import { RootState } from "../../store";
import { GameUserStateI } from "../../store/me/reducer";
import { useAccount } from "./useAccount";

export interface Me extends UserInfo {
  ethBalance: string;
  wethBalance: string;
  mythBalance: string;
  usdtBalance: string;
  gameProf: GameUserStateI;
  FreeGames: number;
}

const defaultMeInfo = (account?: string): Me => ({
  Address: account || "0x0",
  Status: UserStatus.NOT_ACTIVE,
  Following: 0,
  Followers: 0,
  IFollow: false,
  ethBalance: "0",
  wethBalance: "0",
  mythBalance: "0",
  usdtBalance: "0",
  gameProf: {
    GameName: "",
    mySoulBalance: "0",
    soulBalance: "0",
    blockedBalance: "0",
    loader: true,
  },
  FreeGames: 0,
});

function useMe(): Me | undefined {
  const account = useAccount();
  const state = useSelector((state: RootState) => state.me);
  const [me, setMe] = useState<Me | undefined>(account ? defaultMeInfo(account) : undefined);
  const startupConnect = useSelector((state: RootState) => state.auth.startupConnect);

  useEffect(() => {
    if (startupConnect) {
      setMe(defaultMeInfo(account));
      return;
    }
    if (!account) {
      setMe(undefined);
      return;
    }
    if (!state.user || state.user.Address?.toLowerCase() !== account.toLowerCase()) {
      return;
    }
    setMe({
      ...state.user,
      ethBalance: state.ethBalance,
      wethBalance: state.wethBalance,
      mythBalance: state.mythBalance,
      usdtBalance: state.usdtBalance,
      gameProf: state.gameProf,
      FreeGames: state.user.FreeGames,
    });
  }, [
    account,
    state.user,
    state.user?.Address,
    state.ethBalance,
    state.wethBalance,
    state.usdtBalance,
    state.mythBalance,
    state.gameProf,
    startupConnect,
  ]);
  return me;
}

export default useMe;
