import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { GameLinkPropsI } from "./GameLink.props";
import cn from "classnames";
import styles from "./GameLink.module.scss";

export default function GameLink({ children, to, className, ...props }: GameLinkPropsI) {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });
  return (
    <div
      className={cn(styles.link, className, {
        [styles.active_link]: match && !!to,
      })}
      {...props}
    >
      {to.length > 0 ? <Link to={to}>{children}</Link> : <a href='#'> {children} </a>}
    </div>
  );
}
