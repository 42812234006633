import { GAME_BATTLE_RESULT_MODAL } from "../../../../../utils/modalGameNames";
import WithGameModal from "../../../../HOC/GameHOC/withGameModal";
import GameBtn from "../../../GameFolder/GameBtn/GameBtn";
import { BtnType } from "../../../GameFolder/GameBtn/GameBtn.props";
import { ModalHeader } from "../GameModal";
import styles from "./BattleResultModal.module.scss";
import { BattleResultModalPropsI } from "./BattleResultModal.props";
import { gmtTime } from "../../../../../utils/gmtTime";

function BattleResultModal(props: BattleResultModalPropsI) {
  return (
    <>
      <ModalHeader
        title={`${props.AwayName ? "PVP" : "PVE"} | Battle Result`}
        subtitle={`Battle ID : ${props.RoomId}`}
      />

      <div className={styles.wrapper}>
        <div className={styles.result}>{props.Score}</div>
        <div className={styles.bet}>{props.SoulStake ? `${props.SoulStake} SOUL` : "NFT"}</div>
        <div className={styles.vs}></div>
        <div className={styles.bottom}>
          <span>
            Battle Time: <br /> {gmtTime(props.Created)}
          </span>
          <GameBtn type={BtnType.LIGHT} onClick={props.viewBattle}>
            view
          </GameBtn>
        </div>
      </div>
    </>
  );
}

export default WithGameModal(GAME_BATTLE_RESULT_MODAL, BattleResultModal);
