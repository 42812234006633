import WalletType from "../../utils/walletType";
import {
  ActionTypes,
  ActionSetWallet,
  ActionSetToken,
  ActionSetConnecting,
  ActionSetError,
  ActionBase,
} from "./actionTypes";
import * as me from "../../store/me/actions";
import apiService from "../../services/api/service";
import { RootDispatch } from "../../store";
import isError from "../../utils/isError";

const TOKEN_VALIDITY_PERIOD_IN_SECONDS = 40 * 60 * 60; //40 часов

export function setWallet(
  account?: string,
  type?: WalletType
): ActionSetWallet {
  return {
    type: ActionTypes.SET_WALLET,
    payload: { account, type },
  };
}

export function cleanToken(): ActionBase {
  return { type: ActionTypes.CLEAN_TOKEN };
}

// вызывается только из getUser
function setToken(token: string, expires: number): ActionSetToken {
  return {
    type: ActionTypes.SET_TOKEN,
    payload: { token, expires },
  };
}

export function setConnecting(type?: WalletType): ActionSetConnecting {
  return {
    type: ActionTypes.SET_CONNECTING,
    payload: { type },
  };
}

export function setError(err?: Error): ActionSetError {
  return {
    type: ActionTypes.SET_ERROR,
    payload: { err },
  };
}

// вызывается только из initStore
export function init(): ActionBase {
  return {
    type: ActionTypes.INIT,
  };
}

export function getUser(token?: string) {
  return async (dispatch: RootDispatch) => {
    let expires = 0;

    if (token) {
      const date = new Date();
      date.setSeconds(date.getSeconds() + TOKEN_VALIDITY_PERIOD_IN_SECONDS);
      expires = date.getTime();
      apiService.api.setToken(token, expires);
    }
    const err = await dispatch(me.getUser());
    if (isError(err)) {
      dispatch(cleanToken());
      return;
    }

    if (token) dispatch(setToken(token, expires));
    else dispatch(setConnecting());
  };
}
