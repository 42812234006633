import { AxiosResponse } from "axios";
import { ServerResponse } from "./setting";
import service from "./service";

export interface PricesInfo {
  Pair: string;
  Price: number;
  Updated: number;
}

export interface PricesResponse extends ServerResponse {
  Info: PricesInfo[];
}

export function prices(): Promise<AxiosResponse<PricesResponse> | Error> {
  return service.api.get("/prices");
}
