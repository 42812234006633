import cn from "classnames";
import styles from "./GameDataString.module.scss";

import { ReactComponent as Confirm } from "../../../../assets/Icons/game/confirm.svg";
import { GameDataStringPropsI } from "./GameDataString.props";

export default function GameDataString({ title, name, price, confirm }: GameDataStringPropsI) {
  return (
    <div className={styles.wrapper}>
      <label> {title} </label>
      <div className={styles.game_data}>
        <span> {name} </span>
        <span
          className={cn({
            [styles.confirm]: confirm,
          })}
        >
          {price} {confirm && <Confirm className={styles.icon} />}
        </span>
      </div>
    </div>
  );
}
