import { useLocation } from "react-router-dom";
import GameHomeLogin from "./GameHomeLogin/GameHomeLogin";
import GameHome from "./GameHome";

export default function GameHomeProvider() {
  const location = useLocation();
  const user = location.search.replace("?user=", "");

  const fake = {
    playSound: () => null,
    setIsLoading: () => null,
    isLoading: false,
  };

  if (user) {
    return <GameHomeLogin {...fake} />;
  } else {
    return <GameHome {...fake} />;
  }
}
