import cn from "classnames";
import { GAME_SELECTED_FREE_MODAL } from "../../../../../utils/modalGameNames";
import WithGameModal, { ModalGameProps } from "../../../../HOC/GameHOC/withGameModal";
import GameBtn from "../../../GameFolder/GameBtn/GameBtn";
import { BtnType } from "../../../GameFolder/GameBtn/GameBtn.props";
import { ModalHeader } from "../GameModal";
import styles from "./FreeBattleModal.module.scss";
import { useFreeBattleModalControl } from "./useFreeBattleModalControl";

function FreeBattleModal() {
  const control = useFreeBattleModalControl();
  return (
    <>
      <ModalHeader title={"PVE | Free Game"} subtitle={`10 free games to play every day`} />
      <div className={styles.wrapper}>
        <h2>{control.games}</h2>
        <p>Free Game</p>

        <div className={styles.bottom}>
          <span>{control.timeUpdate}</span>
          {control.haveGames && (
            <GameBtn type={BtnType.DARK} onClick={control.toSelectGod}>
              Select god
            </GameBtn>
          )}
        </div>
      </div>
    </>
  );
}
export default WithGameModal(GAME_SELECTED_FREE_MODAL, FreeBattleModal, { battle: true });
