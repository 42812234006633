import buildFormData, { FormParams } from "../../utils/buildFormData";
import service from "./service";
import { PossiblyError } from "./setting";

export interface Message {
  name: string;
  email: string;
  message: string;
  files: File[];
  reCapToken: string;
}

export interface Form {
  Name: string;
  Email: string;
  Message: string;
  file: File;
  "g-recaptcha-response": string;
}

export function send(msg: Message): Promise<PossiblyError> {
  const form: Form = {
    Name: msg.name,
    Email: msg.email,
    Message: msg.message,
    file: msg.files[0],
    "g-recaptcha-response": msg.reCapToken,
  };
  const formData = buildFormData(form as unknown as FormParams);
  return service.api.post("support", formData);
}
