import { GameChallengeModalPropsI } from "./GameChallengeModal.props";
import cn from "classnames";
import styles from "./GameChallengeModal.module.scss";
import { ModalHeader } from "../GameModal";
import WithGameModal from "../../../../HOC/GameHOC/withGameModal";
import { GAME_CHALLENGE_MODAL } from "../../../../../utils/modalGameNames";
import GameBtn from "../../../GameFolder/GameBtn/GameBtn";
import { BtnType } from "../../../GameFolder/GameBtn/GameBtn.props";
import { gmtTime } from "../../../../../utils/gmtTime";
import { lastBattles } from "../../../../../utils/lastResults";
import reduceAddress from "../../../../../utils/reduceAddress";

function GameChallengeModal({
  roomId,
  roomTime,
  bet,
  winRate,
  player,
  goTo,
  ...props
}: GameChallengeModalPropsI) {
  return (
    <>
      <ModalHeader title='PVP  |  Challenge ?' subtitle={`Battle ID: ${roomId}`} />
      <div className={styles.wrapper}>
        <p>Use Token</p>
        <h4>{bet}</h4>

        <div className={styles.host_wrapper}>
          <label> Player </label>
          <div className={styles.host}>
            <p> {reduceAddress(player)} </p>
            <div className={styles.winrate}>{lastBattles(winRate).map((item, i) => item)}</div>
          </div>
        </div>
        <div className={styles.bottom}>
          <span>Open Room Time : {gmtTime(roomTime)}</span>
          <GameBtn type={BtnType.LIGHT} onClick={goTo}>
            Confirm
          </GameBtn>
        </div>
      </div>
    </>
  );
}

export default WithGameModal(GAME_CHALLENGE_MODAL, GameChallengeModal);
