import { useContext } from "react";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import { MyGameGod } from "../../../../services/api/gods";
import { GAME_GOD_MODAL } from "../../../../utils/modalGameNames";
import { AppContext, SoundTypeE } from "../../../HOC/GameHOC/AppContextProvider";

interface GameGodCard {
  onSelect(event: React.MouseEvent): void;
  onActivate(): void;
  onEnter(e: React.KeyboardEvent): void;
  onHover(): void;
}

export const useGameGodCardControl = (
  god: MyGameGod,
  selectGod?: (god: any) => void,
  battle?: boolean
): GameGodCard => {
  const dispatch = useDispatch();
  const { playSound } = useContext(AppContext);

  const onActivate = () => {
    if (battle) return;
    playSound(SoundTypeE.SMALL);
    dispatch(show(GAME_GOD_MODAL, { ...god }));
  };
  const onEnter = (e: React.KeyboardEvent) => {
    if (battle) return;
    if (e && e.key === "Enter") {
      playSound(SoundTypeE.SMALL);
      dispatch(show(GAME_GOD_MODAL, { ...god }));
      return;
    }
  };
  const onSelect = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    selectGod && selectGod(god);
  };
  const onHover = () => playSound(SoundTypeE.HOVER);

  return {
    onSelect,
    onActivate,
    onEnter,
    onHover,
  };
};
