import {  useState } from "react";

export interface DropdownReturn {
  openDropdown: boolean;

  setOpenDropdown(isOpen: boolean): void;
}

export function useControlGameDropdown(): DropdownReturn {
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);

  return {
    openDropdown,

    setOpenDropdown,
  };
}
