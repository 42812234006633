import BigNumber from "bignumber.js";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { hide, show } from "redux-modal";
import {
  exchange,
  exchangerate,
  SoulExchangerate,
} from "../../../../../services/api/game/gameSoul";
import { isServerError } from "../../../../../services/api/setting";
import erc20 from "../../../../../services/bch/erc20";
import wallet from "../../../../../services/wallet";
import { GameUserStateI } from "../../../../../store/me/reducer";
import { useGameProf } from "../../../../../utils/hooks/gameHooks/useGameProf";
import useMe, { Me } from "../../../../../utils/hooks/useMe";
import { GAME_BUY_SOUL, GAME_CONNECT_MODAL } from "../../../../../utils/modalGameNames";
import { soulFromWei } from "../../../../../utils/soulFromWei";
import { Toasts } from "../../../../../utils/toasts";
import { DDOptionI } from "../../../GameFolder/GameDropdown/GameDropdown.props";
interface buySoulControlI {
  readonly gameProf: GameUserStateI;
  readonly me?: Me;
  readonly options: DDOptionI[];
  readonly selectorFrom: DDOptionI;
  readonly valueFrom: string | number;
  readonly selectorTo: DDOptionI;
  readonly valueTo: string | number;
  readonly isEnough?: boolean;
  readonly exRate?: string;
  readonly isLoading: boolean;

  setSelectorFrom(selector: DDOptionI): void;
  setValueFrom(value: number): void;
  setSelectorTo(selector: DDOptionI): void;
  setValueTo(value: number): void;
  onSwap(): void;
}

export const useBuySoulControl = (): buySoulControlI => {
  const gameProf = useGameProf();
  const me = useMe();
  const options = [
    { name: "MYTH", select: 0 },
    { name: "SOUL", select: 0 },
  ];
  const dispatch = useDispatch();
  const [selectorFrom, setSelectorFrom] = useState<DDOptionI>(options[0]);
  const [valueFrom, setValueFrom] = useState<number>(0);
  const [selectorTo, setSelectorTo] = useState<DDOptionI>(options[1]);
  const [valueTo, setValueTo] = useState<string | number>(0);
  const [exchangeData, setExchangeData] = useState<SoulExchangerate>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (selectorTo === selectorFrom) {
      setSelectorTo(options.filter((item) => item !== selectorFrom)[0]);
    }
  }, [selectorFrom === selectorTo]);

  useEffect(() => {
    setValueFrom(0);
    setValueTo(0);
  }, [selectorFrom]);

  const getSoulRate = async () => {
    const res = await exchangerate();
    if (isServerError(res)) {
      return console.error(res.message);
    }
    setExchangeData(res.data);
  };
  useEffect(() => {
    if (me) {
      getSoulRate();
    } else {
      dispatch(hide(GAME_BUY_SOUL));
      dispatch(show(GAME_CONNECT_MODAL));
    }
  }, []);

  useEffect(() => {
    if (!exchangeData) return;

    if (selectorFrom.name === options[0].name) {
      setValueTo(valueFrom * +exchangeData.Rates[0].Price);
    } else {
      setValueTo(valueFrom / +exchangeData.Rates[0].Price);
    }
  }, [valueFrom]);

  const isEnough = () => {
    if (!me) return;
    if (selectorFrom.name === options[0].name) {
      return valueFrom <= +erc20.convertWei(me.mythBalance, "MYTH", 4);
    } else {
      return valueFrom <= +soulFromWei(gameProf.soulBalance);
    }
  };

  const exchangeString = () => {
    if (!exchangeData) return;
    if (selectorFrom.name === options[0].name) {
      return `1 MYTH = ${exchangeData?.Rates[0].Price} SOUL`;
    } else {
      return `1 SOUL = ${1 / +exchangeData.Rates[0].Price} MYTH`;
    }
  };

  const onSwap = async () => {
    if (!exchangeData) return console.error("no exchangeData");

    if (valueFrom === 0) {
      return Toasts.Info("You can't to buy a 0 SOUL");
    }

    try {
      setIsLoading(true);
      const transferRes = await wallet.transferSoul(valueFrom.toString(), "MYTH");

      const exResponse = await exchange({
        SrcAmount: new BigNumber(valueFrom).toString(10),
        Coin: "MYTH", // пока что только мифы
        TxHash: transferRes.transactionHash,
        SoulAmount: valueTo.toString(),
        RateUid: exchangeData?.Uid,
      });
      if (!isServerError(exResponse)) {
        setIsLoading(false);
        dispatch(hide(GAME_BUY_SOUL));
        Toasts.Info(`Please wait while a ${valueTo} SOUL is being bought`, true);
      } else {
        Toasts.Error(exResponse.message);
      }
    } catch (e: any) {
      if (e.code === 4001) {
        Toasts.Error(e.message);
        setIsLoading(false);
        return;
      }
    }
  };

  return {
    gameProf,
    me,
    options,
    selectorFrom,
    valueFrom,
    selectorTo,
    valueTo,
    isEnough: isEnough(),
    exRate: exchangeString(),
    isLoading,

    setSelectorFrom,
    setValueFrom,
    setSelectorTo,
    setValueTo,
    onSwap,
  };
};
