import { AxiosResponse } from "axios";
import { ServerResponse } from "../setting";
import service from "../service";

export interface UserBalanceResponse extends ServerResponse {
  Balance: string;
  Blocked: string;
}

export function userBalance(): Promise<AxiosResponse<UserBalanceResponse> | Error> {
  return service.api.get("/gt/balance");
}
