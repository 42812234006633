import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { hide, show } from "redux-modal";
import { RoutsGameE } from "../../../../../utils/gameRoutsName";
import { useGameProf } from "../../../../../utils/hooks/gameHooks/useGameProf";
import useMe from "../../../../../utils/hooks/useMe";
import {
  GAME_BATTLE_MODE_MODAL,
  GAME_CONNECT_MODAL,
  GAME_SELECTED_FREE_MODAL,
  GAME_SELECTED_MODE_MODAL,
} from "../../../../../utils/modalGameNames";
import { soulFromWei } from "../../../../../utils/soulFromWei";
import { Toasts } from "../../../../../utils/toasts";
import { GameType } from "../../../../GameFolder/GameSelectGods/useGameSelectGodsControl";

export interface controlBattleModeModalI {
  selectedMode: SelectedModeE;

  setSelectedMode(mode: SelectedModeE): void;
  openNextStep(): void;
}

export enum SelectedModeE {
  SOUL,
  OTHER,
}

export const useControlBattleModeModal = (mode: string): controlBattleModeModalI => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const me = useMe();
  const gameProf = useGameProf();
  const [selectedMode, setSelectedMode] = useState<SelectedModeE>(SelectedModeE.SOUL);

  const openNextStep = () => {
    if (!me || !me?.Address) {
      dispatch(hide(GAME_BATTLE_MODE_MODAL));
      dispatch(show(GAME_CONNECT_MODAL));
      return;
    }
    if (selectedMode === SelectedModeE.SOUL)
      return dispatch(show(GAME_SELECTED_MODE_MODAL, { mode }));
    if (mode === "PVE" && selectedMode === SelectedModeE.OTHER)
      return dispatch(show(GAME_SELECTED_FREE_MODAL));

    if (+soulFromWei(gameProf.soulBalance) < 10)
      return Toasts.error("You haven't 10 SOUL for Service Fee");
    navigate(RoutsGameE.Select_gods, { state: { mode, gameType: GameType.NFT } });
  };

  return {
    selectedMode,

    setSelectedMode,
    openNextStep,
  };
};
