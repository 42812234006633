import * as auth from "./auth";
import * as me from "./me";
import * as temples from "./temples";
import * as gods from "./gods";
import * as packs from "./packs";
import * as support from "./support";
import * as edit from "./edit";
import * as sale from "./sale";
import * as prices from "./prices";
import * as ws from "./ws";

const obj = {
  auth,
  me,
  temples,
  gods,
  packs,
  support,
  edit,
  sale,
  prices,
  ws,
};

export default obj;
