import WalletType from "../../utils/walletType";

export enum ActionTypes {
  SET_WALLET = "AUTH_SET_WALLET",
  SET_TOKEN = "AUTH_SET_TOKEN",
  CLEAN_TOKEN = "AUTH_CLEAN_TOKEN",
  SET_CONNECTING = "AUTH_SET_CONNECTING",
  SET_ERROR = "AUTH_SET_ERROR",
  INIT = "AUTH_INIT",
}

export interface ActionBase {
  type: ActionTypes;
  payload?: any;
}

export interface ActionSetWallet extends ActionBase {
  payload: {
    account?: string;
    type?: WalletType;
  };
}

export interface ActionSetToken extends ActionBase {
  payload: {
    token: string;
    expires: number;
  };
}

export interface ActionSetConnecting extends ActionBase {
  payload: {
    type?: WalletType;
  };
}

export interface ActionSetError extends ActionBase {
  payload: {
    err?: Error;
  };
}
