export let BASE_URL: string | undefined;
export let DGCL_URL: string | undefined;
export let DOMAIN_URL: string | undefined;
export let AUTHORIZATION: string | undefined;
export const SYSTEM_WALLET: string = process.env.REACT_APP_SYSTEM_WALLET ?? "";
export const MYTH_WALLET_PROXY: string = process.env.REACT_APP_MYTH_WALLET_PROXY ?? "";
export const CONTRACT_MYTH: string = process.env.REACT_APP_CONTRACT_MYTH ?? "";
export const IS_PROD: boolean = process.env.REACT_APP_PRODUCTION_ENV === "1";

export const VALID_CHAIN: string = process.env.REACT_APP_VALID_CHAIN ?? "";

export const WALLET_FOR_BUY_SOUL: string = process.env.REACT_APP_WALLET_FOR_BUY_SOUL ?? "";

export const BASE_DOMAIN: string = process.env.REACT_APP_BASE_DOMAIN ?? "";

export const DGCL_DOMAIN = !IS_PROD ? "https://test.digicol.io" : "https://digicol.io";

type configReCAPCHTATypes = {
  REACT_RECAPTCHA?: string;
  REACT_TRANSFER_KEY?: string;
};

export const configReCAPCHTA: configReCAPCHTATypes = {
  REACT_RECAPTCHA: process.env.REACT_APP_RECAPTCHA_KEY,
  REACT_TRANSFER_KEY: process.env.REACT_APP_TRANSFER_KEY,
};

// eslint-disable-next-line prefer-const
AUTHORIZATION = process.env.REACT_APP_AUTHORIZATION;

if (typeof window !== "undefined") {
  BASE_URL = window.location.hostname === "localhost" ? process.env.REACT_APP_BASE_URL : "/api";
  DGCL_URL = process.env.REACT_APP_DGCL_URL;
  DOMAIN_URL = window.location.hostname === "localhost" ? process.env.REACT_APP_BASE_DOMAIN : "/";
}

export const TOASTS_CONFIG = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
