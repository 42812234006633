import { PricesInfo } from "../../services/api/prices";
import {
  ActionBase,
  ActionSuccess,
  ActionTypes,
  ActionError,
} from "./actionTypes";

export interface PricesState {
  Loading: boolean;
  ErrorMessage?: string;
  Info?: PricesInfo[];
}

const initialState: PricesState = {
  Loading: false,
};

const pricesReducer = (
  state: PricesState = initialState,
  action: ActionBase
): PricesState => {
  const { type, payload = null } = action;
  switch (type) {
    case ActionTypes.PRICES_START_LOADING:
      return {
        ...state,
        Loading: true,
      };
    case ActionTypes.PRICES_LOADING_ERROR: {
      const act = action as ActionError;
      return {
        ...state,
        ErrorMessage: act.Message,
      };
    }
    case ActionTypes.PRICES_LOADING_SUCCESS: {
      const act = action as ActionSuccess;
      return {
        ...state,
        Info: act.Info,
      };
    }
    default:
      return state;
  }
};

export default pricesReducer;
