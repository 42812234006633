import cn from "classnames";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { hide, show } from "redux-modal";
import { deleteRoom } from "../../../../../services/api/game/deleteRoom";
import { isServerError } from "../../../../../services/api/setting";
import { updateGameBalance } from "../../../../../store/me/actions";
import { GameToasts } from "../../../../../utils/gameToasts";
import { GAME_CANCEL_BATTLE_MODAL, GAME_OOPS_MODAL } from "../../../../../utils/modalGameNames";
import uniqString from "../../../../../utils/uniqString";
import Uniqueness from "../../../../../utils/uniqueness";
import WithGameModal, { ModalGameProps } from "../../../../HOC/GameHOC/withGameModal";
import GameBtn from "../../../GameFolder/GameBtn/GameBtn";
import { BtnType } from "../../../GameFolder/GameBtn/GameBtn.props";
import GameDataString from "../../../GameFolder/GameDataString/GameDataString";
import GameLoader from "../../../GameFolder/GameLoader/GameLoader";
import { ModalHeader } from "../GameModal";
import styles from "./CancelBattleModal.module.scss";

interface CancelBattlePropsI extends ModalGameProps {
  bet: string;
  roomId: string;
  nft: {
    name: string;
    uniq: Uniqueness;
    level: number;
  };
  getRooms(): void;
}

function CancelBattleModal({ bet, roomId, nft, getRooms, ...props }: CancelBattlePropsI) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  if (!nft) return null;

  const onCancel = async () => {
    setLoading(true);
    const delRes = await deleteRoom(roomId);
    if (!isServerError(delRes)) {
      setLoading(false);
      GameToasts.Success("You successfully deleted the room.");
      props.modalClose();
      dispatch(updateGameBalance());
      return getRooms();
    }
    setLoading(false);
    dispatch(hide(GAME_CANCEL_BATTLE_MODAL));
    dispatch(show(GAME_OOPS_MODAL, { subtitle: delRes.message }));
  };

  return (
    <>
      {loading && <GameLoader isAbsolute />}
      <ModalHeader title='PVP | Cancel Battle ?' subtitle={`Battle ID: ${roomId}`} />

      <div className={styles.content}>
        <GameDataString title='Character' name={nft.name} price={bet} />
        <div className={styles.descr}>
          <GameDataString title='Type' name={"PVP"} />
          <GameDataString title='Rare' name={uniqString(nft.uniq)} />
          <GameDataString title='Level' name={`Level ${nft.level}`} />
        </div>
      </div>
      <div className={styles.bottom}>
        <span>
          Open Room Time: <br /> 25-02-2022 11:30
        </span>
        <GameBtn type={BtnType.LIGHT} onClick={onCancel}>
          Confirm
        </GameBtn>
      </div>
    </>
  );
}

export default WithGameModal(GAME_CANCEL_BATTLE_MODAL, CancelBattleModal);
