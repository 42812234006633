import ApiSetting, { APIConfig, APIError } from "./setting";
import { AUTHORIZATION, BASE_URL, DGCL_URL } from "../../config";

class ApiService {
  private _api: ApiSetting;

  constructor(isDGCL = false) {
    const onError = (error: APIError) => {
      //console.error("API SERVICE ERROR:", error.message)
    };

    const config: APIConfig = {
      baseURL: (isDGCL ? DGCL_URL : BASE_URL) || "",
      onError: onError,
      Authorization: AUTHORIZATION,
    };

    this._api = new ApiSetting(config);
  }

  get api(): ApiSetting {
    return this._api;
  }
}

const service = new ApiService(false);
export const dgclService = new ApiService(true);

export default service;
