import styles from "./GameHome.module.scss";
import cn from "classnames";
import GameBtn from "../../global/GameFolder/GameBtn/GameBtn";
import { BtnType } from "../../global/GameFolder/GameBtn/GameBtn.props";
import WithGameLayout from "../../HOC/GameHOC/withLoyout";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import { GAME_CONNECT_MODAL, GAME_HOW_PLAY_MODAL } from "../../../utils/modalGameNames";
import { useContext, useEffect } from "react";
import { AppContext, SoundTypeE } from "../../HOC/GameHOC/AppContextProvider";

import { ReactComponent as HomeLogo } from "../../../assets/Icons/game/homeLogo.svg";
import home from "../../../assets/video/home.mp4";

function GameHome() {
  const dispatch = useDispatch();
  const { playSound } = useContext(AppContext);

  useEffect(() => {
    playSound(SoundTypeE.NO_WALLET);
  }, []);

  return (
    <>
      <div className={styles.banner}>
        <p>Myth NFT Gallery | Own the Legacy</p>
        <HomeLogo />
        <h1 className={styles.subtitle}> battle </h1>
        <GameBtn
          type={BtnType.DARK}
          className={cn(styles.btn, styles.play)}
          onClick={() => dispatch(show(GAME_CONNECT_MODAL))}
        >
          PLAY NOW
        </GameBtn>
        <GameBtn
          type={BtnType.DARK}
          className={cn(styles.btn, styles.how)}
          onClick={() => dispatch(show(GAME_HOW_PLAY_MODAL))}
        >
          HOW TO PLAY
        </GameBtn>
      </div>
    </>
  );
}
export default WithGameLayout(GameHome, { isVideo: true, src: home });
