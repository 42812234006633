import { createContext, ReactNode, useRef, useLayoutEffect, useState } from "react";
import styles from "./AppContextProvider.module.scss";

import hover_sound from "../../../assets/soulds/001_Select_Btn.mp3";
import close_sound from "../../../assets/soulds/002_Close_Btn.mp3";
import small_sound from "../../../assets/soulds/003_Small_Btn.mp3";
import large_sound from "../../../assets/soulds/004_Large_Btn.mp3";
import agility_sound from "../../../assets/soulds/005_Agility_Sound.mp3";
import result_sound from "../../../assets/soulds/006_Result_Sound.mp3";

import noWalletBg_sound from "../../../assets/soulds/bg/001_NoWallet_BGM.mp3";
import generalBg_sound from "../../../assets/soulds/bg/002_General_BGM.mp3";
import vsBgSound_sound from "../../../assets/soulds/bg/003_VS_BGM.mp3";
import GameLoaderComponent from "../../global/GameFolder/GameLoaderComponent/GameLoaderComponent";

export enum SoundTypeE {
  HOVER,
  CLOSE,
  SMALL,
  LARGE,
  AGILITY,
  RESULT,

  NO_WALLET,
  GENERAL,
  VS,
}

export interface AppContextI {
  readonly isLoading: boolean;

  playSound(s: SoundTypeE): void;
  setIsLoading(b: boolean): void;
}
export const AppContext = createContext<AppContextI>({
  playSound: () => null,
  isLoading: false,
  setIsLoading: () => null,
});

export default function AppContextProvider({ children }: { children: ReactNode }) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const selectSound = useRef<HTMLAudioElement>(null);
  const closeSound = useRef<HTMLAudioElement>(null);
  const smallSound = useRef<HTMLAudioElement>(null);
  const largeSound = useRef<HTMLAudioElement>(null);
  const agilitySound = useRef<HTMLAudioElement>(null);
  const resultSound = useRef<HTMLAudioElement>(null);

  const noWalletBgSound = useRef<HTMLAudioElement>(null);
  const generalBgSound = useRef<HTMLAudioElement>(null);
  const vsBgSound = useRef<HTMLAudioElement>(null);

  const playSound = (SoundType: SoundTypeE) => {
    if (
      selectSound.current !== null &&
      closeSound.current !== null &&
      smallSound.current !== null &&
      largeSound.current !== null &&
      agilitySound.current !== null &&
      resultSound.current !== null &&
      noWalletBgSound.current !== null &&
      generalBgSound.current !== null &&
      vsBgSound.current !== null
    ) {
      switch (SoundType) {
        case SoundTypeE.HOVER:
          selectSound.current.volume = 0.1;
          selectSound.current.currentTime = 0;
          selectSound.current.play();
          break;
        case SoundTypeE.CLOSE:
          closeSound.current.volume = 0.1;
          closeSound.current.play();
          break;
        case SoundTypeE.SMALL:
          smallSound.current.volume = 0.1;
          smallSound.current.pause();
          smallSound.current.currentTime = 0;
          smallSound.current.play();
          break;
        case SoundTypeE.LARGE:
          largeSound.current.volume = 0.1;
          largeSound.current.pause();
          largeSound.current.currentTime = 0;
          largeSound.current.play();
          break;
        case SoundTypeE.AGILITY:
          agilitySound.current.volume = 0.1;
          agilitySound.current.pause();
          agilitySound.current.currentTime = 0;
          agilitySound.current.play();
          break;
        case SoundTypeE.RESULT:
          resultSound.current.volume = 0.1;
          resultSound.current.play();
          break;

        case SoundTypeE.NO_WALLET:
          noWalletBgSound.current.volume = 0.1;
          noWalletBgSound.current.play();
          break;
        // case SoundTypeE.GENERAL:
        //   generalBgSound.current.volume = 0.1;
        //   generalBgSound.current.play();
        //   break;
        case SoundTypeE.VS:
          vsBgSound.current.volume = 0.1;
          vsBgSound.current.play();
          break;
      }
    } else {
      return console.error("undefined sounds");
    }
  };

  return (
    <AppContext.Provider value={{ playSound, isLoading, setIsLoading }}>
      {isLoading && <GameLoaderComponent />}
      <div className={styles.sounds}>
        <audio playsInline preload='auto' src={hover_sound} ref={selectSound} />
        <audio playsInline preload='auto' src={close_sound} ref={closeSound} />
        <audio playsInline preload='auto' src={small_sound} ref={smallSound} />
        <audio playsInline preload='auto' src={large_sound} ref={largeSound} />
        <audio playsInline preload='auto' src={agility_sound} ref={agilitySound} />
        <audio playsInline preload='auto' src={result_sound} ref={resultSound} />

        <audio playsInline preload='auto' src={noWalletBg_sound} ref={noWalletBgSound} loop />
        <audio playsInline preload='auto' src={generalBg_sound} ref={generalBgSound} loop />
        <audio playsInline preload='auto' src={vsBgSound_sound} ref={vsBgSound} loop />
      </div>
      {children}
    </AppContext.Provider>
  );
}
