import { PricesInfo } from "../../services/api/prices";

export enum ActionTypes {
  PRICES_START_LOADING = "PRICES_START_LOADING",
  PRICES_LOADING_SUCCESS = "PRICES_LOADING_SUCCESS",
  PRICES_LOADING_ERROR = "PRICES_LOADING_ERROR",
}

export interface ActionBase {
  type: ActionTypes;
  payload?: any;
}

export interface ActionStartLoading extends ActionBase {}

export interface ActionSuccess extends ActionBase {
  readonly Info: PricesInfo[];
}
export interface ActionError extends ActionBase {
  readonly Message: string;
}
