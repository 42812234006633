import { AxiosResponse } from "axios";
import service from "./service";
import { ServerResponse } from "./setting";

export enum UserStatus {
  NOT_ACTIVE = 0, //email not confirmed
  ACTIVE,
  REMOVED,
  BANNED,
}

export interface UserInfo {
  Status: UserStatus;
  Logo?: string;
  Background?: string;
  Approved?: boolean;
  UserName?: string;
  Desc?: string;
  Address?: string; //lowercase!
  Email?: string;
  Created?: number;
  CreatedStr?: string;
  Following: number;
  Followers: number;
  IFollow: boolean;
  FreeGames: number;
}

export interface UserInfoResponse extends ServerResponse {
  Info: UserInfo;
}

export function info(): Promise<AxiosResponse<UserInfoResponse> | Error> {
  return service.api.get("/user_info");
}
