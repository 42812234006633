import { useState } from "react";
import api from "../../../../services/api";
import { isServerError } from "../../../../services/api/setting";
import { cancelOrder } from "../../../../services/bch/order";
import useMe from "../../../../utils/hooks/useMe";
import mkError from "../../../../utils/mkError";
import { Toasts } from "../../../../utils/toasts";
import { ModalProps } from "../../../HOC/withModal";

export interface UnlistFromSaleModalI {
  readonly tokenID: string;
  readonly coll: string;
  readonly count: number;
  readonly image: string;
  onUnlisted(): void;
}

export interface UnlistFromSaleControl {
  readonly progress: boolean;
  submit(): Promise<void>;
}

export const unlistFromSale = async (
  tokenID: string,
  coll: string,
  meAddr: string
): Promise<void> => {
  const resp = await api.sale.getOrder(tokenID, coll, meAddr);
  if (isServerError(resp)) {
    throw resp;
  }
  await cancelOrder(resp.data.Order.Order);
  const resp2 = await api.sale.unlistFromSale(tokenID, coll);
  if (isServerError(resp2)) {
    throw resp2;
  }
};

function useUnlistFromSaleControl(
  info: ModalProps & UnlistFromSaleModalI
): UnlistFromSaleControl {
  const me = useMe();
  const [progress, setProgress] = useState(false);

  return {
    progress,
    async submit() {
      if (!me?.Address) return;
      setProgress(true);
      try {
        unlistFromSale(info.tokenID, info.coll, me.Address);
        info.onUnlisted();
      } catch (e) {
        const err = mkError(e);
        Toasts.Error(err.message);
      } finally {
        setProgress(false);
        info.modalClose();
      }
    },
  };
}

export default useUnlistFromSaleControl;
