export enum RoutsGameE {
  Home = "/game",
  Buy_soul = "",
  Buy_packs = "/game/Buy-Packs",
  Tutorial = "/game/Tutorial",
  Battle = "/game/Battle",
  Rankings = "/game/Rankings",
  Rooms = "/game/rooms",
  History = "/game/history",
  Select_gods = "/game/select-gods",
  Forecast = "/game/forecast",
  BattleRoom = "/game/battle-room/:room",
}
