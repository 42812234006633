import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { show } from "redux-modal";
import api from "../../../../services/api";
import { userRanking, UserRankingI } from "../../../../services/api/game/userRanking";
import { MyGameGod } from "../../../../services/api/gods";
import { isServerError } from "../../../../services/api/setting";
import { GameUserStateI } from "../../../../store/me/reducer";
import { RoutsGameE } from "../../../../utils/gameRoutsName";
import { useGameProf } from "../../../../utils/hooks/gameHooks/useGameProf";
import useMe, { Me } from "../../../../utils/hooks/useMe";
import { lastBattles } from "../../../../utils/lastResults";
import { GAME_EDIT_NAME_MODAL, GAME_TRANSFER_SOUL } from "../../../../utils/modalGameNames";
import { Toasts } from "../../../../utils/toasts";
import { DDOptionI } from "../../../global/GameFolder/GameDropdown/GameDropdown.props";
import { AppContext, SoundTypeE } from "../../../HOC/GameHOC/AppContextProvider";

interface HomeLoginControl {
  readonly me?: Me;
  readonly gameProf: GameUserStateI;
  readonly value_d1: DDOptionI;
  readonly value_d2: DDOptionI;
  readonly value_d3: DDOptionI;
  readonly gods: MyGameGod[];
  readonly total: number;
  readonly hasMore: boolean;
  readonly loading: boolean;
  readonly userRank?: UserRankingI;
  readonly lastRes: string[];

  setSearch(str: string): void;
  setValue_d1(option: DDOptionI): void;
  setValue_d2(option: DDOptionI): void;
  setValue_d3(option: DDOptionI): void;
  onEditName(): void;
  onNavigate(route: RoutsGameE): void;
  onTransferSoul(): void;
  getGods(): void;
}

export const useHomeLoginControl = ({
  dd1,
  dd2,
  dd3,
}: {
  [key: string]: DDOptionI[];
}): HomeLoginControl => {
  const gameProf = useGameProf();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { playSound } = useContext(AppContext);
  const me = useMe();
  const firstRender = useRef(false);
  const [search, setSearch] = useState<string>("");
  const [value_d1, setValue_d1] = useState<DDOptionI>(dd1[0]);
  const [value_d2, setValue_d2] = useState<DDOptionI>(dd2[0]);
  const [value_d3, setValue_d3] = useState<DDOptionI>(dd3[0]);
  const [gods, setGods] = useState<MyGameGod[] | []>([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [userRank, setUserRank] = useState<UserRankingI>();

  const clear = (r: any) => {
    Toasts.Error(r.message);
    console.error(r);
    setLoading(false);
    setGods([]);
    setTotal(0);
  };
  //берем богов
  const getGods = async () => {
    if (!me || !me.Address) return;
    const res = await api.gods.gameGods({
      type: value_d1.select,
      wallet: value_d3.select,
      level: value_d2.select,
      offset: gods.length,
    });
    if (!isServerError(res)) {
      setTotal(res.data.Total);
      setGods([...gods, ...res.data.Records]);
      setLoading(false);
      firstRender.current = true;
      return;
    }
    clear(res);
  };
  const getFilteredGods = async () => {
    setLoading(true);
    const res = await api.gods.gameGods({
      type: value_d1.select,
      wallet: value_d3.select,
      level: value_d2.select,
      search,
    });
    if (!isServerError(res)) {
      setTotal(res.data.Total);
      setGods(res.data.Records);
      setLoading(false);
      return;
    }
    clear(res);
  };

  useEffect(() => {
    if (firstRender.current) {
      setLoading(true);
      const t = setTimeout(() => getFilteredGods(), 1000);
      return () => clearTimeout(t);
    }
  }, [value_d1, value_d2, value_d3, search]);

  const getUserRanking = async () => {
    if (!me || !me.Address) return;
    const res = await userRanking();
    if (!isServerError(res)) {
      setUserRank(res.data.Ranking);
    }
  };

  useEffect(() => {
    getGods();
    getUserRanking();
  }, []);

  const hasMore = gods.length !== 0 ? gods.length < total : false;

  const onEditName = () => dispatch(show(GAME_EDIT_NAME_MODAL));
  const onNavigate = (route: RoutsGameE) => navigate(route);
  const onTransferSoul = () => dispatch(show(GAME_TRANSFER_SOUL));
  return {
    me,
    gameProf,
    value_d1,
    value_d2,
    value_d3,
    gods,
    total,
    hasMore,
    loading,
    userRank,
    lastRes: lastBattles(userRank?.LastResults),

    setSearch,
    setValue_d1,
    setValue_d2,
    setValue_d3,
    onEditName,
    onNavigate,
    onTransferSoul,
    getGods,
  };
};
