import styles from "./GameNotificationAll.module.scss";
import { GameNotificationAllPropsI } from "./GameNotificationAll.props";
import { ReactComponent as CloseIcon } from "../../../../../assets/Icons/game/closeIcon.svg";
import { gmtTime } from "../../../../../utils/gmtTime";
import { battleNotifRes } from "../../../../Layout/GameLayout/useHeaderGameControl";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import reduceAddress from "../../../../../utils/reduceAddress";

function GameNotificationAll({ notifications, onClose }: GameNotificationAllPropsI) {
  const navigate = useNavigate();

  const toBattle = (id: string) => {
    navigate(`/game/battle-room/${id}`, {
      state: {
        isShared: true,
      },
    });
    onClose();
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.header}>
          <h3> Notifications </h3>
          <CloseIcon className={styles.close} onClick={onClose} />
        </div>

        <div className={styles.notif_wrapper}>
          {notifications.map((item) => (
            <div className={styles.body} onClick={() => toBattle(item.RoomId)} key={item.RoomId}>
              <div className={styles.notif}>
                <p className={styles.time}> {gmtTime(item.Played)} </p>
                <p className={styles.name}>
                  Battle{" "}
                  <span
                    className={cn({
                      [styles.lose]: item.Result === "L",
                      [styles.win]: item.Result === "W",
                      [styles.draw]: item.Result === "D",
                    })}
                  >
                    {battleNotifRes(item.Result)}
                  </span>{" "}
                  VS {item.Rival.startsWith("0x") ? reduceAddress(item.Rival) : item.Rival} for{" "}
                  {item.StakeSoul === 0 ? `1 NFT` : `${item.StakeSoul} SOUL`}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default GameNotificationAll;
