import { DGCL_DOMAIN } from "../config";

export const openDgclLink = (collection: string, tokenId: string, owner: string) => {
  const btn = document.createElement("a");
  btn.setAttribute("target", "_blank");
  btn.setAttribute("rel", "noreferrer");
  btn.href = `${DGCL_DOMAIN}/#token/${collection}:${tokenId}/${owner}`;
  btn.style.opacity = "0";
  document.body.appendChild(btn);
  btn.click();
  document.body.removeChild(btn);
  // window.open(`${DGCL_DOMAIN}/#token/${collection}:${tokenId}/${owner}`, "_blank");
};
