import wallet from "../wallet";
import contractsAbi from "./abi";
import { AbiItem } from "web3-utils";
import contractAddress from "./addresses";

export const getExchangeContract = () =>
  wallet.createContract(
    contractsAbi.ExchangeV1 as AbiItem[],
    contractAddress.EXCHANGE_V1
  );
