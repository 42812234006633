import { GAME_BATTLE_MODE_MODAL } from "../../../../../utils/modalGameNames";
import WithGameModal, { ModalGameProps } from "../../../../HOC/GameHOC/withGameModal";
import GameBtn from "../../../GameFolder/GameBtn/GameBtn";
import { BtnType } from "../../../GameFolder/GameBtn/GameBtn.props";
import cn from "classnames";
import styles from "./BattleModeModal.module.scss";
import { SelectedModeE, useControlBattleModeModal } from "./useControlBattleModeModal";
import { ModalHeader } from "../GameModal";

export interface BattleModePropsI extends ModalGameProps {
  mode: "PVE" | "PVP";
}

const GameModeText = {
  PVE: {
    title: "PVE | Select Mode",
    vs: "Environment",
    btn: ["FREE", "Free Game Mode"],
    fee: "0 SOUL",
  },
  PVP: {
    title: "PVP | Select Mode",
    vs: "Player",
    btn: ["NFT", "Mode"],
    fee: "10 SOUL",
  },
};

function BattleModeModal({ mode, modalClose }: BattleModePropsI) {
  const control = useControlBattleModeModal(mode);
  if (!GameModeText[mode]) return null;
  return (
    <>
      <ModalHeader
        title={GameModeText[mode].title}
        subtitle={`Player vs ${GameModeText[mode].vs}`}
      />
      <div className={styles.wrapper}>
        <div className={styles.btn_wrapper}>
          <GameBtn
            className={cn({
              [styles.selected]: control.selectedMode === SelectedModeE.SOUL,
            })}
            onClick={() => control.setSelectedMode(SelectedModeE.SOUL)}
          >
            SOUL
            <p> SOUL Token Mode </p>
          </GameBtn>
          <p> Service Fee : 5% from the Battle Soul token amount </p>
        </div>
        <div className={styles.btn_wrapper}>
          <GameBtn
            className={cn({
              [styles.selected]: control.selectedMode === SelectedModeE.OTHER,
            })}
            onClick={() => control.setSelectedMode(SelectedModeE.OTHER)}
          >
            {GameModeText[mode].btn[0]}
            <p> {GameModeText[mode].btn[1]} </p>
          </GameBtn>
          <p> Service Fee : {GameModeText[mode].fee} </p>
        </div>
      </div>
      <GameBtn
        className={styles.next_btn}
        type={BtnType.ROOM}
        onClick={() => control.openNextStep()}
      >
        Next
      </GameBtn>
    </>
  );
}
export default WithGameModal(GAME_BATTLE_MODE_MODAL, BattleModeModal);
