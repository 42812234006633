const IMAGES_PATH = process.env.REACT_APP_PUBLISHED_URL;

function makeImagePath(p: string | undefined): string {
  if (typeof p === "undefined" || p === null) return "undefined";
  if (p.startsWith("http" || "https")) return p;
  p = p.startsWith("col_preview") ? p : "col_preview" + (p.startsWith("/") ? p : `/${p}`);
  return new URL(p, IMAGES_PATH).href;
}

export default makeImagePath;
