import cn from "classnames";
import styles from "./GameBgCard.module.scss";
import { GameBgCardPropsI } from "./GameBgCard.props";

export default function GameBgCard({
  children,
  battleMode,
  className,
  ...props
}: GameBgCardPropsI) {
  return (
    <div
      className={cn(styles.card, className, {
        [styles.battleMode]: battleMode,
      })}
      {...props}
    >
      {children}
    </div>
  );
}
