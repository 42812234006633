import cn from "classnames";
import GameCopy from "../GameCopy/GameCopy";
import styles from "./GameShareString.module.scss";
import { GameShareStringPropsI } from "./GameShareString.props";

export default function GameShareString({
  string,
  label,
  darkIcon,
  className,
  ...props
}: GameShareStringPropsI) {
  return (
    <div className={cn(styles.wrapper, className)} {...props}>
      <label>{label}</label>
      <div className={styles.share}>
        <p>{string}</p>
        <GameCopy string={string} dark={darkIcon} />
      </div>
    </div>
  );
}
