export const GAME_CONNECT_MODAL = "GAME_CONNECT_MODAL";
export const GAME_HOW_PLAY_MODAL = "GAME_HOW_PLAY_MODAL";
export const GAME_BATTLE_MODE_MODAL = "GAME_BATTLE_MODE_MODAL";
export const GAME_SELECTED_MODE_MODAL = "GAME_SELECTED_MODE_MODAL";
export const GAME_SELECTED_FREE_MODAL = "GAME_SELECTED_FREE_MODAL";
export const GAME_CONFIRM_MODAL = "GAME_CONFIRM_MODAL";
export const GAME_SHARE_BALLTE = "GAME_SHARE_BALLTE";
export const GAME_TRANSFER_SOUL = "GAME_TRANSFER_SOUL";
export const GAME_BUY_SOUL = "GAME_BUY_SOUL";
export const GAME_GOD_MODAL = "GAME_GOD_MODAL";
export const GAME_EDIT_NAME_MODAL = "GAME_EDIT_NAME_MODAL";
export const GAME_CANCEL_BATTLE_MODAL = "GAME_CANCEL_BATTLE_MODAL";
export const GAME_CHALLENGE_MODAL = "GAME_CHALLENGE_MODAL";
export const GAME_OOPS_MODAL = "GAME_OOPS_MODAL";
export const GAME_BATTLE_RESULT_MODAL = "GAME_BATTLE_RESULT_MODAL";
export const GAME_RESULT_MODAL = "GAME_RESULT_MODAL";
