import React, { DetailedHTMLProps, HTMLAttributes, ReactNode } from "react";

export enum BtnType {
  DARK = "DARK",
  LIGHT = "LIGHT",
  CONNECT = "CONNECT",
  ROOM = "ROOM",
}

export interface GameBtnPropsI
  extends DetailedHTMLProps<HTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  type?: BtnType;
  children: ReactNode;
  disabled?: boolean;
  bigBtn?: boolean;
  onClick?(e: React.MouseEvent): void;
}
