import wallet from "../wallet";
import contractsAbi from "./abi";
import { AbiItem } from "web3-utils";
import { getGasLimit, getGasPrice } from "./gas";
import { getExchangeContract } from "./exchange";

async function getTransferProxyAddress() {
  return await getExchangeContract()?.methods.transferProxy().call();
}

export async function setApprovalForErc(
  contractAddress: string,
  approval = true
) {
  //console.log("P1", contractAddress, approval);
  const proxyAddress = await getTransferProxyAddress();
  //console.log("P2", proxyAddress);
  const contract = wallet.createContract(
    contractsAbi.MultiToken as AbiItem[],
    contractAddress
  );
  //console.log("P3", contract);
  const isApproved = await contract?.methods
    .isApprovedForAll(wallet.account, proxyAddress)
    .call();
  //console.log("P4", isApproved);
  if (isApproved !== approval) {
    const approvalCall = contract?.methods.setApprovalForAll(
      proxyAddress,
      approval
    );
    //console.log("P5", approvalCall);
    const gas = await getGasLimit(approvalCall);
    //console.log("P6", gas);
    const gasPrice = await getGasPrice();
    //console.log("P7", gasPrice);
    return await approvalCall.send({ from: wallet.account, gas, gasPrice });
  }
}
