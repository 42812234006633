import { AxiosResponse } from "axios";
import { ServerResponse } from "../setting";
import service from "../service";

interface RatesI {
  Pair: string;
  Price: number; //сколько SOUL за 1 MYTH
}

export interface SoulExchangerate extends ServerResponse {
  Uid: string; // уникальная строка , ее нужно вернуть в запросе exchange и если курс, соответствующий этой строке просрочен - обмен не будет произведен
  Expire: number;
  Rates: RatesI[];
}

export function exchangerate(): Promise<AxiosResponse<SoulExchangerate> | Error> {
  return service.api.get("/gt/exchangerate");
}

interface ExchangeI extends ServerResponse {}
interface ExParamsI {
  SrcAmount: string; //сумма которую меняем (float64)
  Coin: string; // имя токена (или ETH для эфира) валюты, которую меняем
  TxHash: string; //строка хэша транзакции отправки исходных токенов/эфира
  SoulAmount: string; //сумма SOUL, которую получаем в результате обмена (float64) (Считается по курсу на фронте)
  RateUid: string; // уникальная метка курса обмена, по которому произведен расчет (Uid из /api/gt/exchangerate)
}

export function exchange(params: ExParamsI): Promise<AxiosResponse<ExchangeI> | Error> {
  return service.api.post("/gt/exchange", { ...params });
}

interface DepositResponse extends ServerResponse {}

interface DepositParamsI {
  Amount: string; // - отправленная сумма SOUL (float64)
  TxHash: string; // - строка хэша транзакции отправки исходных токенов/эфира
}

export function deposit(params: DepositParamsI): Promise<AxiosResponse<DepositResponse> | Error> {
  return service.api.post("/gt/deposit", { ...params });
}
