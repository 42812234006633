import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { show } from "redux-modal";
import { GameUserStateI } from "../../../../../store/me/reducer";
import { RoutsGameE } from "../../../../../utils/gameRoutsName";
import { useGameProf } from "../../../../../utils/hooks/gameHooks/useGameProf";
import { GAME_BUY_SOUL, GAME_TRANSFER_SOUL } from "../../../../../utils/modalGameNames";
import { soulFromWei } from "../../../../../utils/soulFromWei";
import { Toasts } from "../../../../../utils/toasts";
import { GameType } from "../../../../GameFolder/GameSelectGods/useGameSelectGodsControl";

interface SoulBattleModalControl {
  readonly addBalanceBtns: number[];
  readonly value: number;
  readonly gameProf: GameUserStateI;
  readonly haveSouls: boolean;

  setValue(value: number): void;
  setMaxValue(): void;
  openBuySoul(): void;
  openTransferSoul(): void;
  toPrepare(): void;
}

export const useControlSoulBattleModal = (mode: string): SoulBattleModalControl => {
  const gameProf = useGameProf();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const addBalanceBtns = [2, 5, 10];
  const [value, setValue] = useState<number>(0);

  const setMaxValue = () => setValue(+soulFromWei(gameProf.soulBalance));

  const toPrepare = () => {
    if (value < 1) return Toasts.Error("You can't bet less than 1 SOUL");
    navigate(RoutsGameE.Select_gods, {
      state: {
        mode,
        bet: value,
        gameType: GameType.SOUL,
      },
    });
  };

  return {
    addBalanceBtns,
    value,
    gameProf,
    haveSouls: +soulFromWei(gameProf.soulBalance) >= value,

    setMaxValue,
    setValue,
    openBuySoul: () => dispatch(show(GAME_BUY_SOUL)),
    openTransferSoul: () => dispatch(show(GAME_TRANSFER_SOUL)),

    toPrepare,
  };
};
