import { ActionSetBalance, ActionTypes } from "./actionTypes";
import BigNumber from "bignumber.js";
import wallet from "../../services/wallet";
import Erc20Service from "../../services/bch/erc20";
import api from "../../services/api";
import { isServerError } from "../../services/api/setting";
import { RootDispatch, RootState } from "../../store";
import { Dispatch } from "redux";
import { userBalance } from "../../services/api/game/userBalance";
import erc20 from "../../services/bch/erc20";

export const updateBalance = () => {
  return async (dispatch: RootDispatch) => {
    const ethBalance = await wallet.getWeiBalance();
    const usdtBalance = new BigNumber(await Erc20Service.getCurrencyBalance("USDT")).toFixed();
    const wethBalance = new BigNumber(await Erc20Service.getCurrencyBalance("WETH")).toFixed();
    const mythBalance = new BigNumber(await Erc20Service.getCurrencyBalance("MYTH")).toFixed();
    const mySoulBalance = new BigNumber(await Erc20Service.getCurrencyBalance("SOUL")).toFixed();
    const resp = await userBalance();
    if (!isServerError(resp)) {
      const action: ActionSetBalance = {
        type: ActionTypes.UPDATE_BALANCE,
        payload: {
          ethBalance,
          usdtBalance,
          wethBalance,
          mythBalance,
          mySoulBalance,
          soulBalance: erc20.convertToWei(new BigNumber(resp.data.Balance), "SOUL"),
          blockedBalance: erc20.convertToWei(new BigNumber(resp.data.Blocked), "SOUL"),
        },
      };
      console.log("BALANCE:", action.payload);
      dispatch(action);
    }
  };
};

export const updateEthBalance = () => {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async (dispatch: Dispatch) => {
    const ethBalance = new BigNumber(await wallet.getWeiBalance());
    dispatch({
      type: ActionTypes.UPDATE_ETH_BALANCE,
      payload: ethBalance.toFixed(),
    });
  };
};

export const updateUsdtBalance = () => {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async (dispatch: Dispatch) => {
    const balance = new BigNumber(await Erc20Service.getCurrencyBalance("USDT"));
    dispatch({
      type: ActionTypes.UPDATE_USDT_BALANCE,
      payload: balance.toFixed(),
    });
  };
};

export const updateWethBalance = () => {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async (dispatch: Dispatch) => {
    const wethBalance = new BigNumber(await Erc20Service.getCurrencyBalance("WETH"));
    dispatch({
      type: ActionTypes.UPDATE_WETH_BALANCE,
      payload: wethBalance.toFixed(),
    });
  };
};

export const updateMythBalance = () => {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async (dispatch: Dispatch) => {
    const mythBalance = new BigNumber(await Erc20Service.getCurrencyBalance("MYTH"));
    dispatch({
      type: ActionTypes.UPDATE_MYTH_BALANCE,
      payload: mythBalance.toFixed(),
    });
  };
};

export const updateGameBalance = () => {
  return async (dispatch: RootDispatch) => {
    dispatch({
      type: ActionTypes.PREPARE_UPDATE_GAME_BALANCE,
    });
    dispatch(updateMythBalance());

    const mySoulBalance = new BigNumber(await Erc20Service.getCurrencyBalance("SOUL")).toFixed();

    const resp = await userBalance();
    if (!isServerError(resp)) {
      dispatch({
        type: ActionTypes.UPDATE_GAME_BALANCE,
        payload: {
          soulBalance: erc20.convertToWei(new BigNumber(resp.data.Balance), "SOUL"),
          blockedBalance: erc20.convertToWei(new BigNumber(resp.data.Blocked), "SOUL"),
          mySoulBalance,
        },
      });
    }
  };
};

export const getUser = () => {
  return async (dispatch: RootDispatch, getState: () => RootState): Promise<Error | undefined> => {
    dispatch({ type: ActionTypes.GET_USER });
    const resp = await api.me.info();
    if (isServerError(resp)) {
      if (!getState().auth.startupConnect)
        dispatch({ type: ActionTypes.GET_USER_ERROR, payload: resp });
      return resp;
    }
    await dispatch({
      type: ActionTypes.GET_USER_SUCCESS,
      payload: resp.data.Info,
    });
    dispatch(updateBalance());
  };
};

// TODO:
export const updateUserImages = (url: any, data: any) => {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (dispatch: Dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_USER });
    /*Http.put(url, data)
      .then((res) => {
        dispatch({
          type: UPDATE_USER_SUCCESS,
          payload: res.data.Info,
        });
      })
      .catch((err) => {
        if (err.message) {
          toast.error(err.message);
        }
        dispatch({
          type: UPDATE_USER_ERROR,
          payload: err,
        });
      });*/
  };
};

// TODO:
export const updateUserData = () => {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch({ type: ActionTypes.UPDATE_USER });
    const resp = await api.me.info();
    if (isServerError(resp)) {
      if (!getState().auth.startupConnect)
        dispatch({ type: ActionTypes.GET_USER_ERROR, payload: resp });
      return resp;
    }
    await dispatch({
      type: ActionTypes.UPDATE_USER_SUCCESS,
      payload: resp.data.Info,
    });
  };
};
