import cn from "classnames";
import { GAME_EDIT_NAME_MODAL } from "../../../../../utils/modalGameNames";
import WithGameModal from "../../../../HOC/GameHOC/withGameModal";
import styles from "./EditNameModal.module.scss";
import GameBtn from "../../../GameFolder/GameBtn/GameBtn";
import { BtnType } from "../../../GameFolder/GameBtn/GameBtn.props";
import { ModalHeader } from "../GameModal";
import { useEditNameControl } from "./useEditNameControl";
import GameShareString from "../../../GameFolder/GameShareString/GameShareString";
import GameLoader from "../../../GameFolder/GameLoader/GameLoader";

function EditNameModal() {
  const control = useEditNameControl();
  return (
    <>
      {control.loading && <GameLoader global />}

      <ModalHeader title='Edit Name' subtitle='Letters, numbers, symbols, and emojis' />
      <div className={styles.wrapper}>
        <GameShareString string={control.me?.Address || ""} label='Wallet address' darkIcon />
        <div className={styles.edit_name}>
          <p>Enter your display name</p>
          <input
            placeholder='God temple team'
            value={control.name}
            onChange={(e) => control.setName(e.target.value)}
          />
        </div>
        <GameBtn
          type={BtnType.ROOM}
          onClick={control.onChangeName}
          className={cn({
            ["disabled"]: control.name === undefined || control.name.length < 3,
          })}
          disabled={control.name === undefined || control.name.length < 3}
        >
          Confirm
        </GameBtn>

        {(control.name === undefined || control.name.length < 3) && (
          <span className={styles.error}> Your display name cannot be less than 3 words</span>
        )}
      </div>
    </>
  );
}
export default WithGameModal(GAME_EDIT_NAME_MODAL, EditNameModal);
