import WithGameLayout from "../../../HOC/GameHOC/withLoyout";
import styles from "./GameHomeLogin.module.scss";
import GameBtn from "../../../global/GameFolder/GameBtn/GameBtn";
import { BtnType } from "../../../global/GameFolder/GameBtn/GameBtn.props";
import GameBgCard from "../../../global/GameFolder/GameBgCard/GameBgCard";
import Search from "../../../global/GameFolder/Search/Search";
import GameDropdown from "../../../global/GameFolder/GameDropdown/GameDropdown";
import GameGodCard from "../../../global/GameFolder/GameGodCard/GameGodCard";
import { useHomeLoginControl } from "./useHomeLoginControl";
import { RoutsGameE } from "../../../../utils/gameRoutsName";
import home_bg_login from "../../../../assets/img/game/home_login.png";
import { ReactComponent as Edit } from "../../../../assets/Icons/game/editIcon.svg";
import GameCopy from "../../../global/GameFolder/GameCopy/GameCopy";
import InfiniteScroll from "react-infinite-scroll-component";
import SkeletonGameCard from "../../../global/Skeleton/SkeletonGame/SkeletonGameCard/SkeletonGameCard";
import SkeletonUserRanking from "../../../global/Skeleton/SkeletonGame/SkeletonUserRanking/SkeletonUserRanking";
import { LevelFE, StorageTypeE, TypeFE, WalletFE } from "../../../../services/api/gods";
import { soulFromWei } from "../../../../utils/soulFromWei";
import reduceAddress from "../../../../utils/reduceAddress";

const dd1 = [
  {
    name: "All type",
    select: TypeFE.ALL,
  },
  { name: "Basic", select: TypeFE.BASIC },
  { name: "Rare", select: TypeFE.RARE },
  { name: "Mythtical", select: TypeFE.MYTH },
];
const dd2 = [
  { name: "All levels", select: LevelFE.ALL },
  { name: "Level 1", select: LevelFE.L1 },
  { name: "Level 2", select: LevelFE.L2 },
  { name: "Level 3", select: LevelFE.L3 },
];

const dd3 = [
  { name: "All Wallets", select: WalletFE.ALL },
  { name: "System Wallet", select: WalletFE.SYS },
  { name: "Web3 Wallet", select: WalletFE.WEB3 },
];

function HomeLogin() {
  const control = useHomeLoginControl({ dd1, dd2, dd3 });

  return (
    <>
      <div className={styles.wrapper}>
        {!control.userRank ? (
          <SkeletonUserRanking />
        ) : (
          <section className={styles.status_section}>
            <h1>My Page</h1>
            <div className={styles.info_wrapper}>
              <GameBgCard className={styles.ranking_wrapper}>
                <h4>Total Rankings </h4>
                <p> {control.userRank?.Total || "-"} </p>
                <div className={styles.ranking__bottom_info}>
                  <div className={styles.ranking__bottom_soul}>
                    <h5>Soul Rankings</h5>
                    <p>{control.userRank?.Soul || "-"}</p>
                  </div>
                  <span className={styles.separator} />
                  <div className={styles.ranking__bottom_nft}>
                    <h5>NFT Rankings</h5>
                    <p>{control.userRank?.Nft || "-"}</p>
                  </div>
                </div>
              </GameBgCard>
              <div className={styles.status_wrapper}>
                <GameBgCard className={styles.status__player}>
                  <div className={styles.player__left}>
                    <div className={styles.player__left__top}>
                      <div className={styles.player__name}>
                        <Edit className={styles.edit_icon} onClick={control.onEditName} />
                        <h4> {control.gameProf.GameName || "Edit Name"} </h4>
                      </div>
                      <div className={styles.player__address}>
                        <h4>{reduceAddress(control.me?.Address)}</h4>
                        <GameCopy string={control.me?.Address || ""} />
                      </div>
                    </div>
                    <div className={styles.player__left__bottom}>
                      <p>Battles : {control.userRank.Battles} </p>
                      <div className={styles.total_battles}>
                        Win {control.userRank.Win}
                        <span className={styles.separator} />
                        Draw {control.userRank.Draw}
                        <span className={styles.separator} />
                        Lose {control.userRank.Lose}
                      </div>
                    </div>
                  </div>
                  <div className={styles.player__right}>
                    <div className={styles.player__wallets}>
                      <p>In System Wallet : {soulFromWei(control.gameProf.soulBalance)} SOUL</p>
                      <p>In Web3 Wallet : {soulFromWei(control.gameProf.mySoulBalance)} SOUL</p>
                    </div>
                    <div className={styles.player__history}>
                      <p className={styles.history_title}> Last 10 Battles </p>
                      {control.lastRes.map((last, i) => (
                        <span key={i}>{last}</span>
                      ))}
                    </div>
                  </div>
                </GameBgCard>
                <div className={styles.btn_wrapper}>
                  <GameBtn
                    type={BtnType.DARK}
                    bigBtn
                    onClick={() => control.onNavigate(RoutsGameE.Battle)}
                  >
                    BATTLE
                  </GameBtn>
                  <GameBtn
                    type={BtnType.DARK}
                    bigBtn
                    onClick={() => control.onNavigate(RoutsGameE.Rankings)}
                  >
                    RANKINGS
                  </GameBtn>
                  <GameBtn
                    type={BtnType.DARK}
                    bigBtn
                    onClick={() => control.onNavigate(RoutsGameE.History)}
                  >
                    History
                  </GameBtn>
                  <GameBtn type={BtnType.DARK} bigBtn onClick={control.onTransferSoul}>
                    TRANSFER SOUL
                  </GameBtn>
                </div>
              </div>
            </div>
          </section>
        )}

        <section className={styles.gods_tokens_section}>
          <GameBgCard className={styles.gods_tokens_wrapper}>
            <div className={styles.search_wrapper}>
              <div className={styles.search}>
                <Search onChange={(e: any) => control.setSearch(e.target.value)} />
                <GameDropdown
                  value={control.value_d1}
                  getValue={control.setValue_d1}
                  className={styles.dropd}
                  options={dd1}
                />
                <GameDropdown
                  value={control.value_d2}
                  getValue={control.setValue_d2}
                  className={styles.dropd}
                  options={dd2}
                />
                <GameDropdown
                  value={control.value_d3}
                  getValue={control.setValue_d3}
                  className={styles.dropd}
                  options={dd3}
                />
              </div>
              <p> Total NFTs : {control.total}</p>
            </div>

            <InfiniteScroll
              dataLength={control.gods.length}
              next={control.getGods}
              hasMore={control.hasMore}
              loader={<SkeletonGameCard />}
              className={styles.gods_tokens}
            >
              {control.loading ? (
                <SkeletonGameCard />
              ) : control.gods.length === 0 ? (
                "No available gods for game"
              ) : (
                control.gods.map((item, i) => (
                  <GameGodCard
                    className={styles.god_card}
                    key={i}
                    canNotFgt={item.StorageType !== StorageTypeE.GOD_STORAGE_SYSTEM}
                    {...item}
                  />
                ))
              )}
            </InfiniteScroll>
          </GameBgCard>
        </section>
      </div>
    </>
  );
}

export default WithGameLayout(HomeLogin, { src: home_bg_login });
