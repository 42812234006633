import cn from "classnames";
import styles from "./Skeleton.module.scss";
import { SkeletonPropsI } from "./Skeleton.props";

export default function Skeleton({
  type = "box",
  className,
  godToken = false,
  ...props
}: SkeletonPropsI): JSX.Element {
  return (
    <div
      className={cn(styles.skeleton, className, {
        [styles.box]: type === "box",
        [styles.circle]: type === "circle",
        [styles.text]: type === "text",
        [styles.godToken]: godToken, // добавляем для токенов ( у них размер фиксированный 200 на 225)
      })}
      {...props}
    />
  );
}
