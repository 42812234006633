import { GAME_SELECTED_MODE_MODAL } from "../../../../../utils/modalGameNames";
import WithGameModal from "../../../../HOC/GameHOC/withGameModal";
import cn from "classnames";
import styles from "./SoulBattleModal.module.scss";
import { BattleModePropsI } from "../BattleModeModal/BattleModeModal";
import GameBtn from "../../../GameFolder/GameBtn/GameBtn";
import { BtnType } from "../../../GameFolder/GameBtn/GameBtn.props";
import { useControlSoulBattleModal } from "./useControlSoulBattleModal";
import { ModalHeader } from "../GameModal";
import { soulFromWei } from "../../../../../utils/soulFromWei";
import useMediaQuery from "../../../../../utils/hooks/useMediaQuery";

const GameModeText = {
  PVE: {
    title: "PVE | Select SOUL",
  },
  PVP: {
    title: "PVP | Select SOUL",
  },
};

function SoulBattleModal({ mode }: BattleModePropsI) {
  const mob425 = useMediaQuery("max", 425);
  const control = useControlSoulBattleModal(mode);
  if (!GameModeText[mode]) return null;
  return (
    <>
      <ModalHeader
        title={GameModeText[mode].title}
        subtitle='You can choose or enter SOUL amount'
      />
      <div className={styles.wrapper}>
        <div className={styles.btns_wrapper}>
          {control.addBalanceBtns.map((item, i) => (
            <GameBtn
              key={i}
              type={BtnType.LIGHT}
              onClick={() => control.setValue(item)}
              className={cn({
                [styles.selected]: item === control.value,
              })}
            >
              {item} SOUL
            </GameBtn>
          ))}
        </div>
        <div className={styles.soul_form}>
          <p>OR Enter Your SOUL Amount</p>
          <div className={styles.input_soul}>
            <input
              type='number'
              onChange={(e) => control.setValue(+e.target.value)}
              value={control.value === 0 ? "" : control.value}
            />
            <span onClick={control.setMaxValue}> MAX </span>
          </div>
          <p className={styles.soul_wallet}>
            System Wallet : {soulFromWei(control.gameProf.soulBalance)} SOUL{" "}
            {mob425 ? <br /> : " | "}
            Web3 Wallet :{soulFromWei(control.gameProf.mySoulBalance)} SOUL
          </p>
        </div>
        <div className={styles.bottom_btns}>
          <div className={styles.get_soul}>
            <button onClick={control.openBuySoul}> Buy SOUL Token Now </button>
            <button onClick={control.openTransferSoul}> Transfer SOUL</button>
          </div>
          <GameBtn
            type={BtnType.DARK}
            className={cn(styles.select_god_btn, {
              ["disabled"]: !control.haveSouls,
            })}
            disabled={!control.haveSouls}
            onClick={control.toPrepare}
          >
            Select god
            {!control.haveSouls && <span> Not Enough SOUL </span>}
          </GameBtn>
        </div>
      </div>
    </>
  );
}
export default WithGameModal(GAME_SELECTED_MODE_MODAL, SoulBattleModal, { battle: true });
