import { AxiosResponse } from "axios";

import Uniqueness from "../../utils/uniqueness";
import { MyGodInfo } from "./gods";
import service from "./service";
import { ServerResponse } from "./setting";

export interface TempleInfo {
  Address: string;
  Name: string;
  Image: string;
  ShortDesc: string;
  FullDesc: string;
}

export interface TemplesResponse extends ServerResponse {
  Total: number;
  Records: TempleInfo[];
}

export function temples(offset = 0, limit = 20): Promise<AxiosResponse<TemplesResponse> | Error> {
  return service.api.post("/gt/temples", { offset, limit });
}

export interface MainpageGodInfo {
  Collection: string;
  TokenID: string;
  Group: string;
  Image: string;
  Name: string;
  Type: string;
  ShortDesc: string;
}
export interface MainpageTempleInfo {
  Address: string;
  Name: string;
  Image: string;
  ShortDesc: string;
  FullDesc: string;
  ArtistName: string;
  ArtistImg: string;
  Gods: MainpageGodInfo[];
}

export interface MainpageResponse extends ServerResponse {
  Total: number;
  Records: MainpageTempleInfo[];
}

export function mainpage(offset = 0, limit = 10): Promise<AxiosResponse<MainpageResponse> | Error> {
  return service.api.post("/gt/temples/mainpage", { offset, limit });
}

export interface PackInfo {
  ID: number;
  Image: string;
  ShortDesc: string;
  Price: number;
  PriceCoin: string;
  Uniqueness: Uniqueness;
  AvailableCount?: number;
}

export interface PacksResponse extends ServerResponse {
  Total: number;
  Records: PackInfo[];
}

export function packs(
  temple: string,
  offset: number,
  limit: number
): Promise<AxiosResponse<PacksResponse> | Error> {
  return service.api.post("/gt/temple/packs", { temple, offset, limit });
}

export interface TemplePageTempleInfo {
  Name: string;
  BgImage: string;
  PacksBgImage: string;
  ShortDesc: string;
  FullDesc: string;
}

export interface TemplePageGodInfo extends MyGodInfo {
  Name: string;
  Image: string;
  TokenID: string;
  ShortDesc: string;
  Type: string;
  Group: string;
  Uniqueness: Uniqueness;
  Level: 1 | 2 | 3;
}

export interface TemplePageResponse extends ServerResponse {
  Total: number;
  Info: TemplePageTempleInfo;
  Records: TemplePageGodInfo[];
}

export function temple(
  temple: string,
  offset: number,
  limit: number
): Promise<AxiosResponse<TemplePageResponse> | Error> {
  return service.api.post("/gt/temple", { temple, offset, limit });
}
