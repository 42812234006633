import { AxiosResponse } from "axios";
import service from "./service";
import { ServerResponse } from "./setting";

export interface QueueItem {
  Message: string;
  Created: number;
}

export interface QueueResponse extends ServerResponse {
  total: number;
  records: QueueItem[];
}

export function queue(): Promise<AxiosResponse<QueueResponse> | Error> {
  return service.api.post("/socket_queue");
}

export function xsQueue(): Promise<AxiosResponse<QueueResponse> | Error> {
  return service.api.post("/xs/socket_queue");
}
