import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { Toasts } from "../../utils/toasts";

function useErrorMessage(): void {
  const authError = useSelector((state: RootState) => state.auth.err);
  const meError = useSelector((state: RootState) => state.me.error);

  useEffect(() => {
    const err = authError?.message || meError?.message;
    if (err) {
      Toasts.Error(err);
    }
  }, [authError, meError]);
}

export default useErrorMessage;
