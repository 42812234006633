export enum RoutesE {
  Main = "/",
  About = "/about",
  Temples = "/temples",
  Temple = "/temple/:temple",
  Claims = "/claim",
  Pack = "/pack/:pack",
  God = "/group/:id",
  GodNote = "/groupx/:id/:note",
  User = "/user",
  Staking = "/Staking",
}
