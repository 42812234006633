import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import ws, {
  balanceWasChanged,
  GNotificationsI,
  isGamePlayed,
  isNotifsForGame,
  WSEvent,
} from "../services/ws";
//sound
import generalBg_sound from "../assets/soulds/bg/002_General_BGM.mp3";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { RoutsGameE } from "../utils/gameRoutsName";
import useMe from "../utils/hooks/useMe";
import { GameToasts } from "../utils/gameToasts";
import { updateGameBalance } from "../store/me/actions";
import { useAccount } from "../utils/hooks/useAccount";

const defValue: {
  events: GNotificationsI[];
  notifs: WSEvent[];
} = {
  events: [],
  notifs: [],
};

export const GTContext = createContext(defValue);

const GTContextProvider = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [notifs, setNotifs] = useState<WSEvent[]>([]);
  const token = useSelector((state: RootState) => state.auth.token?.token);
  const me = useMe();

  const onws2 = (ev: WSEvent) => {
    toastsWSListener(ev);
    setNotifs((item) =>
      check([...item, ev].sort((a, b) => b.Id - a.Id).slice(0, 1000))
    );
  };

  const check = (wse: WSEvent[]) => {
    const f1 = wse.filter((item, i, arr) =>
      arr[i + 1] ? item.Id !== arr[i + 1].Id : 1
    );
    const f2 = f1.filter((item) => !item.external);
    //просто через f2 фильтруем сообщения с дигикола (то есть вырезаем их)
    return f2;
  };

  const getGameNotifs = () => {
    const gameNotifs: GNotificationsI[] = [];
    notifs
      .filter((event) => isGamePlayed(event))
      .map((n: WSEvent) => gameNotifs.push(n.Event));
    return gameNotifs.sort((a, b) => b.Played - a.Played);
  };

  const toastsWSListener = (ev: WSEvent) => {
    if (balanceWasChanged(ev)) {
      console.log(ev);
      GameToasts.dismiss(); // вырубаем все уведомления (тосты)
      setTimeout(() => {
        GameToasts.Success(`You successfully got a ${ev.Event.Amount} SOUL`);
        dispatch(updateGameBalance());
      }, 500);
      return;
    }
  };

  useEffect(() => {
    if (!token) return;
    ws.init(token, onws2);
    return () => ws.removeListener(onws2);
  }, [token, me?.Address]);

  useEffect(() => {
    ws.setNeedReconnect(!!token);
    return () => ws.removeListener(onws2);
  }, [token, me?.Address]);
  //gameSound
  const generalBgSound = useRef<HTMLAudioElement>(null);
  const [played, setPlayed] = useState<boolean>(false);

  useEffect(() => {
    if (generalBgSound.current === null) return;
    if (
      location.pathname === RoutsGameE.Battle ||
      location.pathname === RoutsGameE.Rankings ||
      location.pathname === RoutsGameE.Forecast ||
      location.pathname === RoutsGameE.Select_gods ||
      location.pathname === RoutsGameE.Rooms ||
      location.pathname === RoutsGameE.History ||
      location.search.includes("user")
    ) {
      generalBgSound.current.volume = 0.1;
      if (played) return;
      generalBgSound.current.play();
      setPlayed(true);
    } else {
      generalBgSound.current.pause();
      generalBgSound.current.currentTime = 0;
      setPlayed(false);
    }
  }, [location]);

  return (
    <GTContext.Provider
      value={{
        events: getGameNotifs(),
        notifs,
      }}
    >
      <div className={"audio_game"}>
        <audio
          playsInline
          preload="auto"
          src={generalBg_sound}
          ref={generalBgSound}
          loop
        />
      </div>
      {children}
    </GTContext.Provider>
  );
};

export default GTContextProvider;
