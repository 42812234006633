export const lastBattles = (lastRes: string | any[] | undefined): string[] => {
  if (lastRes === undefined) return [];
  let lastArr: any = [];
  if (typeof lastRes === "string") {
    lastArr = lastRes.split("");
  } else {
    lastArr = lastRes;
  }
  const emptyArr = Array.from(new Array(10 - lastArr.length));
  const arr: string[] = [];

  [...lastArr, ...emptyArr].forEach((item) => {
    if (item) {
      arr.push(item);
    } else {
      arr.push("-");
    }
  });
  return arr;
};
