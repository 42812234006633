import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { cleanToken, getUser, setConnecting, setError, setWallet } from "../../store/auth/actions";
import wallet, {
  createAccountChangeListener,
  createChainChangeListener,
  createDisconnectListener,
} from "../../services/wallet";
import { useCallback, useEffect } from "react";
import WalletType from "../../utils/walletType";
import isError from "../../utils/isError";
import unknownObjectText from "../../utils/unknownObjectText";

export const useAccount = (): string | undefined => {
  const auth = useSelector((state: RootState) => state.auth);
  if (!auth.wallet || !auth.token || (auth.connecting && !auth.startupConnect) || auth.err) return;
  return auth.wallet.account;
};

export interface Connector {
  connect(type?: WalletType): Promise<Error | undefined>;
  disconnect(): Promise<void>;
}

export const useConnector = (): Connector => {
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth);

  const connect = useCallback(
    async (type?: WalletType): Promise<Error | undefined> => {
      if (auth.connecting) return;
      if (
        auth.token &&
        typeof auth.token.expires === "number" &&
        new Date().getTime() > auth.token.expires
      ) {
        dispatch(cleanToken());
        return;
      }
      try {
        await dispatch(setConnecting(type || wallet.typ));
        if (type) {
          const resp = await wallet.connect(type);
          if (!resp) {
            dispatch(cleanToken());
            return;
          }
          if (isError(resp)) {
            dispatch(setError(resp));
            return resp;
          }
        }
        // console.log(wallet.account, wallet.typ);
        await dispatch(setWallet(wallet.account || undefined, wallet.typ));
        if (
          auth.token &&
          wallet.account === auth.token.wallet.account &&
          wallet.typ === auth.token.wallet.type
        ) {
          dispatch(getUser());
          return;
        }
        const resp = await wallet.auth();
        if (isError(resp)) {
          dispatch(setError(resp));
          return resp;
        }
        dispatch(getUser(resp));
      } catch (e) {
        const err = isError(e) ? e : Error(unknownObjectText(e));
        dispatch(setError(err));
        return err;
      }
    },
    [auth]
  );

  return {
    connect,
    disconnect() {
      dispatch(cleanToken());
      return wallet.disconnect();
    },
  };
};

const setAccountChangedCb = createAccountChangeListener();
const setChainChangedCb = createChainChangeListener();
const setDisconnectedCb = createDisconnectListener();

export const useWatchConnection = (onError: (err: Error) => void) => {
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth);
  const connector = useConnector();

  const changed = async () => {
    dispatch(setWallet());
    if (!wallet.account || !wallet.typ || !auth.token) {
      return;
    }
    const err = await connector.connect();
    if (err) {
      onError(err);
      return;
    }
  };

  setAccountChangedCb(async () => {
    // await changed();
    // dispatch(setWallet(wallet.account || undefined, wallet.typ));
    dispatch(cleanToken());
    wallet.disconnect();
  });

  setChainChangedCb(async () => {
    await changed();
    if (auth.wallet?.type === WalletType.COIN_BASE && auth.wallet && !wallet.account) {
      return;
    }
    dispatch(setWallet(wallet.account || undefined, wallet.typ));
  });

  setDisconnectedCb(() => {
    dispatch(setWallet());
  });

  useEffect(() => {
    (async () => {
      if (auth.startupConnect) {
        const t = wallet.typ ? undefined : auth.wallet?.type;
        const err = await connector.connect(t);
        if (err) {
          onError(err);
        }
      }
    })();
  }, [auth.wallet]);
};
