import { AxiosResponse } from "axios";
import { ServerResponse } from "../setting";
import service from "../service";

export interface ChangeGameNameResponseI extends ServerResponse {}

export function changeUserName(
  name: string
): Promise<AxiosResponse<ChangeGameNameResponseI> | Error> {
  return service.api.post("/gt/game/changeusername", {
    name,
  });
}
