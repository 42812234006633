export const PACK_MODAL = "PACK_MODAL";
export const CONNECT_MODAL = "CONNECT_MODAL";
export const UNLIST_FROM_SALE_MODAL = "UNLIST_FROM_SALE_MODAL";
export const LIST_FOR_SALE_MODAL = "LIST_FOR_SALE_MODAL";
export const UPGRADE_MODAL2 = "UPGRADE_MODAL2";
export const EDIT_PROFILE_MODAL = "EDIT_PROFILE_MODAL";
export const SUPPORT_MODAL = "SUPPORT_MODAL";
export const STAKING_MODAL = "STAKING_MODAL";
export const NOTIFS_MODAL = "NOTIFS_MODAL";
export const ANIMATION_MODAL = "ANIMATION_MODAL";
export const OPEN_PACK_MODAL = "OPEN_PACK_MODAL";
export const IMAGE_MODAL = "IMAGE_MODAL";
export const FOLLOW_STEPS_MODAL = "FOLLOW_STEPS_MODAL";
export const FOLLOW_STEPS_MODAL_2 = "FOLLOW_STEPS_MODAL_2";
export const TRANSFER_SOUL_MODAL = "TRANSFER_SOUL_MODAL";
export const WITHDROW_SOUL_MODAL = "WITHDROW_SOUL_MODAL";
export const COLLECT_STAKING_MODAL = "COLLECT_STAKING_MODAL";
export const CHANGE_NETWORK_MODAL = "CHANGE_NETWORK_MODAL";
export const BUY_PACK_MODAL = "BUY_PACK_MODAL";
