import cn from "classnames";
import { GAME_SHARE_BALLTE } from "../../../../../utils/modalGameNames";
import WithGameModal from "../../../../HOC/GameHOC/withGameModal";
import GameShareString from "../../../GameFolder/GameShareString/GameShareString";
import { ModalHeader } from "../GameModal";
import styles from "./GameShareModal.module.scss";
import { GameShareModalPropsI } from "./GameShareModal.props";

import { ReactComponent as Twitter } from "../../../../../assets/Icons/game/Twitter.svg";
import { ReactComponent as Telegramm } from "../../../../../assets/Icons/game/Telegram.svg";
import { ReactComponent as Instagram } from "../../../../../assets/Icons/game/IG.svg";
import GameBtn from "../../../GameFolder/GameBtn/GameBtn";
import { BtnType } from "../../../GameFolder/GameBtn/GameBtn.props";
import { BASE_DOMAIN } from "../../../../../config";
import {
  TwitterShareButton,
  InstapaperShareButton,
  TelegramShareButton,
} from "react-share";

function GameShareModal({
  homeName,
  awayName,
  roomId,
  shareBattle = false,
  ...props
}: GameShareModalPropsI) {
  const link = () =>
    shareBattle
      ? `${BASE_DOMAIN}/r/game/rooms?id=${roomId}`
      : `${BASE_DOMAIN}/r/game/battle-room/${roomId}`;

  return (
    <>
      <ModalHeader
        title={`${awayName ? "PVP" : "PVE"} |  Share Battle ${
          shareBattle ? "Room" : "Result"
        } `}
        subtitle="Copy the direct battle link below to share"
      />

      <div className={styles.wrapper}>
        <p>
          {homeName} VS {awayName ? awayName : "Enviroment"}
        </p>
        <p> Battle ID </p>
        <h4>{roomId}</h4>

        <GameShareString
          string={link()}
          label="Share Battle"
          darkIcon
          className={styles.shareString}
        />

        <div className={styles.bottom}>
          <div className={styles.icons}>
            <TwitterShareButton url={link()} title={"God temple game"}>
              <Twitter title="Twitter" />
            </TwitterShareButton>
            <TelegramShareButton url={link()} title={"God temple game"}>
              <Telegramm title="Telegramm" />
            </TelegramShareButton>
            <InstapaperShareButton url={link()} title={"God temple game"}>
              <Instagram title="Instagram" />
            </InstapaperShareButton>
          </div>
          <GameBtn type={BtnType.LIGHT} onClick={props.modalClose}>
            Close
          </GameBtn>
        </div>
      </div>
    </>
  );
}

export default WithGameModal(GAME_SHARE_BALLTE, GameShareModal);
