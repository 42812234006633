import GameBgCard from "../../../GameFolder/GameBgCard/GameBgCard";
import Skeleton from "../../Skeleton";
import styles from "./SkeletonUserRanking.module.scss";

export default function SkeletonUserRanking() {
  return (
    <div className={styles.wrapper}>
      <GameBgCard className={styles.status}>
        <div className={styles.top}>
          <Skeleton type='text' style={{ borderRadius: 0 }} />
          <Skeleton type='text' style={{ borderRadius: 0, width: "100%" }} />
        </div>
        <div className={styles.bottom}>
          <Skeleton type='text' style={{ width: "100%", borderRadius: 0 }} /> |{" "}
          <Skeleton type='text' style={{ width: "100%", borderRadius: 0 }} />
        </div>
      </GameBgCard>
      <div className={styles.right}>
        <GameBgCard className={styles.player_data}>
          <Skeleton type='text' style={{ borderRadius: 0 }} />
          <Skeleton type='text' style={{ borderRadius: 0 }} />
          <Skeleton type='text' style={{ borderRadius: 0 }} />
        </GameBgCard>
        <div className={styles.btns}>
          <Skeleton type='text' style={{ width: "100%" }} />
          <Skeleton type='text' style={{ width: "100%" }} />
          <Skeleton type='text' style={{ width: "100%" }} />
          <Skeleton type='text' style={{ width: "100%" }} />
        </div>
      </div>
    </div>
  );
}
