import { toast, ToastContent } from "react-toastify";

import { ReactComponent as Check } from "../assets/Icons/game/check.svg";
import { ReactComponent as Arrow } from "../assets/Icons/game/arrow_link.svg";
import getEtherscanTxUrl from "./getEtherscanTxURL";

export class GameToasts {
  public static Error(msg: string): ToastContent {
    return toast.error(msg);
  }
  public static Success(msg: string): ToastContent {
    return toast.success(msg, {
      progressClassName: "toast--success",
      icon: <Check style={{ height: "100%" }} />,
    });
  }

  public static TxSuccess(msg: string, txId: string): ToastContent {
    return toast.success(msg, {
      progressClassName: "toast--success",
      icon: <Arrow style={{ height: "100%" }} />,
      onClose: () => window.open(`${getEtherscanTxUrl(txId)}`, "_blank"),
    });
  }

  public static TxError(msg: string, txId: string): ToastContent {
    return toast.error(msg, {
      progressClassName: "toast--success",
      icon: <Arrow style={{ height: "100%" }} />,
      onClose: () => window.open(`${getEtherscanTxUrl(txId)}`, "_blank"),
    });
  }
  public static dismiss() {
    return toast.dismiss();
  }
}
