import { AxiosResponse } from "axios";
import buildFormData from "../../utils/buildFormData";
import service from "./service";
import { PossiblyError, ServerResponse } from "./setting";

export async function storeOrder(
  order: string,
  sign: string
): Promise<PossiblyError> {
  const frm = buildFormData({ Order: order, Sign: sign });
  return await service.api.post("/xs/buy_create_order", frm);
}

export async function exportToken(
  tokenID: string,
  coll: string
): Promise<PossiblyError> {
  return await service.api.post("/gt/gods/export", {
    tokenid: tokenID,
    collection: coll,
  });
}

export interface Order {
  Order: string;
}

export interface OrderResponse extends ServerResponse {
  Order: Order;
}

export async function getOrder(
  tokenID: string,
  collection: string,
  owner: string
): Promise<AxiosResponse<OrderResponse> | Error> {
  if (tokenID.split(":").length < 2) tokenID = `${collection}:${tokenID}`;
  return await service.api.get("/xs/buy_get_order", {
    params: { TokenId: tokenID, Owner: owner },
  });
}

export async function unlistFromSale(
  tokenID: string,
  collection: string
): Promise<PossiblyError> {
  if (tokenID.split(":").length < 2) tokenID = `${collection}:${tokenID}`;
  return await service.api.post("/xs/edit_field", {
    address: tokenID,
    col_forsale: false,
  });
}
