const reduceAddress = (id?: string): string => {
  if (id) {
    const reduced = `${id.slice(0, Math.min(id.length / 3, 6))}...`;
    return id.startsWith("0x") ? reduced.concat(id.slice(id.length - 5, id.length)) : id;
  } else {
    return "";
  }
};

export default reduceAddress;
