import "./AreYouSure.scss";

export interface AreYouSureProps {
  readonly title: string;
  done(ok: boolean): void;
}

const AreYouSure = (props: AreYouSureProps) => {
  return (
    <div className="areyousure">
      <div className="areyousure__contents">
        <div className="areyousure__title">{props.title}</div>
        <div className="areyousure__buttons">
          <button className="btn" onClick={() => props.done(true)}>
            Yes
          </button>
          <button className="btn btn--white" onClick={() => props.done(false)}>
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default AreYouSure;
