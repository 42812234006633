import React, { ReactNode, useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { hide } from "redux-modal";
import cn from "classnames";
import styles from "./GameModal.module.scss";

import { ReactComponent as CloseIcon } from "../../../../assets/Icons/game/closeIcon.svg";
import { AppContext, SoundTypeE } from "../../../HOC/GameHOC/AppContextProvider";

interface ModalProps {
  children: ReactNode;
  name: string;
  params?: {
    battle?: boolean;
    oops?: boolean;
    godModal?: boolean;
  };
}

export default function GameModal({ children, name, params }: ModalProps): JSX.Element {
  const modal = useRef<any>();
  const dispatch = useDispatch();
  const { playSound } = useContext(AppContext);

  const handleClose = () => {
    playSound(SoundTypeE.CLOSE);
    dispatch(hide(name));
  };

  const cancel = (e: React.KeyboardEvent) => {
    if (!params?.battle && !params?.oops && e.key === "Escape") handleClose();
  };
  useEffect(() => {
    if (modal.current) {
      modal.current.focus();
    }
  }, []);

  return (
    <div
      className={cn(styles.modal, {
        [styles.oops]: params && params.oops,
      })}
      id='id'
      onKeyDown={cancel}
      ref={modal}
      tabIndex={0}
    >
      <div
        className={styles.wrapper}
        onClick={() => (params?.battle || params?.oops || params?.godModal ? null : handleClose())}
      />
      <div
        className={cn(styles.childrenWrapper, {
          [styles.p_battle]: params?.battle,
        })}
      >
        {params?.battle && (
          <span className={styles.back} onClick={handleClose}>
            {"<"} back
          </span>
        )}
        {!params?.oops && <CloseIcon className={styles.close_icon} onClick={handleClose} />}
        {children}
      </div>
    </div>
  );
}
// modal header
export function ModalHeader({ title, subtitle }: { title: string; subtitle?: string }) {
  return (
    <>
      <h4 className={styles.title}>{title}</h4>
      {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
    </>
  );
}
