import { FOLLOW_STEPS_MODAL_2 } from "../../../../utils/modalNames";
import WithModal, { ModalProps } from "../../../HOC/withModal";
import cn from "classnames";
import styles from "./FStepsModal2.module.scss";
import Step, { Btn } from "./Step";
import GodImage from "../../GodImage/GodImage";
import useFStepsModal2Control from "./useFStepsModal2Control";
import gtImg from "../../../../assets/img/godtemple.png";
import arrowImg from "../../../../assets/Icons/arrow_upgrade.svg";
import dgclImg from "../../../../assets/img/digicol.png";
import opsImg from "../../../../assets/img/opensea.png";
import { MyGodInfo } from "../../../../services/api/gods";
import makeImagePath from "../../../../utils/makeImagePath";
import AreYouSure, { AreYouSureProps } from "../../AreYouSure/AreYouSure";

export enum StepCode {
  None,
  TransferToUser,
  TransferToSystem,
  DoTransferToUser,
  SellOnDigicol,
  UpdateDigicolOrder,
  SellOnOpenSea,
  UnlistFromSale,
  Stake,
  Unstake,
  Upgrade,
  Done,
  Cancel,
}

export type TransferDir = StepCode.TransferToSystem | StepCode.TransferToUser;
export type SaleDir =
  | StepCode.SellOnDigicol
  | StepCode.SellOnOpenSea
  | StepCode.UnlistFromSale;

export interface FStepsModal2Props {
  readonly god?: MyGodInfo;
  readonly godOnSale?: MyGodInfo;
  readonly steps?: StepCode[];
  title: string;
  progress?(b: any): void;
  done?(b: any): void;
}

interface StepConfig {
  readonly title: string;
  readonly subtitle?: string;
  readonly btnTitle?: string;
  action?(final: boolean): void;
}

interface StepComponent {
  component(props: { final: boolean; active: boolean }): JSX.Element;
}

type StepDescription = StepConfig | StepComponent;

const isStepConfig = (x: StepDescription): x is StepConfig => {
  return typeof (x as StepConfig).title !== "undefined";
};

export interface FStepsModal2Control {
  readonly step: number;
  readonly progress: boolean;
  readonly saleDir?: SaleDir;
  readonly areYouSure?: AreYouSureProps;
  readonly steps: StepCode[];

  transfer(dir: TransferDir, final: boolean): void;
  putOnSale(final: boolean): void;
  unlistFromSale(final: boolean): void;
  stake(final: boolean): void;
  unstake(final: boolean): void;
  upgrade(final: boolean): void;
}

function FStepsModal2(props: FStepsModal2Props & ModalProps): JSX.Element {
  const control = useFStepsModal2Control(props);

  const stepsConfig: { [k: number]: StepDescription } = {
    [StepCode.TransferToUser]: {
      title: "Pay for transfer to your Web3 wallet",
      btnTitle: "Sign transaction",
      action: (final) => control.transfer(StepCode.TransferToUser, final),
    },

    [StepCode.TransferToSystem]: {
      subtitle: "The token is being transferred through the blockchain",
      title: "Pay for transfer to your system account",
      btnTitle: "Sign transaction",
      action: (final) => control.transfer(StepCode.TransferToSystem, final),
    },

    [StepCode.DoTransferToUser]: {
      title: "Transfer to your Web3 wallet",
      subtitle: "The token is being transferred through the blockchain",
    },

    [StepCode.UpdateDigicolOrder]: {
      title: "Update Digicol order",
      subtitle: "Updating the sale information in the blockchain",
    },

    [StepCode.SellOnDigicol]: {
      component: (props) => (
        <Btn
          action={() => control.putOnSale(props.final)}
          final={props.final}
          active={props.active}
        >
          Sell on Digicol
        </Btn>
      ),
    },

    [StepCode.SellOnOpenSea]: {
      component: (props) => (
        <Btn
          action={() => control.putOnSale(props.final)}
          final={props.final}
          active={props.active}
        >
          Sell on OpenSea
        </Btn>
      ),
    },

    [StepCode.UnlistFromSale]: {
      component: (props) => (
        <Btn
          action={() => control.unlistFromSale(props.final)}
          final={props.final}
          active={props.active}
        >
          Unlist from sale
        </Btn>
      ),
    },

    [StepCode.Stake]: {
      component: (props) => (
        <Btn
          action={() => control.stake(props.final)}
          final={props.final}
          active={props.active}
        >
          Stake token
        </Btn>
      ),
    },

    [StepCode.Unstake]: {
      component: (props) => (
        <Btn
          action={() => control.unstake(props.final)}
          final={props.final}
          active={props.active}
        >
          Unstake token
        </Btn>
      ),
    },

    [StepCode.Upgrade]: {
      component: (props) => (
        <Btn
          action={() => control.upgrade(props.final)}
          final={props.final}
          active={props.active}
        >
          Upgrade token
        </Btn>
      ),
    },
  };

  if (!props.god || !props.steps) return <></>;

  return (
    <div className={styles.wrapper}>
      {control.areYouSure && (
        <AreYouSure
          title={control.areYouSure.title}
          done={(ok) => control.areYouSure?.done(ok)}
        />
      )}
      <h3>{props.title}</h3>
      {!!control.saleDir && (
        <div className={styles.transferBlock}>
          <img
            src={control.saleDir === StepCode.UnlistFromSale ? dgclImg : gtImg}
          />
          <img src={arrowImg} />
          <img
            src={
              control.saleDir === StepCode.SellOnDigicol
                ? dgclImg
                : control.saleDir === StepCode.SellOnOpenSea
                ? opsImg
                : gtImg
            }
          />
        </div>
      )}
      <div className={styles.steps}>
        <div className={styles.steps__panel}>
          <h3>Follow steps</h3>
          <div className={styles.steps__steps}>
            {control.steps.map(
              (
                stepCode,
                i,
                _,
                cfg = stepsConfig[stepCode],
                final = stepCode === StepCode.TransferToUser
                  ? i === control.steps.length - 2
                  : i === control.steps.length - 1
              ) => (
                <div
                  key={i}
                  className={cn({ [styles.hidden]: control.step !== i })}
                >
                  {isStepConfig(cfg) ? (
                    <Step
                      title={cfg.title}
                      subtitle={cfg.subtitle}
                      btnTitle={cfg.btnTitle}
                      run={() => cfg.action && cfg.action(final)}
                      progress={control.progress && control.step === i}
                      final={i === control.steps.length - 1}
                      active={control.step === i && !control.progress}
                    />
                  ) : (
                    <Step progress={control.progress && control.step === i}>
                      <cfg.component
                        final={final}
                        active={control.step === i && !control.progress}
                      />
                    </Step>
                  )}
                </div>
              )
            )}
          </div>
        </div>
        <div className={styles.steps__image}>
          <GodImage
            img={makeImagePath(props.god.Image)}
            small
            wide
            count={props.god.Count || 0}
          />
          <p className={styles.name}>
            {props.god.Name}
            {(props.god.Count || 0) > 0 && (
              <span>&nbsp;x{props.god.Count}</span>
            )}
          </p>
        </div>
      </div>
    </div>
  );
}

export default WithModal(FOLLOW_STEPS_MODAL_2, FStepsModal2, {
  noPanel: false,
  confirmClose: true,
});
