import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import https from "https";
import qs from "qs";
import store from "../../store";
import { cleanToken } from "../../store/auth/actions";

export interface APIError {
  message: string;
  errCode: string;
}

export interface APIConfig {
  baseURL: string;
  onError: (err: APIError) => void;
  Authorization: string | undefined;
}

type ServerStatus = "success" | "error";

export interface ServerResponse {
  Status: ServerStatus;
  Message?: string;
}

export type ServerAxiosResponse = AxiosResponse<ServerResponse>;

export type PossiblyError = ServerAxiosResponse | Error;

export function isServerError(resp: PossiblyError): resp is Error {
  return (resp as Error).message !== undefined;
}

export type ResponseOrError = ServerAxiosResponse | Error | string;

type _PossiblyError = ServerAxiosResponse | Error | string;

function _isError(resp: _PossiblyError): resp is Error {
  return (resp as Error).message !== undefined;
}

function _isResponse(resp: _PossiblyError): resp is ServerAxiosResponse {
  return (resp as ServerAxiosResponse).data !== undefined;
}

function _onError(e: _PossiblyError): Error {
  if (_isError(e)) {
    return e;
  }
  if (_isResponse(e)) {
    return Error(e.data.Message);
  }
  return Error(e);
}

class ApiSetting {
  private _axios: AxiosInstance;
  private _token?: string;
  private _tokenExpires?: number;

  setToken(t?: string, e?: number) {
    this._token = t;
    this._tokenExpires = e;
  }

  private checkToken(): string | undefined {
    if (!this._token) return;
    if (
      typeof this._tokenExpires !== "undefined" &&
      new Date().getTime() > this._tokenExpires
    ) {
      this._onTokenExpired();
      return;
    }
    return this._token;
  }

  private _onTokenExpired() {
    this._token = undefined;
    this._tokenExpires = undefined;
    store()?.dispatch(cleanToken());
  }

  constructor({ baseURL, onError, Authorization }: APIConfig) {
    this._axios = Axios.create({
      baseURL: baseURL,
      httpsAgent: new https.Agent({
        rejectUnauthorized: false,
      }),
    });

    this._axios.interceptors.response.use((response: any) => {
      if (response.data.ErrCode === 103) {
        this._onTokenExpired();
      }
      if (response.data.Status === "Error" || response.data.Message) {
        if (onError) {
          onError({
            errCode: response.data.ErrCode,
            message: response.data.Message,
          });
        }
        return Promise.reject(response);
      }
      if (response.data.status === "error" || response.data.message) {
        if (onError) {
          onError({
            errCode: response.data.ErrCode,
            message: response.data.message,
          });
        }
        return Promise.reject(response);
      }
      return response;
    });

    this._axios.interceptors.request.use((config: any) => {
      if (
        config.url &&
        config.url.includes("http") &&
        !config.url.includes(baseURL)
      ) {
        // This request is not to our server
        return;
      }

      const auth: string[] = [];

      if (Authorization) {
        auth.push(`Basic ${window.btoa(Authorization)}`);
      }

      const token = this.checkToken();

      if (token) {
        auth.push(`Bearer ${token}`);
      }

      config.headers["Authorization"] = auth.join(", ");

      if (config.method === "post" || config.method === "put") {
        if (!(config.data instanceof FormData)) {
          if (!(typeof config.data === "string")) {
            config.data = qs.stringify(config.data);
          }
          config.headers["Content-Type"] = "application/x-www-form-urlencoded";
        }
      }

      return { ...config };
    });
  }

  put = <T extends ServerResponse>(
    URL: string,
    payload: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T> | Error> => {
    return this._axios.put<T>(URL, payload, config).catch(_onError);
  };

  get = <T extends ServerResponse>(
    URL: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T> | Error> => {
    return this._axios.get<T>(URL, config).catch(_onError);
  };

  post = <T extends ServerResponse>(
    URL: string,
    payload?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T> | Error> => {
    return this._axios.post<T>(URL, payload, config).catch(_onError);
  };

  delete = <T extends ServerResponse>(
    URL: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T> | Error> => {
    return this._axios.delete<T>(URL, config).catch(_onError);
  };
}

export default ApiSetting;
