import cn from "classnames";
import styles from "./GameBurger.module.scss";

interface BurgerPropsI {
  active: boolean;
  open: (open: boolean) => void;
}

const GameBurger = ({ active, open }: BurgerPropsI) => {
  return (
    <div
      className={cn(styles.header_burger, {
        [styles.active]: active,
      })}
      onClick={() => open(!active)}
    >
      <span className={styles.burger_line}></span>
    </div>
  );
};

export default GameBurger;
