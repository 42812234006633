import BigNumber from "bignumber.js";
import { number } from "yup/lib/locale";
import wallet from "../wallet";
import contractAddress from "./addresses";
import { getRSV } from "./common";
import { getExchangeContract } from "./exchange";
import exchangeAssetTypes from "./exchangeAssetTypes";
import { sendTransaction } from "./transaction";

export const SELLER_FEE = "250";

export const checkTokenID = (tokenID: string) =>
  tokenID.includes(":")
    ? tokenID.split(":")[1].includes("0x")
      ? tokenID.split(":")[1]
      : "0x" + tokenID.split(":")[1]
    : tokenID.includes("0x")
    ? tokenID
    : "0x" + tokenID;

function getAssetType(tokenAddress: string) {
  return contractAddress.CONTRACT_USDT.toLowerCase() ===
    tokenAddress.toLowerCase()
    ? exchangeAssetTypes.ERC20Deprecated
    : exchangeAssetTypes.ERC20;
}

function buildOrder(data: any = {}) {
  //console.log(data);
  const empty = "0x0000000000000000000000000000000000000000";
  data = {
    buyingTokenAddress: empty,
    buyingTokenId: empty,
    buyingAssetType: exchangeAssetTypes.ETH,
    sellingTokenAddress: empty,
    sellingTokenId: empty,
    sellingAssetType: exchangeAssetTypes.ETH,
    owner: wallet.account,
    salt: wallet.web3?.utils.randomHex(20),
    selling: 1,
    buying: 0,
    sellerFee: SELLER_FEE,
    ...data,
  };

  const buyAsset = {
    token: data.buyingTokenAddress,
    tokenId: data.buyingTokenId,
    assetType: data.buyingAssetType,
  };
  const sellAsset = {
    token: data.sellingTokenAddress,
    tokenId: data.sellingTokenId,
    assetType: data.sellingAssetType,
  };
  const orderKey = {
    owner: data.owner,
    salt: data.salt,
    buyAsset,
    sellAsset,
  };
  return {
    key: orderKey,
    selling: data.selling,
    buying: data.buying,
    sellerFee: data.sellerFee,
  };
}

function buildSellOrder(
  tokenId: string,
  contract: string,
  buying: string,
  selling = 1,
  buyingKey: any = {},
  reverse = false,
  currAddress: string
) {
  // tokenId = tokenId.includes(":") ? prepareTokenId(tokenId.split(":")[1]) : prepareTokenId(tokenId);
  const empty = "0x0000000000000000000000000000000000000000";
  buyingKey = {
    tokenAddress: currAddress,
    tokenId: empty,
    assetType:
      currAddress === empty
        ? exchangeAssetTypes.ETH
        : getAssetType(currAddress),
    ...buyingKey,
  };

  let sellingKey = {
    tokenAddress: contract,
    tokenId: tokenId,
    assetType: exchangeAssetTypes.ERC1155,
  };

  if (reverse) {
    const swap = (value1: any, value2: any) => [value2, value1];
    [buyingKey, sellingKey] = swap(buyingKey, sellingKey);
    [buying, selling] = swap(buying, selling);
  }

  return buildOrder({
    sellingTokenAddress: sellingKey.tokenAddress,
    sellingTokenId: sellingKey.tokenId,
    sellingAssetType: sellingKey.assetType,
    buyingTokenAddress: buyingKey.tokenAddress,
    buyingTokenId: buyingKey.tokenId,
    buyingAssetType: buyingKey.assetType,
    selling: selling.toString(10),
    buying: buying,
  });
}

async function signOrder(order: any) {
  //console.log("[signOrder] 1", order);
  const message = await getExchangeContract()
    ?.methods.prepareMessage(order)
    .call();
  //console.log("[signOrder] 2", message);
  return (await wallet.sign(message)).slice(2);
}

if (typeof window !== "undefined")
  (window as any).bignum = (x: string, y: number) =>
    new BigNumber(x).multipliedBy(y).toFixed();

export async function prepareSellOrder(
  tokenId: string,
  contractAddress: string,
  price: string,
  amount = 1,
  reverse = false,
  currAddress: string
) {
  //console.log("A1");
  tokenId = checkTokenID(tokenId);
  //console.log("A2");
  const p = new BigNumber(price).multipliedBy(amount).toFixed();
  //console.log("A3");
  const order = buildSellOrder(
    tokenId,
    contractAddress,
    p,
    amount,
    {},
    reverse,
    currAddress
  );
  //console.log("A4");
  const signedOrder = await signOrder(order);
  //console.log("A5");
  return {
    order,
    signedOrder: getRSV(signedOrder),
  };
}

/** example:
 {
  "key": {
    "owner": "0x68c986BFBA713ae9bCfC1eb85D50D7C32865166d",
    "salt": "0xc9fb1432e960636d7d7eb5f0e0591a77fc1f610b",
    "buyAsset": {
      "token": "0x0000000000000000000000000000000000000000",
      "tokenId": "0x0000000000000000000000000000000000000000",
      "assetType": 0
    },
    "sellAsset": {
      "token": "0xe8bd72147522531bbf215f788529d2ee5c9612da",
      "tokenId": "0x000000000000000000000000000000002c3c8783edaf6dfb4b599f72daeae170",
      "assetType": 2
    }
  },
  "selling": "1",
  "buying": "100000000000000",
  "sellerFee": "250"
}
 */
export interface Asset {
  token: string; // common.Address
  tokenId: string; // *big.Int
  assetType: number; // uint8
}
export interface OrderKey {
  owner: string; // common.Address
  salt: string; // *big.Int
  sellAsset: Asset;
  buyAsset: Asset;
}

export interface Order {
  key: OrderKey;
  selling: string; //*big.Int
  buying: string; //*big.Int
  sellerFee: string; //*big.Int
}

export async function cancelOrder(orderStr: string) {
  const order = JSON.parse(orderStr) as Order;
  const cancel = getExchangeContract()?.methods.cancel(order.key);
  const result = sendTransaction(cancel);
  return result;
}
