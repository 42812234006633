import { AxiosResponse } from "axios";
import service from "./service";
import { ServerResponse } from "./setting";

export interface NonceResponse extends ServerResponse {
  Nonce: string;
}

export function nonce(
  address: string
): Promise<AxiosResponse<NonceResponse> | Error> {
  return service.api.get("/metamask_nonce", { params: { Address: address } });
}

export interface ValidateResponse extends ServerResponse {
  Token: string;
}

export function validate(
  data: string
): Promise<AxiosResponse<ValidateResponse> | Error> {
  return service.api.post("/auth_post/metamask/validate", data);
}
