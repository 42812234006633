import * as socket from "./socket";
import api from "../api";
import { isServerError } from "../api/setting";
import { QueueItem } from "../api/ws";

const WS_ADDRESS = process.env.REACT_APP_WS_ADDRESS;

export enum WSEventsE {
  SMT_HELLO = 0,
  SMT_TOKEN_BOUGHT = 1, // 1. покупка моего токена,
  SMT_TOKEN_CREATED = 2, // 2. публикация токена у того, кого я фолловлю,
  SMT_BID = 3, // 3. кто-то сделал ставку на мой токен,
  SMT_MY_BID_CANCELLED = 4, // 4. кто-то отменил мою ставку на свой токен,
  SMT_BID_CANCELLED = 5, // 5. кто-то отменил свою ставку на мой токен.
  SMT_COMMISSION_RECEIVED = 6, //6. комиссия для вывода incentives получена
  SMT_INCENTIVES_SENT = 7, //7. incentives переведены пользователю

  SMT_TOKEN_SOLD = 8, //+ 8. покупка моего токена
  SMT_TOKEN_SENT = 9, //+ 9. я отправил токен другому пользователю (не продажа а transfer)
  SMT_TOKEN_RECEIVED = 10, //+ 10. яполучил токен от другого пользователя (не продажа а transfer)
  SMT_TOKEN_GOT_BY_LENDER = 11, //+ 11. мой токен перешел к кредитору в результате невозвращенного займа
  SMT_TOKEN_GOT_FROM_BORROWER = 12, //+ 12. я получил токен от заемщика в результате невозвращенного займа
  SMT_WITHDRAW_DONE = 13, //13. сумма withdraw переведенa пользователю
  SMT_GOD_TRANSFER_DONE = 14, //14. бог переведен с системного кошелька юзеру
  SMT_BALANCE_INCREASED = 15, //баланс пользователя пополнен (передается в случае успешного депозита соулов, обмена MYTH/SOUL а также перевода награды за стэкинг на виртуальный баланс юзера)
  SMT_GAME_PLAYED = 16, // игра сыграна - по окончанию PvP или PvE игры (в случае PvP - посылается обоим участникам)
  SMT_WITHDRAW_SENT = 17, // сумма withdraw отправлена пользователю (транзакция отправлена в сеть)
  SMT_WSPROXY_MESSAGE = 18, // сообщение пришло с digicol
  SMT_TOKEN_UPGRADE_FINISHED = 19, //19. Завершен апгрейд бога
  SMT_TOKEN_UPGRADE_ABORTED = 20, //20. Прерван апгрейд бога
}

export enum GameBalanceReasonE {
  SOUL, //1 - депозит SOUL,
  CUR_CHANGED, //2 - обмен других токенов на SOUL,
  STAKING, //3 - добавление наград за стэкинг
}

export interface TokenEvent {
  TokenId: string;
  TokenPreview: string;
  TokenName: string;
  Created: number; //unixtime
}

export interface TokenBoughtEvent extends TokenEvent {
  OldOwnerAddress: string;
}

export function isTokenBought(ev: WSEvent): ev is WSEvent<TokenBoughtEvent> {
  return (
    ev.Type === WSEventsE.SMT_TOKEN_BOUGHT ||
    ev.Type === WSEventsE.SMT_TOKEN_RECEIVED ||
    ev.Type === WSEventsE.SMT_TOKEN_GOT_FROM_BORROWER
  );
}

export interface TokenSoldEvent extends TokenEvent {
  NewOwnerAddress: string;
}

export function isTokenSold(ev: WSEvent): ev is WSEvent<TokenSoldEvent> {
  return (
    ev.Type === WSEventsE.SMT_TOKEN_SOLD ||
    ev.Type === WSEventsE.SMT_TOKEN_SENT ||
    ev.Type === WSEventsE.SMT_TOKEN_GOT_BY_LENDER
  );
}

export interface TokenCreatedEvent extends TokenEvent {
  AuthorAddress: string;
}

export function isTokenCreated(ev: WSEvent): ev is WSEvent<TokenCreatedEvent> {
  return ev.Type === WSEventsE.SMT_TOKEN_CREATED;
}

export interface BidEvent extends TokenEvent {
  Amount: number;
}

export function isBid(ev: WSEvent): ev is WSEvent<BidEvent> {
  return ev.Type === WSEventsE.SMT_BID;
}

export interface MyBidCancelEvent extends TokenEvent {
  OwnerAddress: string;
}

export function isMyBidCancel(ev: WSEvent): ev is WSEvent<MyBidCancelEvent> {
  return ev.Type === WSEventsE.SMT_MY_BID_CANCELLED;
}

export interface BidCancelEvent extends TokenEvent {
  CancellerAddress: string;
}

export function isBidCancel(ev: WSEvent): ev is WSEvent<BidCancelEvent> {
  return ev.Type === WSEventsE.SMT_BID_CANCELLED;
}

export interface ComissionEvent {
  TxId: string; //хэш транзакции
  Amount: number; //сумма перевода
  Ok: boolean; //success - true, failed - false
}

export function isComission(ev: WSEvent): ev is WSEvent<ComissionEvent> {
  return ev.Type === WSEventsE.SMT_COMMISSION_RECEIVED || ev.Type === WSEventsE.SMT_INCENTIVES_SENT;
}

function isProxy(ev: WSEvent): ev is WSEvent<socket.WSEvent | string> {
  return ev.Type === WSEventsE.SMT_WSPROXY_MESSAGE;
}

export interface GodTransferDoneEvent {
  TokenId: string; //токен Id бога
  Collection: string;
  GodsCount: number; //общее кол-во экземпляров бога на кошельке пользователя
  Error: string; //ошибка, если была в процессе ожидания завершения перевода
}

export function isGodTransferDone(ev: WSEvent): ev is WSEvent<GodTransferDoneEvent> {
  return ev.Type === WSEventsE.SMT_GOD_TRANSFER_DONE;
}

// на случай если нужно будет получить все игровые нотификации
export const isNotifsForGame = (ev: WSEvent): ev is WSEvent => {
  return balanceWasChanged(ev) || isGamePlayed(ev);
};

export interface GNotificationsI {
  Played: number;
  Result: "L" | "W" | "D";
  Rival: string;
  RoomId: string;
  StakeSoul: number;
  Type: 0 | 1;
}

export const isGamePlayed = (ev: WSEvent): ev is WSEvent<GNotificationsI> => {
  return ev.Type === WSEventsE.SMT_GAME_PLAYED;
};

export interface BalanceChI {
  TxId: string; //  хэш транзакции отправки токенов
  Amount: number; // отправленная сумма
  Ok: boolean; // true/false - признак успеха отправки транзакции
}

export const balanceWasChanged = (ev: WSEvent): ev is WSEvent<BalanceChI> => {
  return (
    (ev.Type === WSEventsE.SMT_BALANCE_INCREASED || ev.Type === WSEventsE.SMT_WITHDRAW_DONE) &&
    !ev.old
  );
};

export interface TokenUpgradeEvent extends TokenEvent {
  OldTokenId: string;
}

export function isTokenUpgrade(ev: WSEvent): ev is WSEvent<TokenUpgradeEvent> {
  return (
    ev.Type === WSEventsE.SMT_TOKEN_UPGRADE_FINISHED ||
    ev.Type === WSEventsE.SMT_TOKEN_UPGRADE_ABORTED
  );
}

export interface WSEvent<T = any> extends socket.WSEvent<T> {
  readonly old: boolean;
  readonly external?: boolean;
}

export interface EventCB {
  (ev: WSEvent): boolean | void;
  once?: boolean;
}

class WebSocketService {
  private cbs_: Set<EventCB> = new Set();
  private needReconnect_ = false;
  private incomingSocket?: socket.ReconnectingSocket;

  async init(tok?: string, ...cbs: EventCB[]) {
    if (!tok) return;

    //console.log("WSS INIT", tok)

    for (const cb of cbs) {
      this.addListener(cb);
    }
    const incomingAddr = `${WS_ADDRESS}/api/socket?Token=${tok}`;

    const processQueue = (records: QueueItem[], lastId: number, proxy?: boolean): number => {
      const q = [];
      for (const el of records) {
        const ev = JSON.parse(el.Message);
        q.push(ev);
      }
      q.sort((a, b) => a.Id - b.Id);
      //console.log("SOCKET QUEUE MESSAGES", q);
      for (const ev of q) {
        this.processIncomingEvent_({ ...ev, old: true, external: proxy });
        if (ev.Id > lastId) lastId = ev.Id;
      }
      return lastId;
    };

    if (this.incomingSocket) {
      this.incomingSocket.setNeedReconnect(this.needReconnect_);
      this.incomingSocket.reconnect(incomingAddr);
    } else {
      const q1 = await api.ws.queue();
      let lastId = -1;
      //const lastId = -1;

      if (isServerError(q1)) console.error(q1);
      else {
        //console.log("RECEIVED SOCKET QUEUE:", queue.data);
        if (q1.data?.records) lastId = processQueue(q1.data.records, lastId);
      }

      const q2 = await api.ws.xsQueue();
      if (isServerError(q2)) console.error(q2);
      else {
        //console.log("RECEIVED SOCKET QUEUE:", queue.data);
        if (q2.data?.records) lastId = processQueue(q2.data.records, lastId, true);
      }

      this.incomingSocket = new socket.ReconnectingSocket(lastId);
      this.incomingSocket.setNeedReconnect(this.needReconnect_);
      this.incomingSocket.reconnect(incomingAddr);
      this.incomingSocket.cb = (ev) => this.processIncomingEvent_({ ...ev, old: false });

      /* это для тестирования:
      const testAddEvent = () => {
        const ev: WSEvent<BidEvent> = {
          Type: WSEventsE.SMT_BID,
          Id: new Date().getTime() * 1000 * 1000,
          Event: {
            TokenId:
              "0000000000000000000000000000000000000000000000000000000000002a56",
            TokenPreview:
              "col_preview/0x71B053bCaF286Ba20D9006845412D4532A8E1f34_0000000000000000000000000000000000000000000000000000000000002a56",
            TokenName: "Sample",
            Created: new Date().getTime() / 1000,
            Amount: 10000,
          },
          old: false,
          external: true,
        };
        this.processIncomingEvent_(ev);
      }
      */

      /* это для тестирования:
      setTimeout(() => {
        for (let i = 0; i < 20; i++) testAddEvent();
      }, 10 * 1000);
      */
    }
  }

  setNeedReconnect(needReconnect: boolean) {
    this.needReconnect_ = needReconnect;
    //console.log("WSS NEED RECONNECT", needReconnect)
    if (this.incomingSocket) this.incomingSocket.setNeedReconnect(needReconnect);
  }

  processIncomingEvent_(ev: WSEvent) {
    // console.log("PROCESS SOCKET MESSAGE__FIRST", ev);
    if (isProxy(ev)) {
      if (typeof ev.Event === "string") return;
      ev = { ...ev.Event, Id: ev.Id, old: ev.old, external: true };
    }
    //console.log("RECEIVED SOCKET MESSAGE", ev);
    const s = new Set<EventCB>();
    this.cbs_.forEach((cb) => {
      // console.log("PROCESS SOCKET MESSAGE", ev, cb);
      const ok = cb(ev);
      if (!(ok && cb.once)) s.add(cb);
    });
    this.cbs_ = s;
  }

  addListener(cb: EventCB) {
    this.cbs_.add(cb);
  }

  removeListener(cb: EventCB) {
    this.cbs_.delete(cb);
  }

  once(cb: EventCB) {
    cb.once = true;
    this.cbs_.add(cb);
  }
}

const ws = new WebSocketService();

export default ws;
