import { IconPropsI } from "./Icon.props";

export default function Icon({ src, ...props }: IconPropsI) {
  return (
    <div {...props} style={{ position: "relative", width: "20px", height: "20px" }}>
      <object
        width={"100%"}
        data={src}
        type='image/svg+xml'
        style={{ position: "absolute", zIndex: -1 }}
      />
    </div>
  );
}
