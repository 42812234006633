import { ReactNode, useState } from "react";
import styles from "./Modal.module.scss";
import cn from "classnames";
import { hide } from "redux-modal";
import { useDispatch } from "react-redux";
import { UPGRADE_MODAL2 } from "../../../utils/modalNames";

type ModalProps = {
  children: ReactNode;
  name: string;
  noPanel?: boolean;
  noHandleClose?: boolean;
  confirmClose?: boolean;
  animation?: boolean;
};

const OKCancel = (props: { done(ok: boolean): void; title: string }) => (
  <div className={styles.okcancel}>
    <div className={styles.okcancel__contents}>
      <div className={styles.okcancel__title}>{props.title}</div>
      <div className={styles.okcancel__buttons}>
        <button className="btn" onClick={() => props.done(true)}>
          Yes
        </button>
        <button className="btn btn--white" onClick={() => props.done(false)}>
          No
        </button>
      </div>
    </div>
  </div>
);

export default function Modal({
  children,
  name,
  noPanel = false,
  confirmClose = false,
}: ModalProps): JSX.Element {
  const dispatch = useDispatch();
  const handleClose = confirmClose
    ? () => setOKC(true)
    : () => dispatch(hide(name));
  const [okc, setOKC] = useState(false);
  const okcDone = (ok: boolean) => {
    if (ok) dispatch(hide(name));
    setOKC(false);
  };

  return (
    <div className={cn(styles.modal)}>
      <div
        className={styles.wrapper}
        onClick={noPanel ? () => null : handleClose}
      />
      <div
        className={cn(styles.childrenWrapper, {
          [styles.upgradeModal]: name === UPGRADE_MODAL2,
          // [styles.no_panel]: !!noPanel,
        })}
      >
        {okc && (
          <OKCancel
            done={okcDone}
            title="Are you sure you want to stop the process?"
          />
        )}

        {!noPanel && (
          <button
            className={cn(styles.childrenWrapper__close_btn, "btn-reset")}
            type="button"
            aria-label="close"
            onClick={handleClose}
          />
        )}
        {children}
      </div>
    </div>
  );
}
