import { FunctionComponent } from "react";
import GameHeader from "../../Layout/GameLayout/GameHeader";
import GameLayout from "../../Layout/GameLayout/GameLayout";
import AppContextProvider, { AppContextI } from "./AppContextProvider";

export default function WithGameLayout<T extends Record<string, unknown> & AppContextI>(
  Component: FunctionComponent<T>,
  params?: {
    isVideo?: boolean;
    src: string;
  }
) {
  return function withGameLayoutComponent(props: T): JSX.Element {
    document.body.style.background = "linear-gradient(0deg, #000000 100%, rgba(0, 0, 0, 0) 100%)";
    return (
      <AppContextProvider>
        <GameLayout src={params?.src || ""} isVideo={params?.isVideo || false}>
          <Component {...props} />
        </GameLayout>
      </AppContextProvider>
    );
  };
}
