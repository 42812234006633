import wallet from "../wallet";
import { getGasLimit, getGasPrice } from "./gas";
import {
  ContractSendMethod,
  SendOptions,
  EstimateGasOptions,
} from "web3-eth-contract";
import { TransactionReceipt } from "web3-core";
import EventEmitter from "events";

interface PromiEventEmitter<T> extends Promise<T>, EventEmitter {}

interface PromiEventType<T> {
  resolve?: (value: T | PromiseLike<T>) => void;
  reject?: (reason?: any) => void;
  eventEmitter: PromiEventEmitter<T>;
}

interface PromiEventNew<T> {
  new (justPromise?: boolean): PromiEventType<T>;
}

const PromiEvent =
  require("web3-core-promievent") as PromiEventNew<TransactionReceipt>;

export async function sendTransaction(
  method: ContractSendMethod,
  params: EstimateGasOptions = {}
): Promise<TransactionReceipt | undefined> {
  const from = wallet.account;
  if (!from) {
    return;
  }
  const gasPrice = await getGasPrice();
  const gas = await getGasLimit(method, { ...params, from: from || undefined });
  const promiEvent = new PromiEvent();
  promiEvent.eventEmitter.on("checkReceipt", (transactionHash: string) => {
    setTimeout(async () => {
      const receipt = await wallet.web3?.eth.getTransactionReceipt(
        transactionHash
      );
      if (!receipt) {
        promiEvent.eventEmitter.emit("checkReceipt", transactionHash);
        return;
      }
      if (receipt.status) {
        if (promiEvent.resolve) promiEvent.resolve(receipt);
      } else {
        if (promiEvent.reject) promiEvent.reject(receipt);
      }
    }, 5000);
  });
  const data: SendOptions = {
    ...params,
    from,
    gasPrice: gasPrice as unknown as string | undefined,
  };
  if (gas) {
    data.gas = gas as unknown as number;
  }
  method
    .send(data)
    .once("transactionHash", function (transactionHash) {
      promiEvent.eventEmitter.emit("transactionHash", transactionHash);
      promiEvent.eventEmitter.emit("checkReceipt", transactionHash);
    })
    .on("error", function (error) {
      if (promiEvent.reject) promiEvent.reject(error);
    });

  return await Promise.resolve(promiEvent.eventEmitter);
}
