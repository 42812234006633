import {
  Provider,
  DCMetamaskConnectProviderFactory,
  DCFortmaticProviderFactory,
  DCWalletLinkProviderFactory,
  DCWalletConnectProviderFactory,
} from "../utils/bch/walletProvider";
import { BigNumber as EthersBigNumber } from "ethers";
import Web3 from "web3";
import { TransactionConfig } from "web3-core";
import { AbiItem } from "web3-utils";
import { Contract } from "web3-eth-contract";
import WalletType from "../utils/walletType";
import api from "../services/api";
import { isServerError } from "./api/setting";
import contractsAbi from "./bch/abi";
import { SYSTEM_WALLET, MYTH_WALLET_PROXY, VALID_CHAIN, WALLET_FOR_BUY_SOUL } from "../config";
import Erc20Service, { mockCurrencies } from "./bch/erc20";
import { Coins } from "../constants";
import store from "../store";
import { CHANGE_NETWORK_MODAL } from "../utils/modalNames";
import { show } from "redux-modal";

const GAS_PRICE_COEF = parseFloat(process.env.REACT_APP_GAS_PRICE_COEF || "1");
const GAS_LIMIT_COEF = 1.1;
export const CHAIN_ID = parseInt(VALID_CHAIN || "0x3", 16);

const _clearLocalStorage = () => {
  localStorage.removeItem("walletconnect");
  localStorage.removeItem("WALLETCONNECT_DEEPLINK_CHOICE");
  const keys = Object.keys(localStorage);
  for (let i = 0; i < keys.length; i++) {
    if (
      keys[i].toLowerCase().indexOf("walletlink") !== -1 ||
      keys[i].toLowerCase().indexOf("walletconnect") !== -1
    ) {
      localStorage.removeItem(keys[i]);
    }
  }
};

export interface DisconnectInfo {
  code: number;
  reason: string;
}

export type AccountsChandger = (accounts: string[]) => void;
export type ChainChandger = (changed: boolean) => void;
export type Disconnector = (info: DisconnectInfo) => void;
export type StateObserver = (connected: boolean) => void;

interface _CallbackTypes {
  accountChanged: AccountsChandger;
  chainChanged: ChainChandger;
  disconnected: Disconnector;
  state: StateObserver;
}

type _Callbacks<T> = {
  [P in keyof T]: T[P][];
};

const UINT_PREFIX = "0x";
const prepareTokenId = (tokenId: string) => (tokenId.match(/x/i) ? tokenId : UINT_PREFIX + tokenId);

class Wallet {
  private _web3?: Web3;
  private _provider?: Provider;
  private _accounts?: string[];
  private _chain = -1;
  private _type?: WalletType;

  constructor(
    private _callbacks: _Callbacks<_CallbackTypes> = {
      accountChanged: [],
      chainChanged: [],
      disconnected: [],
      state: [],
    }
  ) {}

  get properChain(): boolean {
    return this._chain === CHAIN_ID;
  }

  get typ(): WalletType | undefined {
    return this._type;
  }

  on<K extends keyof _CallbackTypes>(typ: K, cb?: _CallbackTypes[K]) {
    if (!cb) return;
    if (typ === "state") {
      const cb_ = cb as StateObserver;
      this.on("accountChanged", () => cb_(!!this.account));
      this.on("chainChanged", () => cb_(!!this.account));
      this.on("disconnected", () => cb_(!!this.account));
    }
    for (const c of this._callbacks[typ]) {
      if (cb === c) return;
    }
    const arr = this._callbacks[typ];
    (arr as _CallbackTypes[K][]).push(cb);
  }

  get web3(): Web3 | undefined {
    return this._web3;
  }

  createContract(abi: AbiItem | AbiItem[], address?: string): Contract | null {
    if (this._web3) return new this._web3.eth.Contract(abi, address);
    return null;
  }

  get enabled(): boolean {
    return this._web3 !== null && this._provider !== null;
  }

  private _enableProvider(): Promise<void> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve, reject) => {
      try {
        if (!this._provider) {
          reject("_enableProvider: provider not enabled");
          return;
        }
        if (this._provider.source) {
          await this._provider.source.enable();
        } else {
          await this._provider.enable();
        }
        resolve();
      } catch (e) {
        reject("_enableProvider: " + e);
      }
    });
  }

  private _init(web3?: Web3): Promise<void> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve, reject) => {
      try {
        await this._enableProvider();

        this._web3 = web3;

        if (this._web3) {
          this._accounts = await this._web3.eth.getAccounts();
          this._chain = await this._web3.eth.getChainId();
          for (const cb of this._callbacks.state) {
            cb(!!this.account);
          }
          resolve();
        } else {
          reject("_init: web3 is undefined");
        }
      } catch (e) {
        reject("_init: " + e);
      }
    });
  }

  private async _setProviders(factory: DCMetamaskConnectProviderFactory) {
    try {
      const { provider, web3 } = await factory.create();
      this._provider = provider;
      this._web3 = web3;

      this._provider.on("accountsChanged", (accounts: string[]) => {
        let fire = false;
        if (this._accounts) {
          if (this._accounts.length !== accounts.length) {
            fire = true;
          } else {
            for (let i = 0; i < this._accounts.length; i++) {
              if (this._accounts[i] !== accounts[i]) {
                fire = true;
                break;
              }
            }
          }
        }
        this._accounts = accounts;
        if (fire) {
          for (const cb of this._callbacks.accountChanged) {
            cb(accounts);
          }
        }
      });
      this._provider.on("chainChanged", (chainId: string) => {
        this._chain = parseInt(chainId, 16);
        for (const cb of this._callbacks.chainChanged) {
          cb(this.properChain);
        }
      });

      this._provider.on("disconnect", (code: number, reason: string) => {
        for (const cb of this._callbacks.disconnected) {
          cb({ code, reason });
        }
      });

      await this._init(web3);
    } catch (e) {
      //console.log(e);
      return Promise.reject("_setProviders: " + e);
    }
  }

  setMetamask(): Promise<void> {
    return this._setProviders(new DCMetamaskConnectProviderFactory());
  }

  setFortmatic(): Promise<void> {
    return this._setProviders(new DCFortmaticProviderFactory());
  }

  setWalletLink(): Promise<void> {
    return this._setProviders(new DCWalletLinkProviderFactory());
  }

  setWalletConnect(): Promise<void> {
    return this._setProviders(new DCWalletConnectProviderFactory());
  }

  async disconnect() {
    if (!this._provider) return;

    if (this._provider.fm) {
      await this._provider.fm.user.logout();
    }

    let needCB = true;
    if (this._provider?.disconnect) {
      needCB = this.typ === WalletType.COIN_BASE;
      await this._provider.disconnect();
    }

    this._provider = undefined;
    this._web3 = undefined;
    this._chain = -1;
    this._accounts = undefined;
    this._type = undefined;

    if (needCB) {
      for (const cb of this._callbacks.disconnected) {
        cb({ code: 0, reason: "" });
      }
    }
  }

  get accountOfAnyChain(): string | null {
    if (this._accounts && this._accounts.length > 0) return this._accounts[0];
    return null;
  }

  get account(): string | null {
    if (this.properChain && this._accounts && this._accounts.length > 0) return this._accounts[0];
    return null;
  }

  async sign(data: string): Promise<string> {
    const acc = this.accountOfAnyChain;
    if (acc && this._web3) {
      const result = await this._web3.eth.personal.sign(data, acc, "");
      return result;
    }
    return Promise.reject("sign: not initialized");
  }

  async getBalance(): Promise<string> {
    const acc = this.accountOfAnyChain;
    if (!acc || !this._provider || !this._web3) {
      return Promise.reject("getBalance: not initialized");
    }
    const weiBalance = this._web3 ? await this._web3.eth.getBalance(acc) : "0";
    return Promise.resolve(this._web3.utils.fromWei(weiBalance));
  }

  async getWeiBalance(): Promise<string> {
    const acc = this.accountOfAnyChain;
    if (!acc || !this._provider || !this._web3) {
      return Promise.reject("getBalance: not initialized");
    }
    const weiBalance = this._web3 ? await this._web3.eth.getBalance(acc) : "0";
    return Promise.resolve(weiBalance);
  }

  async estimateGas(obj: TransactionConfig): Promise<number> {
    if (this._web3) return Math.trunc((await this._web3.eth.estimateGas(obj)) * GAS_LIMIT_COEF);
    return Promise.reject("estimateGas: not initialized");
  }

  async getContractGasLimit(callee: any, params: any) {
    const limit = await callee.estimateGas(params);
    const newLimit = EthersBigNumber.from(limit)
      .mul(Math.round(GAS_LIMIT_COEF * 100))
      .div(100);
    return newLimit;
  }

  async getGasPrice(): Promise<EthersBigNumber> {
    if (this._web3) {
      const price = await this._web3.eth.getGasPrice();
      const newPrice = EthersBigNumber.from(price)
        .mul(Math.round(GAS_PRICE_COEF * 100))
        .div(100);
      return newPrice;
    }
    return Promise.reject("getGasPrice: not initialized");
  }
  // 0.00012759999999999998,
  async transferEth(amount: any, to?: string | undefined, needConvertToWei = true) {
    const toAddress = to ? to : SYSTEM_WALLET ?? "";
    const data = {
      from: this.account ?? "",
      to: toAddress,
      value: needConvertToWei ? Erc20Service.convertToWei(amount, Coins.ETH) : amount,
    };
    const gases = await Promise.all([this.getGasPrice(), this.estimateGas(data)]);
    const tx = {
      from: data.from,
      to: data.to,
      value: data.value,
      gasPrice: gases[0].toString(),
      gas: gases[1],
    };
    return await this._web3?.eth.sendTransaction(tx);
  }

  transferToken = async (tokenId: string, newOwner: string, collection: string, count = 1) => {
    const empty = "0x0000000000000000000000000000000000000000";
    const token = tokenId.includes(":")
      ? prepareTokenId(tokenId.split(":")[1])
      : prepareTokenId(tokenId);

    const contract = this.createContract(contractsAbi.MultiUserToken as AbiItem[], collection);
    const transfer = contract?.methods.safeTransferFrom(
      wallet.account,
      newOwner,
      token,
      count,
      empty
    );
    // .safeTransferFrom(wallet.account, newOwner, token, 1, "0x0000000000000000000000000000000000000000")
    /*
                const transfer = multi
              ? contract.methods.safeTransferFrom(from, newOwner, token, amount, empty)
              : contract.methods.transferFrom(from, newOwner, token);*/
    const gases = await Promise.all([
      this.getGasPrice(),
      this.getContractGasLimit(transfer, {
        from: wallet.account,
      }),
    ]);
    return await transfer.send({
      from: wallet.account,
      gasPrice: gases[0].toString(),
      gas: gases[1],
    });
  };

  transfer = async (to: string, amount: string, currency: string, needConvertToWei = true) => {
    const from = this.account;
    const contract = this.createContract(
      contractsAbi.ERC20 as AbiItem[],
      Erc20Service.getAddress(currency)
    );
    const transfer = contract?.methods.transfer(
      to,
      needConvertToWei ? Erc20Service.convertToWei(amount, currency) : amount
    );
    const gases = await Promise.all([
      this.getGasPrice(),
      this.getContractGasLimit(transfer, {
        from,
      }),
    ]);

    return await transfer.send({
      from,
      gasPrice: gases[0].toString(),
      gas: gases[1],
    });
  };

  transferMythToServer = async (amount: string) => {
    return this.transfer(MYTH_WALLET_PROXY, amount, "MYTH");
  };

  transferSoul = async (amount: string, curr = "SOUL") => {
    // для покупки СОУЛов и тля трансфера соулов от с Web3 на Системный кошелек
    return this.transfer(WALLET_FOR_BUY_SOUL, amount, curr);
  };

  async connect(wt: WalletType, onProperChain = true): Promise<string | Error | undefined> {
    _clearLocalStorage();
    const promise =
      wt === WalletType.METAMASK
        ? this.setMetamask()
        : wt === WalletType.FORTMATIC
        ? this.setFortmatic()
        : wt === WalletType.COIN_BASE
        ? this.setWalletLink()
        : wt === WalletType.WALLET_CONNECT
        ? this.setWalletConnect()
        : this.setMetamask();

    const r = await promise.catch((e) => {
      console.error("WALLET CONNECTION ERROR:", e);
      return false;
    });
    if (r === false) {
      return Error("Error while set provider");
    }
    this._type = wt;
    if (onProperChain && !this.properChain) {
      store()?.dispatch(
        show(CHANGE_NETWORK_MODAL, {
          cb: async (): Promise<Error | WalletType> => {
            try {
              await (this._provider as any).request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: Web3.utils.toHex(CHAIN_ID) }],
              });
              return wt;
            } catch (e) {
              console.error(e);
              return Error((e as any).message);
            }
          },
        })
      );
      return;
      // return Error("Wrong chain");
    }

    const account = this.accountOfAnyChain;
    if (!account) return Error("No wallet accounts open");
    return account;
  }

  async auth(): Promise<string | Error | undefined> {
    if (!this.account) return;
    const resp = await api.auth.nonce(this.account);
    if (isServerError(resp)) {
      console.error(resp);
      return resp;
    }
    const signature = await this.sign(resp.data.Nonce);
    const validationData = {
      address: this.account,
      nonce: resp.data.Nonce,
      signature,
    };
    const validationString = JSON.stringify(validationData);
    const vResp = await api.auth.validate(validationString);
    if (isServerError(vResp)) {
      return vResp;
    }
    return vResp.data.Token;
  }

  async addTokenToMetamask(cur: "MYTH" | "SOUL"): Promise<string | Error> {
    const currency = mockCurrencies.find((c) => c.Symbol === cur);
    try {
      const addedToken = await (this._provider as any).request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", // Initially only supports ERC20, but eventually more!
          options: {
            address: currency?.Address, // The address that the token is at.
            symbol: currency?.Symbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: 18, // The number of decimals in the token
            // image: tokenImage, // A string url of the token logo
          },
        },
      });

      if (addedToken) return "You added a ERC20 token";

      return Error("Faild to adding a token");
    } catch (e) {
      console.error(e);
      return e as Error;
    }
  }
}

const wallet = new Wallet();

export class WalletStateListener {
  private _listener?: (c: boolean) => void;

  constructor() {
    wallet.on("state", (c) => this.listener(c));
  }

  listener(c: boolean) {
    if (this._listener) this._listener(c);
  }

  set(listener: (c: boolean) => void) {
    this._listener = listener;
  }
}

export function createAccountChangeListener() {
  let cb: AccountsChandger | undefined = undefined;
  wallet.on("accountChanged", (accounts) => {
    if (cb) cb(accounts);
  });
  return (cb_: AccountsChandger) => {
    cb = cb_;
  };
}

export function createChainChangeListener() {
  let cb: ChainChandger | undefined = undefined;
  wallet.on("chainChanged", (changed) => {
    if (cb) cb(changed);
  });
  return (cb_: ChainChandger) => {
    cb = cb_;
  };
}

export function createDisconnectListener() {
  let cb: Disconnector | undefined = undefined;
  wallet.on("disconnected", (info) => {
    if (cb) cb(info);
  });
  return (cb_: Disconnector) => {
    cb = cb_;
  };
}

export default wallet;
