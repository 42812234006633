export const UINT_PREFIX = "0x";

export const getRSV = (signature: string) => {
  const [r, s, v] = [
    UINT_PREFIX + signature.slice(0, 64),
    UINT_PREFIX + signature.slice(64, 128),
    UINT_PREFIX + signature.slice(128, 130),
  ];
  return { r, s, v };
};
