import { Coins } from "../constants";
import { Me } from "./hooks/useMe";
import { BigNumber } from "ethers";

export default function isEnoughBalance(amountInWei: any, coinName: Coins, me: Me) {
  const amountNumber = BigNumber.from(amountInWei);
  // console.log("IS ENOUGH?", amountInWei, me);
  switch (coinName) {
    case Coins.ETH:
      return amountNumber.lte(me.ethBalance);
    case Coins.MYTH:
      return amountNumber.lte(me.mythBalance);
    case Coins.WETH:
      return amountNumber.lte(me.wethBalance);
    case Coins.USDT:
      return amountNumber.lte(me.usdtBalance);
    default:
      return false;
  }
}
