import { MouseEvent } from "react";
import { GAME_GOD_MODAL } from "../../../../../utils/modalGameNames";
import WithGameModal, { ModalGameProps } from "../../../../HOC/GameHOC/withGameModal";
import styles from "./GodModal.module.scss";
import GameBtn from "../../../GameFolder/GameBtn/GameBtn";
import { BtnType } from "../../../GameFolder/GameBtn/GameBtn.props";
import makeImagePath from "../../../../../utils/makeImagePath";
import uniqString from "../../../../../utils/uniqString";
import { useGodModalControl } from "./useGodModalControl";
import {
  MyGodInfo,
  StorageTypeBtn,
  StorageTypeE,
  StorageTypeString,
} from "../../../../../services/api/gods";
import GameLoader from "../../../GameFolder/GameLoader/GameLoader";

interface GodModalPropsI extends ModalGameProps, MyGodInfo {}

function GodModal(props: GodModalPropsI) {
  const control = useGodModalControl(props);
  const transferToSystemOrMyWallet = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (props.StorageType === StorageTypeE.GOD_STORAGE_SYSTEM) {
      //StorageType.GOD_STORAGE_SYSTEM
      control.tranfserToMyWallet(props.TokenID, 1);
      return;
    }
    if (
      props.StorageType == StorageTypeE.GOD_STORAGE_USER ||
      props.StorageType == StorageTypeE.GOD_STORAGE_SELL_DIGICOL
    ) {
      // GOD_STORAGE_USER = 1
      control.tranfserToSystemWallet(props.TokenID, props.Collection, 1, props.SystemWallet);
      return;
    }
  };
  return (
    <div className={styles.wrapper}>
      {control.loading && <GameLoader global style={{ width: "100%", height: "100%" }} />}
      <div className={styles.img}>
        <img src={makeImagePath(props.Image)} />
      </div>
      <div className={styles.descr}>
        <div className={styles.top}>
          <h3> {props.Name} </h3>
          <h4>
            {uniqString(props.Uniqueness)} Level {props.Level}
          </h4>
          <p> {props.FullDesc} </p>
        </div>
        <div className={styles.bottom}>
          <span> {StorageTypeString[props.StorageType]} </span>
          <GameBtn type={BtnType.DARK} onClick={transferToSystemOrMyWallet}>
            {StorageTypeBtn[props.StorageType]}
          </GameBtn>
        </div>
      </div>
    </div>
  );
}
export default WithGameModal(GAME_GOD_MODAL, GodModal, { godModal: true });
