import { GAME_BUY_SOUL } from "../../../../../utils/modalGameNames";
import WithGameModal from "../../../../HOC/GameHOC/withGameModal";
import cn from "classnames";
import styles from "./BuySoulModal.module.scss";
import { ModalHeader } from "../GameModal";
import GameBtn from "../../../GameFolder/GameBtn/GameBtn";
import GameSelectorValue from "../../../GameFolder/GameSelectorValue/GameSelectorValue";
import { useBuySoulControl } from "./useBuySoulControl";

import { ReactComponent as Arrow } from "../../../../../assets/Icons/game/arrow.svg";
import GameLoader from "../../../GameFolder/GameLoader/GameLoader";

function BuySoulModal() {
  const control = useBuySoulControl();
  return (
    <>
      {(!control.exRate || control.isLoading) && <GameLoader global wh />}

      <ModalHeader
        title='Buy SOUL Token for System Wallet'
        subtitle='Max. 20,000 SOUL per buying'
      />
      <div className={styles.wrapper}>
        <GameSelectorValue
          from
          balance={control.me?.mythBalance || "0"}
          options={[control.options[0]]}
          // getSelectorValue={control.setSelectorFrom}
          getSelectorValue={() => null}
          value={control.valueFrom}
          selectorValue={control.selectorFrom}
          getValue={control.setValueFrom}
          isError={!control.isEnough}
        />
        <Arrow className={styles.icon} />
        <GameSelectorValue
          balance={control.gameProf.soulBalance}
          options={[control.options[1]]}
          getSelectorValue={() => null}
          // getSelectorValue={control.setSelectorTo}
          value={control.valueTo}
          selectorValue={control.selectorTo}
          getValue={control.setValueTo}
        />
      </div>
      <div className={styles.bottom_wrapper}>
        <span> {control.exRate ? control.exRate : "Loading..."} </span>
        <GameBtn
          className={cn(styles.btn, {
            ["disabled"]: !control.isEnough,
          })}
          onClick={control.onSwap}
          disabled={!control.isEnough}
        >
          {!control.isEnough && <span> Not Enough </span>}
          SWAP
        </GameBtn>
      </div>
    </>
  );
}

export default WithGameModal(GAME_BUY_SOUL, BuySoulModal);
