import { CopyToClipboard } from "react-copy-to-clipboard";
import { ReactComponent as Copy } from "../../../../assets/Icons/game/Copy.svg";
import cn from "classnames";
import styles from "./GameCopy.module.scss";
import { GameCopyPropsI } from "./GameCopy.props";
import { Toasts } from "../../../../utils/toasts";

export default function GameCopy({ string, dark }: GameCopyPropsI): JSX.Element {
  const onClick = () => Toasts.Success("Copied!");
  return (
    <CopyToClipboard text={string}>
      <Copy
        className={cn(styles.icon, {
          [styles.dark]: dark,
        })}
        onClick={onClick}
      />
    </CopyToClipboard>
  );
}
