import MultiToken from "./MultiToken.json";
import MultiUserToken from "./MultiUserToken.json";
import ERC20 from "./WETH.json"; //FIXME: true erc20
import ExchangeV1 from "./ExchangeV1.json";

export default {
  MultiToken,
  MultiUserToken,
  ERC20,
  ExchangeV1,
};
