import { ReactNode, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { Toasts } from "../../../utils/toasts";
import FStepsModal2 from "../../global/modals/FStepsModal2/FStepsModal2";
import BattleModeModal from "../../global/modals/GameFolder/BattleModeModal/BattleModeModal";
import BattleResultModal from "../../global/modals/GameFolder/BattleResultModal/BattleResultModal";
import BuySoulModal from "../../global/modals/GameFolder/BuySoulModal/BuySoulModal";
import CancelBattleModal from "../../global/modals/GameFolder/CancelBattleModal/CancelBattleModal";
import ConfirmModal from "../../global/modals/GameFolder/ConfirmModal/ConfirmModal";
import EditNameModal from "../../global/modals/GameFolder/EditNameModal/EditNameModal";
import FreeBattleModal from "../../global/modals/GameFolder/FreeBattleModal/FreeBattleModal";
import GameChallengeModal from "../../global/modals/GameFolder/GameChallengeModal/GameChallengeModal";
import GameConnectModal from "../../global/modals/GameFolder/GameConnectModal/GameConnectModal";
import GameOops from "../../global/modals/GameFolder/GameOops/GameOops";
import GameShareModal from "../../global/modals/GameFolder/GameShareModal/GameShareModal";
import GodModal from "../../global/modals/GameFolder/GodModal/GodModal";
import HowToPlay from "../../global/modals/GameFolder/HowToPlay/HowToPlay";
import SoulBattleModal from "../../global/modals/GameFolder/SoulBattleModal/SoulBattleModal";
import TransferSoulModal from "../../global/modals/GameFolder/TransferSoulModal/TransferSoulModal";
import GameHeader from "./GameHeader";
import styles from "./GameLayout.module.scss";
import ChangeNetworkModal from "../../global/modals/ChangeNetworkModal/ChangeNetworkModal";
import GameResultModal_new from "../../global/modals/GameFolder/GameResultModal_new/GameResultModal_new";

interface GameLayoutI {
  children: ReactNode;
  src: string;
  isVideo: boolean;
}

export default function GameLayout({ children, src, isVideo }: GameLayoutI) {
  const vid = document.getElementsByTagName("video")[0];
  useEffect(() => {
    if (vid) vid.volume = 0.1;
  }, [vid]);

  return (
    <>
      <ToastContainer theme='dark' position='top-center' />

      <GameConnectModal />
      <HowToPlay />
      <BattleModeModal />
      <SoulBattleModal />
      <FreeBattleModal />
      <EditNameModal />
      <GodModal />
      <BuySoulModal />
      <TransferSoulModal />
      <ConfirmModal />
      <CancelBattleModal />
      <GameShareModal />
      <GameChallengeModal />
      <GameOops />
      <BattleResultModal />
      <FStepsModal2 />
      <GameResultModal_new />
      <ChangeNetworkModal />

      <div className={styles.component_wrapper}>
        <GameHeader />
        <div className={styles.bg_wrapper} style={{ background: `url(${src})` }}>
          {isVideo && <video loop autoPlay playsInline preload='auto' src={src} />}
          {/* <img src={bg_img} alt='godtemple game' /> */}
          {/* <GameFooter gradient /> */}
        </div>
        <main className={styles.component}>{children}</main>
        {/* <GameFooter footer /> */}
      </div>
    </>
  );
}
