import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { hide } from "redux-modal";
import { changeUserName } from "../../../../../services/api/game/gameName";
import { isServerError } from "../../../../../services/api/setting";
import { updateUserData } from "../../../../../store/me/actions";
import { GameToasts } from "../../../../../utils/gameToasts";
import { useGameProf } from "../../../../../utils/hooks/gameHooks/useGameProf";
import useMe, { Me } from "../../../../../utils/hooks/useMe";
import { GAME_EDIT_NAME_MODAL } from "../../../../../utils/modalGameNames";
import { AppContext, SoundTypeE } from "../../../../HOC/GameHOC/AppContextProvider";

interface EditNameControlI {
  readonly name: string;
  readonly loading: boolean;
  readonly me?: Me;

  setName(name: string): void;
  onChangeName(): void;
}

export const useEditNameControl = (): EditNameControlI => {
  const gameProf = useGameProf();
  const me = useMe();
  const dispatch = useDispatch();
  const [name, setName] = useState<string>(gameProf.GameName);
  const [loading, setLoading] = useState<boolean>(false);
  const { playSound } = useContext(AppContext);

  useEffect(() => {
    playSound(SoundTypeE.SMALL);
  }, []);

  const onChangeName = async () => {
    setLoading(true);
    const res = await changeUserName(name);
    if (!isServerError(res)) {
      dispatch(hide(GAME_EDIT_NAME_MODAL));
      GameToasts.Success(`You successfully changed your gaming name`);
      dispatch(updateUserData());
      setLoading(false);
    }
  };

  return {
    name,
    loading,
    me,

    setName,
    onChangeName,
  };
};
