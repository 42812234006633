import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../HOC/GameHOC/AppContextProvider";
import styles from "./GameLoaderComponent.module.scss";

export default function GameLoaderComponent() {
  const { setIsLoading } = useContext(AppContext);
  return (
    <div className={styles.wrapper}>
      <h4>Loading</h4>
      <div className={styles.progress}>
        <div
        // onAnimationEnd={() => setIsLoading(false)}
        />
      </div>
    </div>
  );
}
