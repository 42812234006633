import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  getTransferFee,
  MyGodInfo,
  StorageTypeE,
  transferToMyWallet,
} from "../../../../../services/api/gods";
import wallet from "../../../../../services/wallet";
import asyncExecute from "../../../../../utils/asyncExecute";
import isEnoughBalance from "../../../../../utils/isEnoughBalance";
import { Toasts } from "../../../../../utils/toasts";
import Erc20Service from "../../../../../services/bch/erc20";
import { Coins } from "../../../../../constants";
import useMe from "../../../../../utils/hooks/useMe";
import { FStepsModal2Props, StepCode } from "../../FStepsModal2/FStepsModal2";
import { hide, show } from "redux-modal";
import { FOLLOW_STEPS_MODAL_2 } from "../../../../../utils/modalNames";
import { GAME_GOD_MODAL } from "../../../../../utils/modalGameNames";

interface GodModalControlI {
  readonly loading: boolean;

  tranfserToSystemWallet(
    tokenId: string,
    collection: string,
    count: number,
    systemWallet: string
  ): void;
  tranfserToMyWallet(tokenId: string, count: number): void;
}

export const useGodModalControl = (token: MyGodInfo): GodModalControlI => {
  const me = useMe();
  const dispatch = useDispatch();
  const [transferEnded, setTransferEnded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  async function tranfserToSystemWallet() {
    const res = await new Promise<any>((done) => {
      let steps = [StepCode.TransferToSystem];
      if (token.StorageType === StorageTypeE.GOD_STORAGE_SELL_DIGICOL) {
        steps = [StepCode.UnlistFromSale, ...steps];
      }
      const modalProps: FStepsModal2Props = {
        done,
        god: token,
        title: "Transfer to System wallet",
        steps,
      };
      dispatch(hide(GAME_GOD_MODAL));
      dispatch(show(FOLLOW_STEPS_MODAL_2, modalProps));
    });
    if (typeof res !== "boolean" || !res) return;
    startReload();
  }

  const startReload = () => setTimeout(() => window.location.reload(), 500);

  async function tranfserToMyWallet(tokenId: string, count: number) {
    if (!me) return console.error("ME not found ", me);

    setLoading(true);
    try {
      const getFeeRes = await asyncExecute(() => getTransferFee(tokenId, count));
      if (getFeeRes.Fee < 0) {
        throw Error(`${getFeeRes.Available} tokens available for transfer`);
      }
      if (!isEnoughBalance(Erc20Service.convertToWei(getFeeRes.Fee, Coins.ETH), Coins.ETH, me)) {
        return Error("Insufficient funds. Top up your wallet and try again.");
      }
      const transferEthRes = await wallet.transferEth(getFeeRes.Fee, getFeeRes.Address);
      if (transferEthRes && transferEthRes.status) {
        const transferRes = await asyncExecute(() =>
          transferToMyWallet(tokenId, count, transferEthRes.transactionHash, getFeeRes.Fee, true)
        );
        if (transferRes.Status === "success") {
          setTransferEnded(true);
          startReload();
        }
      } else {
        console.error("SOME ERROR in === wallet.transferEth");
      }
    } catch (e) {
      console.error(e);
      Toasts.error(e);
    } finally {
      setLoading(false);
    }
  }
  return {
    loading,

    tranfserToSystemWallet,
    tranfserToMyWallet,
  };
};
