import GameNotification from "../../../GameFolder/GameNotification/GameNotification";
import styles from "./GameNotificationsBlock.module.scss";
import { ReactComponent as NotifWhiteArrow } from "../../../../../assets/Icons/game/notifWhiteArrow.svg";
import { GameNotificationsBlockPropsI } from "./GameNotificationsBlock.props";

export default function GameNotificationsBlock({
  notifications: notififactions,

  onClose,
  onOpenAllNotif,
}: GameNotificationsBlockPropsI) {
  const isEmpty = notififactions.length === 0;

  return (
    <>
      <div className={styles.wrapper}>
        <NotifWhiteArrow className={styles.arrow} />

        {isEmpty ? (
          <p className={styles.empty}>No notifications</p>
        ) : (
          notififactions
            .slice(0, 3)
            .map((item) => (
              <GameNotification
                className={styles.notif}
                notif={item}
                key={item.RoomId}
                onCloseBlock={onClose}
              />
            ))
        )}

        {!isEmpty && (
          <a type='button' className={styles.all_btn} onClick={onOpenAllNotif}>
            See all
          </a>
        )}
      </div>
    </>
  );
}
