import styles from "./GodImage.module.scss";
import cn from "classnames";
import { GodImageProps } from "./GodImage.props";
import { useState } from "react";
import Skeleton from "../Skeleton/Skeleton";
import Loader from "../Loader/Loader";
import Lightbox from "react-image-lightbox";

export default function GodImage({
  name,
  placeTag,
  img,
  alt = "godtemple",
  className,
  type,
  isLoading = false,
  zoomable = false,
  count = -1,
  small = false,
  wide,
  ...props
}: GodImageProps): JSX.Element {
  const [loadImg, setLoadImg] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div
      className={cn(styles.temple_img, "temple__img", className, {
        // [styles.temple_img__multi]: count > 0,
      })}
      {...props}
    >
      <div
        className={cn(styles.temple_img__container, {
          // [styles.temple_img__container__multi]: count > 0,
        })}
      >
        {!loadImg && <Skeleton type={type} godToken style={{ width: "100%" }} />}
        <img
          onClick={() => setIsOpen(true)}
          src={img}
          alt={name || alt || "godtemple"}
          onLoad={() => setLoadImg(true)}
          hidden={!loadImg}
          className={cn({
            [styles.img__multi]: count > 0,
            [styles.img__small]: !!small,
          })}
        />
        {zoomable && isOpen && <Lightbox mainSrc={img} onCloseRequest={() => setIsOpen(false)} />}
        {isLoading && <Loader isAbsolute={true} />}
        {!!placeTag && <span className={cn("temple__format", styles.placeTag)}>{placeTag}</span>}
      </div>
      {!wide && count > 0 && <div className={styles.count}>x{count}</div>}
    </div>
  );
}
