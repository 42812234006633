import cn from "classnames";
import styles from "./GameGodCard.module.scss";
import { GameGodCardPropsI } from "./GameGodCard.props";
import GameBtn from "../GameBtn/GameBtn";
import { BtnType } from "../GameBtn/GameBtn.props";
import GameImage from "../GameImage/GameImage";

import { useGameGodCardControl } from "./useGameGodCardControl";
import { memo, useRef } from "react";
import makeImagePath from "../../../../utils/makeImagePath";
import uniqString from "../../../../utils/uniqString";
import { StorageTypeString } from "../../../../services/api/gods";
import UniquenessE from "../../../../utils/uniqueness";

function GameGodCard({
  battle,
  canNotFgt,
  className,
  selectGod,
  ...props
}: GameGodCardPropsI): JSX.Element {
  const {
    Collection,
    FullDesc,
    Group,
    Image,
    Level,
    Name,
    PackItemId,
    ShortDesc,
    Temple,
    TokenID,
    Type,
    Uniqueness,
    StorageType = 0,
  } = props;
  const control = useGameGodCardControl(props, selectGod, battle);

  const ref = useRef<HTMLDivElement>(null);

  return (
    <div className={cn(styles.card_wrapper, className)}>
      <div
        className={cn(styles.img_wrapper, {
          [styles.canNotFgt]: canNotFgt,
        })}
        onClick={() => {
          control.onActivate();
          ref.current?.blur();
        }}
        onKeyDown={control.onEnter}
        onMouseEnter={control.onHover}
        onFocus={control.onHover}
        tabIndex={0}
        ref={ref}
      >
        <GameImage src={makeImagePath(Image)} className={cn(styles.img)} />
        {battle && (
          <GameBtn
            type={BtnType.LIGHT}
            bigBtn
            className={styles.battleBtn}
            onClick={control.onSelect}
          >
            Battle
          </GameBtn>
        )}
      </div>
      <div className={styles.card_descr}>
        <h4> {Name}</h4>
        <p>
          <span
            className={cn(styles.uniq, {
              [styles.basic]: Uniqueness === UniquenessE.Common,
              [styles.rare]: Uniqueness === UniquenessE.Rare,
              [styles.myth]: Uniqueness === UniquenessE.Mythical,
            })}
          >
            {uniqString(Uniqueness)}
          </span>{" "}
          Level {Level}
        </p>
      </div>

      <div className={styles.status}>{StorageTypeString[StorageType]}</div>
    </div>
  );
}

export default memo(GameGodCard);
