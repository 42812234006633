import { memo, useEffect, useRef } from "react";
import { GameDropdownPropsI } from "./GameDropdown.props";
import cn from "classnames";
import styles from "./GameDropdown.module.scss";

import { ReactComponent as Select_arrow } from "../../../../assets/Icons/game/select_arrow.svg";
import { useControlGameDropdown } from "./useControlGameDropdown";

function GameDropdown({
  options,
  value,
  getValue,
  className,
  ...pros
}: GameDropdownPropsI): JSX.Element {
  const r = useRef<any>();
  const control = useControlGameDropdown();

  useEffect(() => {
    document.addEventListener("click", function (e) {
      if (e.target !== r.current) control.setOpenDropdown(false);
    });
    return () => document.removeEventListener("click", () => null);
  }, []);

  return (
    <div
      className={cn(styles.dropdown_wrapper, className, {
        [styles.openDrop]: control.openDropdown,
      })}
      onClick={() => control.setOpenDropdown(!control.openDropdown)}
      ref={r}
      {...pros}
    >
      {value.name}
      {options.length > 1 && (
        <Select_arrow
          className={cn({
            [styles.active]: control.openDropdown,
          })}
        />
      )}
      <div
        className={cn(styles.dropdawn_options, {
          [styles.openDrop]: options.length > 1 && control.openDropdown,
        })}
      >
        {options.map((item, i) => (
          <span onClick={() => getValue(item)} key={i}>
            {item.name}
          </span>
        ))}
      </div>
    </div>
  );
}
export default memo(GameDropdown);
