import { FunctionComponent, PropsWithChildren } from "react";
import Modal from "../global/modals/Modal";
import { connectModal, InjectedProps } from "redux-modal";
import { useDispatch } from "react-redux";
import { show, hide } from "redux-modal";

export interface ModalProps extends PropsWithChildren<InjectedProps> {
  modalClose: () => void;
  modalOpen: () => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function WithModal<T extends PropsWithChildren<InjectedProps>>(
  name: string,
  Component: FunctionComponent<T>,
  params?: {
    noPanel?: boolean;
    confirmClose?: boolean;
  }
) {
  function withModalComponent(props: T) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dispatch = useDispatch();

    return (
      <Modal name={name} noPanel={params?.noPanel} confirmClose={params?.confirmClose}>
        <Component
          modalClose={() => dispatch(hide(name))}
          modalOpen={() => dispatch(show(name))}
          {...props}
        />
      </Modal>
    );
  }

  return connectModal({
    name,
    getModalState: (state) => state.modals,
  })(withModalComponent);
}
