import { Suspense } from "react";
import { FunctionComponent } from "react";
import GameLoader from "../components/global/GameFolder/GameLoader/GameLoader";
import GameLoaderComponent from "../components/global/GameFolder/GameLoaderComponent/GameLoaderComponent";
import Loader from "../components/global/Loader/Loader";

export const SuspendComponent = (
  Component: FunctionComponent<any>,
  params?: { game: boolean }
): JSX.Element => (
  <Suspense fallback={params && params.game ? <GameLoader global /> : <Loader global />}>
    <Component />
  </Suspense>
);
