import cn from "classnames";
import React, { useContext, useEffect, useRef } from "react";
import { AppContext, SoundTypeE } from "../../../HOC/GameHOC/AppContextProvider";
import styles from "./GameBtn.module.scss";
import { BtnType, GameBtnPropsI } from "./GameBtn.props";

export default function GameBtn({
  type,
  children,
  className,
  disabled,
  bigBtn = false,
  onClick,
  ...props
}: GameBtnPropsI): JSX.Element {
  const { playSound } = useContext(AppContext);
  const ref = useRef<HTMLButtonElement>(null);

  return (
    <button
      className={cn(styles.btn, className, {
        [styles.dark]: type === BtnType.DARK,
        [styles.light]: type === BtnType.LIGHT,
        [styles.connect]: type === BtnType.CONNECT,
        [styles.room]: type === BtnType.ROOM,
        ["disabled"]: disabled,
      })}
      disabled={disabled}
      onMouseEnter={() => playSound(SoundTypeE.HOVER)}
      onFocus={() => playSound(SoundTypeE.HOVER)}
      tabIndex={0}
      ref={ref}
      onClick={(e: React.MouseEvent) => {
        ref.current?.blur();
        onClick && setTimeout(() => onClick(e), 150);
        playSound(bigBtn ? SoundTypeE.LARGE : SoundTypeE.SMALL);
      }}
      {...props}
    >
      {children}
    </button>
  );
}
