import { AxiosResponse } from "axios";
import Uniqueness from "../../utils/uniqueness";
import service from "./service";
import { PossiblyError, ServerResponse } from "./setting";

export interface GodInfo {
  Collection: string;
  TokenID: string;
  Name: string;
  Image: string;
  ShortDesc: string;
  FullDesc: string;
  UpgradePrice: string;
  UpgradeCurrency: string;
  Level: 1 | 2 | 3;
  Type: string;
  Artist: string;
  ArtistImage: string;
  Etherscan: string;
  Ipfs: string;
  Metadata: string;
  Royalty: number;
}
export interface GroupResponse extends ServerResponse {
  Records: GodInfo[];
}

export function group(group: string): Promise<AxiosResponse<GroupResponse> | Error> {
  return service.api.post("/gt/gods/group", { Group: group });
}

export function group2(tokenID: string): Promise<AxiosResponse<GroupResponse> | Error> {
  return service.api.post("/gt/gods/group2", { TokenID: tokenID });
}

export enum StorageTypeE {
  GOD_STORAGE_SYSTEM = 0,
  GOD_STORAGE_USER = 1,
  GOD_STORAGE_IN_PROCESS = 2,
  GOD_STORAGE_SELL_DIGICOL = 3,
  GOD_STORAGE_IN_GAME = 4,
  GOD_STORAGE_UPGRADING = 5,
}

export const StorageTypeString = [
  "On the system wallet",
  "On the web3 wallet",
  "Transfer in progress...",
  "On the Digicol market",
  "God in game",
  "God is upgrading",
];

export const StorageTypeBtn = {
  [StorageTypeE.GOD_STORAGE_SYSTEM]: "Transfer to Web3 Wallet",
  [StorageTypeE.GOD_STORAGE_USER]: "Transfer to System Wallet",
  [StorageTypeE.GOD_STORAGE_SELL_DIGICOL]: "Transfer to System Wallet",
  [StorageTypeE.GOD_STORAGE_IN_PROCESS]: "Transfer in progress...",
  [StorageTypeE.GOD_STORAGE_IN_GAME]: "God in game",
  [StorageTypeE.GOD_STORAGE_UPGRADING]: "God is upgrading",
};

export interface GodStakingInfo {
  TokenID: string;
  Collection: string;
  Note: number;
  Staked: number;
  StakingDuration: number;
  StakingDaysLeft: number;
  StakingStart: number;
  StakingFinish: number;
  StakingProfit: string;
  StakingAutoRenew: boolean;
}

export interface MyGodInfo extends GodStakingInfo {
  Name: string;
  Image: string;
  Level: 1 | 2 | 3;
  Type: string;
  ShortDesc: string;
  FullDesc: string;
  Group: string;
  ForSale: boolean; // пока не учитывайте, всегда 0
  StakingAvailable: boolean;
  StorageType: StorageTypeE; // (0 - на системном кошельке, 1 - на кошельке юзера, 2 - в процессе трансфера от системы к юзеру)
  Uniqueness?: number;
  SystemWallet: string; //адрес системного кошелька для данного бога (куда слать для хранения системой)
  DataExported: boolean; //экспортированы ли данные на digicol, должно быть всегда (!) true. Если нет то что-то не то на сервере
  Royalty: number;
  Count?: number;
  Upgradeable: boolean;
}

export interface MyGroupResponse extends ServerResponse {
  Records: MyGodInfo[];
  Total: number;
}

export function my(offset = 0, limit = 20): Promise<AxiosResponse<MyGroupResponse> | Error> {
  return service.api.post("/gt/gods/my", { offset, limit });
}

enum QueryKind {
  ANY = 0,
  STAKING_AVAILABLE,
  STAKING,
}

export function myStaking(
  staking: boolean,
  offset = 0,
  limit = 20
): Promise<AxiosResponse<MyGroupResponse> | Error> {
  return service.api.post("/gt/gods/my", {
    offset,
    limit,
    kind: staking ? QueryKind.STAKING : QueryKind.STAKING_AVAILABLE,
  });
}

export interface TransferToMyWalletResponse extends ServerResponse {
  Fee: number; //если <0 то недостаточно токенов и сколько есть нужно смотреть в Available
  Address: string; //адрес на который переводить комиссию (на случай поддержки нескольких системных аккаунтов)
  Available?: number;
  GodsCount?: number;
}

export function getTransferFee(
  tokenId: string,
  count: number
): Promise<AxiosResponse<TransferToMyWalletResponse> | Error> {
  return service.api.post("/gt/gods/touserfee", {
    TokenId: tokenId,
    Count: count,
  });
}

export function transferToMyWallet(
  tokenId: string,
  count: number,
  Tx: string,
  Fee: number,
  wait?: boolean
): Promise<AxiosResponse<TransferToMyWalletResponse> | Error> {
  return service.api.post("/gt/gods/touser", {
    TokenId: tokenId,
    Count: count,
    Tx,
    Fee,
    Wait: !!wait,
  });
}

export function transferToSystemWallet(
  tokenId: string,
  count: number,
  tx: string
): Promise<AxiosResponse<ServerResponse> | Error> {
  return service.api.post("/gt/gods/fromuser", {
    TokenId: tokenId,
    Count: count,
    Tx: tx,
  });
}

export interface GodStakingParams {
  Duration: number;
  Profit: string;
}

export interface StakingInfoResponse extends ServerResponse {
  Info: GodStakingParams;
}

export function getStakingParams(
  tokenID: string,
  collection: string
): Promise<AxiosResponse<StakingInfoResponse> | Error> {
  return service.api.get(`/gt/gods/stakinginfo?tokenid=${tokenID}&collection=${collection}`);
}

export interface NewStakingInfo {
  ID: number;
  Duration: number;
  Start: number;
  Finish: number;
  Profit: string;
  AutoRenew: boolean;
}

export interface NewStakingResponse extends ServerResponse {
  Info: NewStakingInfo;
}

export function stakeGod(
  tokenID: string,
  collection: string,
  note: number,
  autoRenew: boolean
): Promise<AxiosResponse<NewStakingResponse> | Error> {
  return service.api.post("/gt/gods/stake", {
    tokenid: tokenID,
    collection,
    note,
    autorenew: autoRenew,
  });
}

export function stopStaking(staking: number): Promise<PossiblyError> {
  return service.api.post("/gt/stakings/finish", { staking });
}

export enum StakingEvent {
  ACCRUAL = 0,
  COMMISSION,
  WITHDRAW,
}

export interface StakingHistoryRow {
  Date: number;
  Event: StakingEvent;
  Amount: number;
}

export interface StakingHistoryResponse extends ServerResponse {
  Total: number;
  Records: StakingHistoryRow[];
}

export function stakingHistory(
  offset: number,
  limit = 20
): Promise<AxiosResponse<StakingHistoryResponse> | Error> {
  return service.api.get(`/gt/stakings/history?offset=${offset}&limit=${limit}`);
}

export interface StakableGodInfo {
  Collection: string;
  TokenID: string;
  Group: string;
  Name: string;
  Image: string;
  Level: 1 | 2 | 3;
  Type: string;
  ShortDesc: string;
  FullDesc: string;
  Uniqueness: Uniqueness;
}

export interface StakableGodsResponse extends ServerResponse {
  Total: number;
  Records: AwailableForStakingI[];
}

export interface AwailableForStakingI {
  Collection: string;
  TokenID: string;
  Group: string;
  Profit: string;
  Name: string;
  Level: 1 | 2 | 3;
  Uniqueness: Uniqueness;
  Duration: number; // of days
  Image: string;
  TempleBg: string;
  TempleCreator: string;
  TempleID: number;
  TempleName: string;
}

export function stakableGods(
  offset: number,
  limit = 20
): Promise<AxiosResponse<StakableGodsResponse> | Error> {
  return service.api.get("/gt/gods/stakable", { params: { offset, limit } });
}

export interface MyGameGod {
  Collection: string;
  FullDesc: string;
  Group: string;
  Image: string;
  Level: number;
  Name: string;
  PackItemId: number;
  ShortDesc: string;
  Temple: string;
  TokenID: string;
  Type: string;
  Uniqueness: Uniqueness;
  StorageType?: StorageTypeE;
  SystemWallet: string;
}

export interface UserGameGodsResponse extends ServerResponse {
  Records: MyGameGod[];
  Total: number;
}

export enum TypeFE {
  ALL,
  BASIC,
  RARE,
  MYTH,
}
export enum WalletFE {
  ALL,
  SYS,
  WEB3,
}
export enum LevelFE {
  ALL,
  L1,
  L2,
  L3,
}

interface FilterI {
  // type 0-все, 1-basic,2-rare,3-myth, wallet 0-все, 1-sys,2-web3, level 0-все
  type: TypeFE;
  wallet: WalletFE;
  level: LevelFE;
  search?: string;
  offset?: number;
  limit?: number;
}

export function gameGods({
  type,
  wallet,
  level,
  search = "",
  offset = 0,
  limit = 20,
}: FilterI): Promise<AxiosResponse<UserGameGodsResponse> | Error> {
  return service.api.post("/gt/gods/my", {
    search,
    type,
    wallet,
    level,
    forGame: true,
    offset,
    limit,
  });
}

export interface OnSaleGodResponse extends ServerResponse {
  Records: MyGodInfo[];
}

export function getOnSaleGod(
  tokenID: string,
  coll: string
): Promise<AxiosResponse<OnSaleGodResponse> | Error> {
  return service.api.get("/gt/gods/onsalegod", {
    params: { tokenid: tokenID, collection: coll },
  });
}

export interface CheckUpgradeResponse extends ServerResponse {
  Available: boolean;
  Price: string;
  Coin: "MYTH" | "USDT" | "ETH";
  Address: string;
  UpgradeID: number;
}

export function checkUpgrade(
  tokenID: string,
  collection: string,
  note: number
): Promise<AxiosResponse<CheckUpgradeResponse> | Error> {
  return service.api.post("/gt/gods/checkupgrade", {
    tokenid: tokenID,
    collection,
    note,
  });
}

export function upgrade(upgradeID: number, tx: string): Promise<PossiblyError> {
  return service.api.post("/gt/gods/upgrade", {
    upgradeid: upgradeID,
    tx,
  });
}

export function abortUpgrade(upgradeID: number): Promise<PossiblyError> {
  return service.api.post("/gt/gods/abortupgrade", {
    upgradeid: upgradeID,
  });
}

export interface UpgradeBaseInfo {
  UpName: string;
  UpPrice: string;
  UpFee: string;
  UpFullPrice: string;
  UpCoin: string;
  UpImage: string;
  UpLevel: number;
  UpShortDesc: string;
  Supply: number;
}

export interface UpgradeInfo extends MyGodInfo, UpgradeBaseInfo {
  Whenopened: number;
}

export interface UpgradeInfoResponse extends ServerResponse {
  Info: UpgradeInfo;
}

export function upgradeInfo(
  tokenID: string,
  collection: string,
  note: number
): Promise<AxiosResponse<UpgradeInfoResponse> | Error> {
  return service.api.get("/gt/gods/upgradeinfo", {
    params: {
      tokenid: tokenID,
      collection,
      note,
    },
  });
}
