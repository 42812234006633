import { render } from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { initStore } from "./store";
import { Store } from "redux";

import "./styles/globals.scss";

const start = async () => {
  const store: Store = await initStore();
  return render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById("root")
  );
};
start();
// if (persistor) {
//   persistor.then(start);
// }
// else {
//   start();
// }

// import React, { useEffect, useState } from "react";
// import { render } from "react-dom";
// import "./index.css";
// import App from "./App";
// import { Provider } from "react-redux";
// import { initStore } from "./store";
// import { Store } from "redux";

// import "./styles/globals.scss";

// function Index(): void {
//   const [store, setStore] = useState<Store>();

//   useEffect(() => {
//     (async () => {
//       const s = await initStore();
//       setStore(s);
//     })();
//   }, []);

//   // eslint-disable-next-line no-extra-boolean-cast
//   return !!store
//     ? render(
//         <Provider store={store}>
//           <App />
//         </Provider>,
//         document.getElementById("root")
//       )
//     : render(<></>, document.getElementById("root"));
// }
// Index();
