import React from "react";
import Skeleton from "../../Skeleton";
import styles from "./SkeletonGameCard.module.scss";

export default function SkeletonGameCard() {
  return (
    <>
      {Array.from(new Array(6)).map((item, key) => (
        <div className={styles.wrapper} key={key}>
          <Skeleton className={styles.img} />
          <Skeleton type='text' style={{ width: 100 }} />
          <Skeleton type='text' style={{ width: "80%" }} />
          <Skeleton type='text' style={{ width: "90%" }} />
        </div>
      ))}
    </>
  );
}
