import styles from "./GameLoader.module.scss";
import cn from "classnames";
import { DetailedHTMLProps, HTMLAttributes } from "react";
import { RingLoader } from "react-spinners";
import { css } from "@emotion/react";

interface LoaderGamePropsI
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  global?: boolean;
  size?: number;
  isAbsolute?: boolean;
  white?: boolean;
  wh?: boolean;
  color?: string;
}

export default function GameLoader({
  global,
  size = 100,
  isAbsolute = false,
  white = false,
  wh = false,
  color = "#000",
  ...props
}: LoaderGamePropsI) {
  const override = white
    ? css`
        border-color: #fff;
      `
    : css`
        border-color: #000;
      `;
  return (
    <div
      className={cn(styles.wrapper, {
        [styles.global]: global,
        [styles.isAbsolute]: isAbsolute,
        [styles.wh]: wh,
      })}
      {...props}
    >
      <RingLoader color={color} css={override} size={size} />
    </div>
  );
}
