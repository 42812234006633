import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { hide } from "redux-modal";
import { RoutsGameE } from "../../../../../utils/gameRoutsName";
import { GAME_OOPS_MODAL } from "../../../../../utils/modalGameNames";

interface GameOopsControlI {
  onClose(): void;
}

export const useGameOopsControl = (isBattle?: boolean): GameOopsControlI => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(hide(GAME_OOPS_MODAL));
    navigate(isBattle ? RoutsGameE.Rooms : RoutsGameE.Home);
  };

  return {
    onClose,
  };
};
