import { useNavigate } from "react-router-dom";
import { RoutsGameE } from "../../../../../utils/gameRoutsName";
import { GameType } from "../../../../GameFolder/GameSelectGods/useGameSelectGodsControl";
import momentTz from "moment-timezone";
import useMe from "../../../../../utils/hooks/useMe";
interface FreeBattleModalControlI {
  readonly games: number;
  readonly haveGames: boolean;
  readonly timeUpdate: string;

  toSelectGod(): void;
}

export const useFreeBattleModalControl = (): FreeBattleModalControlI => {
  const navigate = useNavigate();
  const me = useMe();
  const toSelectGod = () => {
    navigate(RoutsGameE.Select_gods, {
      state: { mode: "PVE", bet: 10, gameType: GameType.SOUL, isFree: true },
    });
  };
  const games = me?.FreeGames || 0;
  const timeUpdate = `before ${momentTz.tz("GMT").endOf("D").format("DD MMMM YYYY HH:mm")} GMT`;

  return {
    games,
    haveGames: games > 0,
    timeUpdate,

    toSelectGod,
  };
};
