import styles from "./Step.module.scss";
import cn from "classnames";
import { ReactNode } from "react";
import Button from "../../Button/Button";

export interface StepProps {
  title?: string;
  btnTitle?: string;
  subtitle?: string;
  done?: boolean;
  progress?: boolean;
  children?: ReactNode;
  final?: boolean;
  active?: boolean;
  run?: () => void;
}

export const Btn = (props: {
  children: ReactNode;
  action: () => void;
  final: boolean;
  active: boolean;
}) => (
  <Button
    className={cn(styles.modal__upgrade_steps_btn, {
      [styles.btn]: !props.final,
    })}
    onClick={props.action}
    style={{
      fontSize: 12,
      pointerEvents: props.active ? "all" : "none",
    }}
    white={!props.final}
    pulsate={props.active}
  >
    {props.children}
  </Button>
);

export default function FollowSteps(props: StepProps): JSX.Element {
  return (
    <div
      className={cn(styles.modal, styles.modal__upgrade_steps_wrap)}
      style={{ pointerEvents: props.progress ? "none" : "initial" }}
    >
      <div className={styles.modal__upgrade_steps_inner}>
        {props.progress && (
          <div className={styles.modal__upgrade_steps_circle} />
        )}
        {props.children ? (
          props.children
        ) : (
          <>
            <p className={styles.modal__upgrade_steps_pay}>{props.title}</p>
            <Btn
              final={!!props.final}
              active={!!props.active}
              action={() => props.run && props.run()}
            >
              {props.progress
                ? "In progress..."
                : props.done
                ? "Done"
                : props.btnTitle || "OK"}
            </Btn>
            <p
              className={styles.modal__upgrade_steps_sign}
              style={{ visibility: props.progress ? "visible" : "hidden" }}
            >
              {props.subtitle}
            </p>
          </>
        )}
      </div>
    </div>
  );
}
