import styles from "./GameNotification.module.scss";
import cn from "classnames";
import { ReactComponent as NotifIcon } from "../../../../assets/Icons/game/notificationBattle.svg";
import { GameNotificationPropsI } from "./GameNotification.props";
import { gmtTime } from "../../../../utils/gmtTime";
import { useNavigate } from "react-router-dom";
import { battleNotifRes } from "../../../Layout/GameLayout/useHeaderGameControl";
import reduceAddress from "../../../../utils/reduceAddress";

export default function GameNotification({
  notif,
  className,
  onCloseBlock,
  ...props
}: GameNotificationPropsI) {
  const navigate = useNavigate();

  const toBattle = () => {
    navigate(`/game/battle-room/${notif.RoomId}`, {
      state: {
        isShared: true,
      },
    });
    onCloseBlock();
  };

  return (
    <div className={cn(styles.wrapper, className)} {...props} onClick={toBattle}>
      <div className={styles.date}> {gmtTime(notif.Played)} </div>
      <div className={styles.content}>
        <NotifIcon className={styles.icon} />
        <div className={styles.result}>
          <p>
            Battle{" "}
            <span
              className={cn({
                [styles.lose]: notif.Result === "L",
                [styles.win]: notif.Result === "W",
                [styles.draw]: notif.Result === "D",
              })}
            >
              {battleNotifRes(notif.Result)}
            </span>{" "}
          </p>
          <p> VS {notif?.Rival?.startsWith("0x") ? reduceAddress(notif.Rival) : notif.Rival} </p>
        </div>
      </div>
    </div>
  );
}
