import cn from "classnames";
import { CSSProperties, MouseEventHandler, ReactNode } from "react";
import styles from "./Button.module.scss";

export interface ButtonProps {
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;

  white?: boolean;
  pulsate?: boolean;

  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const Button = (props: ButtonProps) => (
  <button
    className={cn(
      "btn",
      {
        "btn--white": props.white,
        [styles.pulsate]: !!props.pulsate,
      },
      props.className
    )}
    onClick={props.onClick}
    style={props.style}
  >
    {props.children}
  </button>
);

export default Button;
