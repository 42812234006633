const CONN_STATE_CLOSED = 0;
const CONN_STATE_WAIT_RECONNECT = 1;
const CONN_STATE_WAIT_OPEN = 2;
const CONN_STATE_OPEN = 3;
const CONN_STATE_WAIT_CLOSE = 4;

export interface WSEvent<T = any> {
  readonly Id: number; //уникальный номер
  readonly Status?: string; //может быть пустым, либо error,
  readonly Message?: string; //если status==error, содержит текст ошибки
  readonly Type: number; //SMT_VIDEO_EVENT...
  readonly Event: T; //детали события Type
}

export class ReconnectingSocket {
  private count_: number;
  private state_: number;
  private lastId_: number;
  private needReconnect_: boolean;
  private socket?: WebSocket;
  private address?: string;

  public cb?: (data: WSEvent) => void;

  constructor(lastId: number) {
    this.count_ = 0;
    this.state_ = CONN_STATE_CLOSED;
    this.lastId_ = lastId;
    this.needReconnect_ = false;
  }

  close() {
    if (this.socket) {
      console.log("WS: программное закрытие сокета");
      this.socket.close();
    }
  }

  setNeedReconnect(needReconnect: boolean) {
    //console.log("WS SET NEED RECONNECT", needReconnect)
    this.needReconnect_ = needReconnect;
    if (needReconnect && this.address) this.reconnect(this.address);
    else this.close();
  }

  reconnect(address: string) {
    //console.log("WS START RECONNECT", address, this.needReconnect_, this.state_)
    const wasAddress = this.address;
    if (address) this.address = address;

    if (
      !this.needReconnect_ ||
      !address ||
      this.state_ === CONN_STATE_WAIT_OPEN ||
      this.state_ === CONN_STATE_WAIT_CLOSE
    )
      return;

    //console.log("WS RECONNECT", this.socket?.readyState)

    if (this.socket && this.socket.readyState === 1) {
      if (wasAddress === this.address) {
        //console.log("WS RECONNECT 1 EXIT", this.socket?.readyState, wasAddress, this.address)
        return;
      }
      this.address = address;
      this.state_ = CONN_STATE_WAIT_CLOSE;
      this.close(); //will reconnect
      //console.log("WS RECONNECT 2 EXIT", this.socket?.readyState, wasAddress, this.address)
      return;
    }

    this.address = address;

    //console.log("WS: CREATE SOCKET : <", this.address, ">");
    this.socket = new WebSocket(this.address);
    this.socket.onmessage = this.onMessage.bind(this);
    this.socket.onerror = this.onError.bind(this) as any;
    this.socket.onopen = this.onOpen.bind(this);
    this.socket.onclose = this.onClose.bind(this);

    this.state_ = CONN_STATE_WAIT_OPEN;
  }

  send(data: any) {
    const d = JSON.stringify(data);
    if (this.socket && this.socket.readyState === 1) {
      this.socket.send(d);
    } else {
      console.log("WS: данные не посланы");
    }
  }

  ping() {
    if (!this.socket) return;
    if (this.socket.readyState !== 1) return;
    //console.log("WS: PING");
    this.socket.send("ping");
    setTimeout(this.ping.bind(this), 20000);
  }

  onMessage(ev: MessageEvent) {
    //console.log("WS: Тестовые данные", ev);
    if (ev.data === "pong") {
      //console.log("WS: PONG");
      return;
    }

    //console.log("WS: Получено сообщение");
    const event: WSEvent = JSON.parse(ev.data);

    if (typeof event !== "object") {
      //console.log("WS: получены данные JSON:", event);
      return;
    }

    console.log("WS: получены данные JSON:", event);
    if (event.Id > this.lastId_) {
      if (this.cb) {
        //console.log("WS: call CB");
        this.cb(event);
      }
      this.lastId_ = event.Id;
    }
  }

  onError(err: Error): any {
    console.log("WS: Ошибка " + err.message);
    this.close();
  }

  onOpen() {
    this.state_ = CONN_STATE_OPEN;
    this.count_ = this.count_ < 0 ? 1 : this.count_ + 1;
    console.log("WS: Соединение установлено", this.count_);
    //this.ping();
  }

  onClose(ev: CloseEvent): void {
    const wasState = this.state_;
    this.state_ = CONN_STATE_CLOSED;

    this.count_ = this.count_ > 0 ? this.count_ - 1 : 0;
    if (ev.wasClean) {
      console.log("WS: Соединение закрыто чисто");
    } else {
      console.log("WS: Обрыв соединения");
    }
    console.log(
      "WS: Код: " +
        ev.code +
        " причина: " +
        ev.reason +
        " соединений: " +
        this.count_
    );

    if (
      this.needReconnect_ &&
      (wasState === CONN_STATE_OPEN ||
        wasState === CONN_STATE_WAIT_OPEN ||
        wasState === CONN_STATE_WAIT_CLOSE)
    ) {
      console.log("WS: Попытка установить новое соединение...");
      setTimeout(() => this.address && this.reconnect(this.address), 1000);
      this.state_ = CONN_STATE_WAIT_RECONNECT;
    }
  }
}
