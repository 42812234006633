import wallet from "../wallet";
import { BigNumber as EthersBigNumber } from "ethers";

const GAS_LIMIT_COEF = 1.1;
let GAS_PRICE_COEF = 1;
if (typeof window !== "undefined")
  GAS_PRICE_COEF = parseFloat(process.env.REACT_APP_GAS_PRICE_COEF || "1");

export async function getGasLimit(callee: any, params: any = undefined) {
  const limit = await callee.estimateGas(params);
  const newLimit = EthersBigNumber.from(limit)
    .mul(Math.round(GAS_LIMIT_COEF * 100))
    .div(100);
  //Log.info(`Gas limit: ${limit}, evaluated limit: ${newLimit.toString()}`);
  return newLimit;
}

export async function getGasPrice() {
  const price = await wallet.web3?.eth.getGasPrice();
  const newPrice = EthersBigNumber.from(price)
    .mul(Math.round(GAS_PRICE_COEF * 100))
    .div(100);
  //Log.info(`Gas price: ${price}, evaluated price: ${newPrice.toString()}`);
  return newPrice;
}
