import { AxiosResponse } from "axios";
import service from "./service";
import { ServerResponse } from "./setting";

export interface StakingRewardsResponseI extends ServerResponse {
  Total: number;
}

export function stakingRewards(): Promise<AxiosResponse<StakingRewardsResponseI> | Error> {
  return service.api.get("/gt/stakings/rewards");
}

export function addToSoulBalance(): Promise<AxiosResponse<ServerResponse> | Error> {
  return service.api.post("/gt/stakings/addtobalance");
}

export interface WithdrowResponseI extends ServerResponse {
  Commission: number;
}

export function withdrow(Amount: number): Promise<AxiosResponse<WithdrowResponseI> | Error> {
  return service.api.post("/gt/withdraw", { Amount });
}
