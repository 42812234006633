import WalletType from "../../utils/walletType";
import {
  ActionBase,
  ActionSetConnecting,
  ActionSetError,
  ActionSetToken,
  ActionSetWallet,
  ActionTypes,
} from "./actionTypes";
import apiService from "../../services/api/service";

export interface WalletAccount {
  account: string;
  type: WalletType;
}

export interface Token {
  token: string;
  expires: number;
  wallet: WalletAccount;
}

export interface AuthState {
  token?: Token;
  wallet?: WalletAccount;
  connecting?: WalletType;
  startupConnect?: boolean;
  err?: Error;
}

const initialState: AuthState = {};

const reduce = (
  state: AuthState = initialState,
  action: ActionBase
): AuthState => {
  if (action.type.startsWith("AUTH_")) {
    //console.log("AUTH ACTION:", state, action);
  }
  switch (action.type) {
    case ActionTypes.SET_WALLET: {
      const { account, type } = (action as ActionSetWallet).payload;
      return {
        ...state,
        wallet: account ? { account, type: type as WalletType } : undefined,
      };
    }
    case ActionTypes.SET_TOKEN: {
      const { token, expires } = (action as ActionSetToken).payload;
      if (!state.wallet)
        return { ...state, err: new Error("Wallet account not set") };
      return {
        ...state,
        token: { token, expires, wallet: state.wallet },
        err: undefined,
        connecting: undefined,
        startupConnect: undefined,
      };
    }
    case ActionTypes.CLEAN_TOKEN: {
      apiService.api.setToken();
      return {
        ...state,
        token: undefined,
        err: undefined,
        connecting: undefined,
        startupConnect: undefined,
      };
    }
    case ActionTypes.SET_CONNECTING: {
      const { type } = (action as ActionSetConnecting).payload;
      const result = {
        ...state,
        connecting: type,
        startupConnect: type ? state.startupConnect : undefined,
        err: undefined,
      };
      return result;
    }
    case ActionTypes.SET_ERROR: {
      const { err } = (action as ActionSetError).payload;
      if (err) apiService.api.setToken();
      return err ? { err } : { ...state, err: undefined };
    }
    case ActionTypes.INIT: {
      // вызывается только из initStore
      apiService.api.setToken(state.token?.token, state.token?.expires);
      return {
        ...state,
        err: undefined,
        connecting: undefined,
      };
    }
    default:
      return state;
  }
};

export default reduce;
