import { toast, ToastContent, ToastOptions } from "react-toastify";
import { ServerAxiosResponse } from "../services/api/setting";

import check from "../assets/Icons/game/check.svg";
export class Toasts {
  public static Notification(msg: string): ToastContent {
    return toast(msg);
  }

  public static Error(msg: string): ToastContent {
    return toast.error(msg);
  }
  public static Success(msg: string): ToastContent {
    return toast.success(msg, {
      progressClassName: "toast--success",
    });
  }
  public static Info(msg: string, persist = false): ToastContent {
    return toast.info(msg, {
      autoClose: persist && false,
      // position: persist ? "top-right" : "top-center",
    });
  }

  public static error(e: any) {
    if ((e as Error).message !== undefined) {
      return toast.error((e as Error).message);
    }
    if ((e as ServerAxiosResponse).data !== undefined) {
      return toast.error((e as ServerAxiosResponse).data.Message ?? "Unknown error");
    }
    return toast.error(e as string);
  }
}
