import { FunctionComponent, PropsWithChildren } from "react";
import { connectModal, InjectedProps } from "redux-modal";
import { useDispatch } from "react-redux";
import { show, hide } from "redux-modal";
import GameModal from "../../global/modals/GameFolder/GameModal";

export interface ModalGameProps extends PropsWithChildren<InjectedProps> {
  modalClose: () => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function WithGameModal<T extends PropsWithChildren<InjectedProps>>(
  name: string,
  Component: FunctionComponent<T>,
  params?: {
    battle?: boolean;
    oops?: boolean;
    godModal?: boolean;
  }
) {
  function withGameModalComponent(props: T) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dispatch = useDispatch();
    return (
      <GameModal params={params} name={name}>
        <Component modalClose={() => dispatch(hide(name))} {...props} />
      </GameModal>
    );
  }

  return connectModal({
    name,
    getModalState: (state) => state.modals,
  })(withGameModalComponent);
}
